import { useEffect, useState } from 'react';
// import FormGroup from './bootstrap/forms/FormGroup';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../components/bootstrap/Card';
// import PhaseService from '../../services/PhaseService';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PhaseService from '../../services/PhaseService';
import Button from '../../components/bootstrap/Button';
import Modal, {
    ModalBody, ModalHeader
} from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Select from '../../components/bootstrap/forms/Select';
import Input from '../../components/bootstrap/forms/Input';
import DelphiLogger from '../../services/DelphiLogger';

export const TrackerColumnModal = ({ isColumnModalOpen, setIsColumnModalOpen, trackerWorkstreams, updateWorkstreams, updateWorkstream }) => {

    const [newWSName, setNewWSName] = useState("")
    const [newWSType, setNewWSType] = useState("Email Blast")

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...trackerWorkstreams];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        updateWorkstreams(updatedList);
    };

    var filter = {
        // default filter configuration
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.data.value} ${option.data.label}`,
        trim: true,
    }

    return (
        <>
            <Modal
                setIsOpen={setIsColumnModalOpen}
                isOpen={isColumnModalOpen}
                titleId='projectPhases'
                isCentered
                isScrollable
                size='lg'>
                <ModalHeader setIsColumnModalOpen={setIsColumnModalOpen}>
                    Edit Tasks (Columns)
                </ModalHeader>
                <ModalBody>

                    <Card >
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Edit Tasks (Columns)</CardTitle>
                                <br />
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <DragDropContext onDragEnd={handleDrop}>
                                    <Droppable droppableId="list-container">
                                        {(provided) => (
                                            <div
                                                className="rounded col-xl-12 list-container"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {trackerWorkstreams?.map((item, index) => {
                                                    DelphiLogger.debug(item, `Item${index}`)
                                                    return !item?.toDelete &&
                                                        <Draggable key={item?._id} draggableId={item?._id} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    className="rounded item-container"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <div className='row'>
                                                                        <div className='col-auto' >
                                                                            <Button
                                                                                color="danger"
                                                                                icon="XLg"
                                                                                onClick={
                                                                                    () => {
                                                                                        updateWorkstream({ ...item, toDelete: true })
                                                                                    }
                                                                                }
                                                                            />

                                                                        </div>
                                                                        <div style={{ marginTop: "7px" }} className='col-auto'>{item?.title}</div>
                                                                        <div className='col-auto'>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className='row' style={{ paddingBottom: "70px" }}>
                                <div className='col-6'>
                                    <Input
                                        placeholder='WorkStream Name'
                                        onBlur={e => setNewWSName(e.target.value)}
                                    // validFeedback='Looks good!'
                                    />
                                </div>
                                <div className='col-3'>
                                    <Select placeholder="Type"
                                        onChange={e => setNewWSType(e.target.value)}
                                    >
                                        <option value="Task">To-Do</option>
                                        <option value="EmailBlast">Email Blast</option>
                                    </Select>
                                </div>
                                <div className='col-3'>
                                    <Button
                                        icon="Plus"
                                        color="success"
                                        onClick={() => {
                                            updateWorkstream({
                                                type: newWSType,
                                                title: newWSName
                                            })
                                        }}
                                    >
                                        Add Workstream
                                    </Button>
                                </div>
                            </div>
                        </CardBody >
                    </Card>
                </ModalBody>
            </Modal>
        </>
    )
}
