import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Dots } from 'loading-animations-react';
import { Calendar as DatePicker } from 'react-date-range';
import { useNavigate, useParams } from 'react-router-dom';
import dataF from '../../common/data/dummyEventsData';
import USERS from '../../common/data/userDummyData';
import Button from '../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../../components/bootstrap/Card';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from '../../components/bootstrap/Dropdown';
import Checks from '../../components/bootstrap/forms/Checks';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Textarea from '../../components/bootstrap/forms/Textarea';
import OffCanvas, {
    OffCanvasBody,
    OffCanvasHeader,
    OffCanvasTitle
} from '../../components/bootstrap/OffCanvas';
import Popovers from '../../components/bootstrap/Popovers';
import Icon from '../../components/icon/Icon';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import useDarkMode from '../../hooks/useDarkMode';
import useSortableData from '../../hooks/useSortableData';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import { demoPages } from '../../menu';
import ProjectService from '../../services/ProjectService';
import { TrackerColumnModal } from './TrackerColumnModal';
import './TrackerPage.css';
import { TrackerRowModal } from './TrackerRowModal';
import TrackerService from '../../services/TrackerService';
import { TrackerBoard } from '../../components/Trackers/TrackerBoard/TrackerBoard';
import DelphiLogger from '../../services/DelphiLogger';
import { v4 as uuidv4 } from 'uuid';
import TaskService from '../../services/TaskService';
import HistoryService from '../../services/HistoryService';
import FileService from '../../services/FileService';
import { useAuth0 } from "@auth0/auth0-react";
import UserService from '../../services/UserService';
import PhaseService from '../../services/PhaseService';
import TaskPanel from '../projecttasks/TaskPanel';
import TagService from '../../services/TagService';
import { EmailBlastModal } from '../../components/Trackers/TrackerBoard/EmailBlastModal';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import { BuyerListModal } from '../../components/BuyerList/BuyerListModal';
import { TrackerSaveTemplateModal } from '../../components/Trackers/SaveTemplate/TrackerSaveTemplateModal';

// eslint-disable-next-line react/prop-types
const TrackerPage = () => {
    
    const { user } = useAuth0();
    const navigate = useNavigate();
    const [curProject, setCurProject] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const { trackerId, taskId } = useParams();
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [date, setDate] = useState(new Date());

    const [buyersList, setBuyersList] = useState([])
    const [tracker, setTracker] = useState(null)
    const [trackerRows, setTrackerRows] = useState([])
    const [trackerWorkstreams, setTrackerWorkstreams] = useState([])
    const [trackerAIs, setTrackerAIs] = useState({})
    const [tagOptions, setTagOptions] = useState([]);
    const [orgUsers, setOrgUsers] = useState([]);

    const [isColumnModalOpen, setIsColumnModalOpen] = useState(false);
    const [isTrackerRowModalOpen, setIsTrackerRowModalOpen] = useState(false);
    const [isEmailBlastModalOpen, setIsEmailBlastModalOpen] = useState(false)
    const [isBuyersListModalOpen, setIsBuyersListModalOpen] = useState(false)
    const [focusAI, setFocusAI] = useState(null)
    const [focusWorkStream, setFocusWorkStream] = useState(null)
    const [isTaskPanelOpen, setIsTaskPanelOpen] = useState(false)
    const [isTrackerSaveTemplateModalOpen, setIsTrackerSaveTemplateModalOpen] = useState(false)

    const [data, setData] = useState(dataF);

    // BEGIN :: Upcoming Events
    const [upcomingEventsInfoOffcanvas, setUpcomingEventsInfoOffcanvas] = useState(false);
    const handleUpcomingDetails = () => {
        setUpcomingEventsInfoOffcanvas(!upcomingEventsInfoOffcanvas);
    };

    const getOrDefaultActionItem = (trackerRow, workstream, ais) => {

        ais = ais ? ais : trackerAIs

        const aiId = `${trackerRow._id}${workstream._id}`
        var ai = ais[aiId]

        if (!ai) {

            const itemName = trackerRow?._displaylistItems?.length > 0 ?
                trackerRow?._displaylistItems[0]["Fund Name"] : ""

            ai = {
                _id: aiId,
                tracker: tracker?._id,
                trackerRow: trackerRow._id,
                workstream: workstream._id,
                title: `${workstream?.title} (${itemName})`,
                type: workstream.type,
                owners: [],
                project: curProject?._id,
                status: "New"
            }
        }

        ai.displayAssignees = ai?.assignees?.map(a => getUserOptionById(a))

        return ai
    }

    const updateAI = (ai) => {
        const newDict = { ...trackerAIs }
        newDict[`${ai?._id}`] = ai
        setTrackerAIs(newDict)
        TaskService.putTask(ai)

        if (ai?._id == focusAI?._id) {
            setFocusAI(ai)
        }
    }

    const startEmailBlast = (ws) => {
        setFocusWorkStream(ws)
        setIsEmailBlastModalOpen(true)
    }

    const getUserOption = (user) => {
        return {
            ...user,
            value: user.user_id,
            label: <div style={{ color: "black" }}><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
        }
    }

    const getUserOptionById = (id) => {
        const user = UserService.getUser(id)
        return {
            ...user,
            value: user.user_id,
            label: <div style={{ color: "black" }}><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
        }
    }

    const updateOrgUsers = async (curProject) => {
        var orgUsers = UserService.getOrgUsers()
        const ou = await Promise.all(
            orgUsers.map(async (user) => {
                return getUserOption(user)
            }))

        const collaborators = await Promise.all(
            curProject?.collaborators?.filter(x => !orgUsers.some(o => o?._id == x?._id)).map(async (id) => {
                return getUserOptionById(id)
            }))

        const users = [...ou, ...collaborators]
        setOrgUsers(users)

        return users
    }

    const updateTrackerRow = (tr) => {

        var newTRList = [...trackerRows]
        if (!tr._id) {
            tr._id = uuidv4()
            tr.title = tr["Fund Name"]
            tr.tracker = tracker?._id
            tr.trackerIndex = trackerRows.length
            tr.project = curProject?._id
            tr.status = "Active"

            setTrackerRows([...newTRList, tr])
        }
        else {
            const ind = newTRList.findIndex(x => x._id == tr._id)
            newTRList[ind] = tr
            setTrackerRows(newTRList)
        }
        TrackerService.saveTrackerRows(tracker?._id, [tr])
    }

    const updateTrackerRows = (newTr) => {
        const indexed = newTr.map((x, i) => ({ ...x, trackerIndex: i }))
        setTrackerRows(indexed)
        TrackerService.saveTrackerRows(tracker?._id, newTr)
    }

    const updateWorkstream = (ws) => {

        var newWSList = [...trackerWorkstreams]
        if (!ws._id) {
            ws._id = uuidv4()
            ws.tracker = tracker?._id
            ws.trackerIndex = trackerWorkstreams.length
            ws.project = curProject?._id
            ws.status = "New"
            ws.phase = curProject.currentPhase ?? curProject.phases > 0 ? curProject.phases[0] : null

            setTrackerWorkstreams([...newWSList, ws])
        }
        else {
            const ind = newWSList.findIndex(x => x._id == ws._id)
            newWSList[ind] = ws
            setTrackerWorkstreams(newWSList)
        }
        TaskService.putWorkstream(ws)
    }

    const updateWorkstreams = (newWs) => {
        const indexed = newWs.map((x, i) => ({ ...x, trackerIndex: i }))
        setTrackerWorkstreams(indexed)
        TaskService.putWorkstreams(newWs)
    }

    const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
    const handleUpcomingEdit = () => {
        setUpcomingEventsEditOffcanvas(!upcomingEventsEditOffcanvas);
    };

    const toggleBuyerExit = (item) => {
        const newData = [...data]
        const index = newData.findIndex(x => x.id == item.id)
        newData[index].exited = !(newData[index].exited)
        setData(newData)
    }


    const addComment = async (task, comment, filesToUpload, workstreamIndex, taskIndex) => {

        const commentObj = {
            body: comment,
            author: user.sub
        }

        var filesCreatedIds = []
        if (filesToUpload.length > 0) {
            const filesCreated = await FileService.uploadFiles(filesToUpload, curProject)
            filesCreatedIds = filesCreated.map(f => f._id)
            commentObj.files = filesCreatedIds
            commentObj._displayFiles = filesCreated
        }

        HistoryService.putHistory(task?._id, [commentObj]).catch((error) => {
            DelphiLogger.logMessage(
                'Comment add FAILED',
                `Comment add FAILED ${error}`,
            )
        })

        // Don't wait for a refresh
        commentObj.type = "comment"
        commentObj.updatedDate = (new Date()).toISOString();
        commentObj.createdDate = (new Date()).toISOString();
        commentObj.state = "created"

        const history = task.history ?? []
        updateAI({ ...task, history: [...history, commentObj], files: [...(task.files ?? []), ...filesCreatedIds] })
    }

    var dropDownOptions = {
        stateOptions: [
            "New",
            "Active",
            "Complete",
        ],
        priorityOptions:
            [
                "",
                "Low",
                "Medium",
                "High"
            ],
        tagOptions: tagOptions,
        orgUserOptions: orgUsers
    }

    const pullTags = (id) => {
        const prom = TagService.getTags().then(
            async (resp) => {
                const tags = await resp.json()
                setTagOptions(tags)
            }
        ).catch(
            (error) => DelphiLogger.logMessage(
                `Tags retrieval failed ${error}`,
                "Tags retrieval failed",
            ))

        return prom
    }

    // When selecting or adding a tag
    const updateTaskTags = (
        newValue,
        actionMeta,
        task,
        workstreamIndex,
        taskIndex
    ) => {

        if (actionMeta.action == 'create-option') {
            const newVal = newValue[newValue.length - 1]

            // Only save a tag if it is not already an option
            if (!(tagOptions.some(x => x.value == newVal))) {
                const newTag = { ...newVal, _id: newVal.value }
                setTagOptions([...tagOptions, newTag])
                TagService.putTag(newTag)
            }
        }

        updateAI({ ...task, tags: newValue })
    };

    const refreshData = async () => {
        setIsLoading(true)
        await UserService.initialize()
        await PhaseService.init()
        pullTags()
        if (trackerId) {
            const tracker = (await TrackerService.getTracker(trackerId))?.find(x => x)
            if (tracker) {
                const project = await ProjectService.getProject(tracker?.project)
                DelphiLogger.debug(tracker, "Tracker")
                setTracker(tracker)
                setCurProject(project)
                await (updateOrgUsers(project))
                setTrackerRows(tracker?._displayRows ?? [])
                setTrackerWorkstreams(tracker?._displayWorkStreams ?? [])

                var aiMap = tracker?._displayActionItems?.reduce(function (map, ai) {
                    ai.displayAssignees = ai?.assignees?.map(a => getUserOptionById(a))
                    map[ai?._id] = ai;
                    return map;
                }, {}) ?? {};
                DelphiLogger.debug(aiMap)
                setTrackerAIs(aiMap)
            }
        }
        setIsLoading(false)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, [trackerId]);

    const commonProps = {
        updateTrackerRow: updateTrackerRow,
        updateTrackerRows: updateTrackerRows,
        setFocusAI: setFocusAI,
        setIsTaskPanelOpen: setIsTaskPanelOpen,
        startEmailBlast: startEmailBlast,
        curProject: curProject,
        project: curProject,
        tracker: tracker,
        trackerWorkstreams: trackerWorkstreams,
        updateWorkstreams: updateWorkstreams,
        updateWorkstream: updateWorkstream,
        isColumnModalOpen: isColumnModalOpen,
        setIsColumnModalOpen: setIsColumnModalOpen,
        isTrackerRowModalOpen: isTrackerRowModalOpen,
        setIsTrackerRowModalOpen: setIsTrackerRowModalOpen,
        isTaskPanelOpen: isTaskPanelOpen,
        addComment: addComment,
        updateTaskTags: updateTaskTags,
        dropDownOptions: dropDownOptions,
        isEmailBlastModalOpen: isEmailBlastModalOpen,
        setIsEmailBlastModalOpen: setIsEmailBlastModalOpen,
        focusWorkStream: focusWorkStream,
        trackerRows: trackerRows,
        actionItems: trackerAIs,
        updateAI: updateAI,
        trackerAIs: trackerAIs,
        getOrDefaultActionItem: getOrDefaultActionItem,
        refreshData: refreshData,
        isBuyersListModalOpen: isBuyersListModalOpen,
        setIsBuyersListModalOpen: setIsBuyersListModalOpen,
        setIsTrackerSaveTemplateModalOpen: setIsTrackerSaveTemplateModalOpen,
        isTrackerSaveTemplateModalOpen: isTrackerSaveTemplateModalOpen
    }


    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            :
            <>
                <PageWrapper title={demoPages.editPages.subMenu.editInCanvas.text} style={{ flex: "1 1 auto" }}>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Breadcrumb
                                list={[
                                    {
                                        title: 'Deals',
                                        to: '/deals',
                                    },
                                    {
                                        title: curProject?.title,
                                        to: `/dealdashboard/${curProject?._id}`,
                                    },
                                    {
                                        title: tracker?.title,
                                        to: `/trackerBoard/${tracker?._id}`,
                                    },
                                ]}
                            />
                        </SubHeaderLeft>
                        <SubHeaderRight>
                            <Popovers
                                desc={
                                    <DatePicker
                                    />
                                }
                                placement='bottom-end'
                                className='mw-100'
                                trigger='click'>
                                <Button color={themeStatus}>
                                    Complete Tracker
                                </Button>
                            </Popovers>
                        </SubHeaderRight>
                    </SubHeader>
                    <Page container='fluid' style={{ zIndex: "1020", flex: "1 1 auto" }}>
                        <div className='row h-100'>
                            <div className='col-12'>
                                <TrackerBoard
                                    {...commonProps}
                                />
                            </div>
                        </div>
                    </Page>
                </PageWrapper>

                <TrackerColumnModal
                    {...commonProps} />
                <TrackerRowModal
                    {...commonProps} />

                <TaskPanel
                    focusItems={{ task: focusAI }}
                    updateTask={(a, b, c) => updateAI(a)}
                    {...commonProps} />

                <EmailBlastModal
                    {...commonProps}
                />

                <BuyerListModal
                    {...commonProps} />

                <TrackerSaveTemplateModal
                    {...commonProps}
                />
            </>);


};

export default TrackerPage;
