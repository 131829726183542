import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Dots } from 'loading-animations-react';
import { AvatarGroupMemberList } from '../../../../components/AvatarGroupMemberModal/AvatarGroupMemberList';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../../../../components/bootstrap/Card';
import Modal, {
    ModalBody, ModalHeader
} from '../../../../components/bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../../../components/bootstrap/OffCanvas';
import { BuyerListImport } from '../../../../components/BuyerList/BuyerListImport';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import { MainProjectInformation } from './WizardSteps/MainProjectInformation';
import { PhaseSelect } from './WizardSteps/PhaseSelect';
import { SellProjectAssetInforomation } from './WizardSteps/SellProjectAssetInforomation';
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import TemplateService from '../../../../services/TemplateService';
import ProjectService from '../../../../services/ProjectService';
import WorkstreamSection from '../../WorkstreamSection';
import TaskService from '../../../../services/TaskService';
import TrackerService from '../../../../services/TrackerService';
import { v4 as uuidv4 } from 'uuid';

export const NewProjectModal = ({ setIsOpen, isOpen, callback }) => {

    const navigate = useNavigate();
    const [buyersList, setBuyersList] = useState([]);
    const [owners, setOwners] = useState([]);
    const [projectPhases, setProjectPhases] = useState([]);
    const [projectType, setProjectType] = useState("sellSide");
    const [loadingMessage, setLoadingMessage] = useState("Loading")
    const [isLoading, setIsLoading] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([])

    const addNewProject = async (p, lists = null) => {
        await (ProjectService.saveProject([p]))
        if (lists) {
            await ProjectService.saveLists(p, lists)
        }
        return p
    }

    const addNewWorkstream = async (ws, project) => {

        const uid = uuidv4()
        ws._id = ("t" + uid)
        ws.project = project._id

        const tasks = ws?.tasks?.map((task, i) => {
            return {
                ...task,
                project: project._id,
                workstream: ws._id,
                _id: uuidv4(),
                g: uuidv4(),
                status: "New",
                comments: [],
                history: [],
                type: "task"
            }
        }) ?? []

        ws.tasks = tasks
        await (TaskService.putWorkstream(ws))
        await (TaskService.putTasks(tasks))
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            asset: {},
            phases: ["Planning"],
            currentPhase: "Planning"
        },
        onSubmit: async (values) => {
            setIsLoading(true)
            const template = templateOptions?.find(o => o?._id == values?.projectTemplate)

            setLoadingMessage("Creating deal")
            var newProject = values
            if (template) {
                newProject = {
                    owners: owners,

                    title: values?.title,
                    description: values?.description,
                    asset: values?.asset,
                    phases: template?.phases,
                    currentPhase: template?.phases?.length > 0 ? template?.phases[0] : null
                }
            }

            var list = {
                // TODO: get list type from modal
                type: "buyer",
                title: "Buyers List",
                items: buyersList
            }

            newProject = await addNewProject(
                newProject,
                [
                    list
                ]
            )

            if (template) {
                const workstreams = template?.workStreams
                const trackers = template?.trackerTemplates

                setLoadingMessage("Creating deal workstreams")
                workstreams?.map((w, i) => addNewWorkstream({...w, index: w?.index ?? i}, newProject))
            }

            TaskService.saveTaskQueue()
            TaskService.saveWorkstreamQueue()
            await new Promise(resolve => setTimeout(resolve, 1000));

            navigate(`/dealdashboard/${newProject?._id}`)
            if (callback) {
                callback(newProject?._id)
            }

            setIsOpen(false)
            setIsLoading(false)
        }
    });

    const updateProjectOwners = (newOwners) => {
        setOwners(newOwners)
        formik.setFieldValue("owners", newOwners)
    }

    const updateProjectType = (projectType) => {
        setProjectType(projectType)
        formik.setFieldValue("type", projectType)

        var t = templateOptions.find(x => {
            switch (projectType) {
                case "sellSide":
                    return x.title == "FinSpace Sell-Side Template"
                    break;
                case "buySide":
                    return x.title == "FinSpace Buy-Side Template"
                    break;
                default:
                    return false
                    break;
            }
        })

        formik.setFieldValue("projectTemplate", t?._id ?? "")
    }

    const refreshData = async () => {
        const projectTemplates = await TemplateService.getProjectTemplates()
        const templateOptions = projectTemplates?.map(o => ({
            ...o,
            value: o?._id, label: o?.title,
            trackersL: o?.trackers?.length,
            phasesL: o?.phases?.length,
            wsL: o?.workStreams?.length
        }))

        var t = templateOptions.find(x => {
            switch (projectType) {
                case "sellSide":
                    return x.title == "FinSpace Sell-Side Template"
                    break;
                default:
                    return false
                    break;
            }
        })

        if (t) {
            formik.setFieldValue("projectTemplate", t?._id)
        }

        setTemplateOptions(templateOptions)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, [])


    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>New Deal</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                {isLoading ?
                    <PageWrapper className="justify-content-center text-center" title="Tasks">
                        <div className=" justify-content-center text-center" title="Tasks">
                            <Dots text={loadingMessage}></Dots>
                        </div>
                    </PageWrapper>
                    :
                    <Wizard onSubmit={formik.handleSubmit} >
                        <WizardItem icon={"Check"} id='step1' title='Add Deal Info'>
                            <MainProjectInformation
                                templateOptions={templateOptions}
                                formik={formik}
                                projectType={projectType}
                                updateProjectType={updateProjectType}
                            />
                            <br />
                            <Card >
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle>Add Deal Team</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody className="pb-0">
                                    <AvatarGroupMemberList members={owners} updateMembers={updateProjectOwners} />
                                </CardBody >
                            </Card>
                        </WizardItem>
                    </Wizard>
                }
            </ModalBody>
        </Modal>
    )
}
