import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import * as Yup from 'yup';
import Wizard, { WizardItem } from '../Wizard';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../bootstrap/Card';
import { generateField, WizardField } from './WizardFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop } from '@fortawesome/free-regular-svg-icons';
import Popovers from '../bootstrap/Popovers';
import Icon from '../icon/Icon';

const generateYup = (wizardObject) => {
    var yup = {}

    wizardObject?.steps?.map(s => {
        s?.cards?.map(c => {
            c?.fields?.map(f => {
                var key = f?.id
                var validate = f?.validate

                if (validate) {
                    yup[key] = validate
                }
            })
        })
    })

    return Yup.object().shape(yup)
}

const generateInitValues = (wizardObject) => {
    var init = {}
    const checks = []

    wizardObject?.steps?.map(s => {
        s?.cards?.map(c => {
            c?.fields?.map(f => {
                var key = f?.id
                var value = f?.init

                if (f?.type == "check") {
                    if (value) {
                        checks.push(key)
                    }
                }
                else {
                    init[key] = value
                }
            })
        })
    })

    init.checks = checks
    return init
}

export const WizardGenerator = ({ wizardObject }) => {

    const validate = generateYup(wizardObject)
    const initValues = generateInitValues(wizardObject)

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: validate,
        onSubmit: (formData) => {
            wizardObject?.onSubmit(formData)
        },
    });

    useEffect(() => {
        const yup = generateYup(wizardObject)
        const values = generateInitValues(wizardObject)

        formik.setValues(values)
    }, [wizardObject])


    return (
        <Wizard
            isHeader
            stretch
            color='info'
            noValidate
            onSubmit={formik.handleSubmit}
            className='shadow-3d-info'
        >
            {
                wizardObject?.steps?.map(s => {
                    return <WizardItem title={s?.title ? s?.title : ""}>
                        {
                            s?.cards?.map(card => {
                                return <Card >
                                    {card?.title &&
                                        <CardHeader>
                                            <CardLabel>
                                                <CardTitle>{card?.info &&
                                                    <Popovers
                                                        trigger='hover'
                                                        desc={card?.info}>
                                                        <Icon
                                                            icon='Info'
                                                            size='lg'
                                                            className='ms-1 mr-1 cursor-help'
                                                        />
                                                    </Popovers>}{card?.title}</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                    }
                                    <CardBody>
                                        <div className='row g-4'>
                                            {
                                                card?.fields?.map(f => {
                                                    return <WizardField fieldObject={f} formik={formik} />
                                                })
                                            }
                                        </div>
                                    </CardBody >
                                </Card>
                            })
                        }
                        {
                            Object.keys(formik.errors)?.length > 0 && <Card className="mt-3 mb-0">
                                <CardBody>
                                    {
                                        Object.keys(formik.errors).map((key) => {
                                            return <div className='row'><div className='col'><span className="text-danger"><FontAwesomeIcon icon={faCircleStop}></FontAwesomeIcon>{formik.errors[key]}</span></div> </div>
                                        })
                                    }
                                </CardBody>
                            </Card>
                        }
                    </WizardItem>
                })
            }
        </Wizard>
    )
}
