import React from 'react'
import WorkstreamSection from './WorkstreamSection'
import './TaskTable.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddWorkStreamRow } from './AddWorkStreamRow';

const TaskTable = ({ selectMode, filterSort, setFilterSort, workstreams, updateAllWSTasks,
    updateWorkstream, updateWorkstreams, deleteWorkstream, startWorkstreamTemplateSave,
    updateTask, updateTaskTags, addNewTask, dropDownOptions, setIsTaskPanelOpen,
    setFocusTask, phaseFilter, addNewWorkstream }) => {

    const handleDrop = (droppedItem) => {

        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...workstreams];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        updatedList = updatedList.map((i, index) => {
            var ret = { ...i }
            ret.index = index
            return ret
        })

        // Update State
        updateWorkstreams(updatedList);
    };

    const filterWs = (ws) => {
        var matches = true
        matches = matches && (!filterSort?.search || ws?.title?.includes(filterSort?.search))
        return matches
    }

    return (

        <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
                {(provided) => (

                    <div className="chat-wrap-inner list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <div className="chat-box mb-5" >
                            {
                                workstreams
                                    .sort((a, b) => (a?.index > b?.index) ? 1 : -1)
                                    // .filter((ws) => filterWs(ws))
                                    .map((item, index) => (
                                        <Draggable key={item?._id ?? index} draggableId={item?._id ?? index} index={item?.index ?? index}>
                                            {(provided) => (
                                                <div
                                                    className="item-container ws-container"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}>
                                                    <WorkstreamSection
                                                        filterSort={filterSort}
                                                        setFilterSort={setFilterSort}
                                                        provided={provided}
                                                        // hidden={!(phaseFilter.includes(item.phase))}
                                                        selectMode={selectMode}
                                                        key={index}
                                                        index={index}
                                                        updateAllWSTasks={updateAllWSTasks}
                                                        updateWorkstream={updateWorkstream}
                                                        deleteWorkstream={deleteWorkstream}
                                                        startWorkstreamTemplateSave={startWorkstreamTemplateSave}
                                                        updateTask={updateTask}
                                                        updateTaskTags={updateTaskTags}
                                                        addNewTask={addNewTask}
                                                        workstream={item}
                                                        dropDownOptions={dropDownOptions}
                                                        setIsTaskPanelOpen={setIsTaskPanelOpen}
                                                        setFocusTask={setFocusTask}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                            }
                            {
                                addNewWorkstream &&
                                <div className='newWS'>
                                    <AddWorkStreamRow
                                        addNewWorkstream={addNewWorkstream} />
                                </div>
                            }
                        </div>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default TaskTable;