import React, { useState } from 'react';
import { useAuth0, } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OffCanvas, { OffCanvasBody } from '../../components/bootstrap/OffCanvas';
import Avatar, { AvatarGroup } from '../../components/Avatar';
import DatePicker from "react-datepicker";
import USERS from '../../common/data/userDummyData';
import Select from 'react-select';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import CreatableSelect from 'react-select/creatable';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../components/bootstrap/Card';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave, faClone, faPaperPlane, faCheckCircle, faHandRock } from '@fortawesome/free-regular-svg-icons'
import './TaskPanel.css'
import { AddComment } from '../../components/icon/material-icons';

import { TitleH1 } from './columns/TitleColumn'
import { StatusColumn } from './columns/StatusColumn'
import { PriorityColumn } from './columns/PriorityColumn'
import { DueDateColumn } from './columns/DueDateColumn'
import { TagColumn } from './columns/TagColumn'
import { AssigneesColumn } from './columns/AssigneesColumn';
import UserService from '../../services/UserService';
import Chat, { ChatAvatar, ChatGroup, EmailChatGroup } from '../../components/Chat';
import InputGroup from '../../components/bootstrap/forms/InputGroup';
import Textarea from '../../components/bootstrap/forms/Textarea';
import { FileInput } from '../../components/bootstrap/forms/FileInput';
import { Input } from '@material-ui/core';
import { SubTaskInput } from './SubTaskInput';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TaskPanel = ({ setIsTaskPanelOpen, isTaskPanelOpen,
    focusItems, updateTask, updateSubTask, addComment,
    updateTaskTags, dropDownOptions, uploadFiles }) => {

    const task = focusItems?.task;

    const handleDrop = (droppedItem) => {
        var subTasks = task?._displaySubTasks

        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...subTasks];

        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        updateTask({ ...task, _displaySubTasks: updatedList }, focusItems?.workstreamIndex, focusItems?.taskIndex)

        updatedList?.map((st, i) => {
            updateSubTask(
                { ...st, index: i },
                focusItems?.workstreamIndex,
                focusItems?.taskIndex,
                i)
        })
    };

    const columnProps = {
        task: task,
        updateTask: updateTask,
        updateTaskTags: updateTaskTags,
        dropDownOptions: dropDownOptions,
        workstreamIndex: focusItems?.workstreamIndex,
        taskIndex: focusItems?.taskIndex
    }

    const { user } = useAuth0();

    const creatableStyle = {
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "15px",
                backgroundColor: "darkgrey"
            };
        },
    };

    const avatarImgStyle = {
        borderRadius: "50%",
        borderColor: "white",
        borderWidth: "80px"
    }

    const buttonStyle = {
        marginRight: "3px"
    }

    let priorityClass = ""

    switch (task?.priority) {
        case 'High':  // if (x === 'value1')
            priorityClass = "bg-danger"
            break;
        case 'Medium':  // if (x === 'value2')
            priorityClass = "bg-warning"
            break;
        case 'Low':  // if (x === 'value1')
            priorityClass = "bg-light bg-warning"
            break;
        default:
            priorityClass = ""
            break;
    }

    let statusClass = "task-complete bg-light"

    switch (task?.status) {
        case 'Complete':  // if (x === 'value1')
            statusClass = "bg-success"
            break;
        case 'Active':  // if (x === 'value2')
            statusClass = "bg-warning"
            break;
        case 'Cancelled':  // if (x === 'value1')
        case 'Blocked':  // if (x === 'value2')
            statusClass = "bg-danger"
            break;
        case 'New':  // if (x === 'value2')
        case 'Queued':  // if (x === 'value2')
            statusClass = "white"
            break;
        default:
            statusClass = null
            break;
    }

    const projects = [
        { label: "Operation merger", value: "Operation merger" },
        { label: "Finspace Acquisition", value: "Operation merger" }
    ]

    const workStreams = [
        { label: "Pre-work", value: "Pre-work" },
        { label: "Post-work", value: "Post-work" }
    ]

    return (
        <OffCanvas setOpen={setIsTaskPanelOpen} isOpen={isTaskPanelOpen} isRightPanel>
            <OffCanvasBody className='overflow-hidden p-0'>

                <div className="d-flex p-0 flex-column h-100 ">
                    <div className="task-detail-top flex-grow: 0">

                    </div>
                    <div className="activitySection flex-grow-1" style={{ overflow: "auto", overflowX: "hidden", padding: "8px 0 0 13px" }}>
                        <div className="p-0 container-fluid">
                            <div className='row mb-5'>
                                <div className='col'>
                                    <div className='d-flex align-items-center'>
                                        <StatusColumn {...columnProps} />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <CardActions>
                                        <Button className='bg-warning' style={buttonStyle}>
                                            <FontAwesomeIcon icon={faClone} />
                                        </Button>
                                        <Button className='bg-danger' style={buttonStyle} onClick={() => {
                                            updateTask({ ...task, toDelete: !task?.toDelete }, focusItems?.workstreamIndex, focusItems?.taskIndex)
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </Button>
                                        <Button className='white' style={buttonStyle}
                                            color='link'
                                            icon='Close'
                                            onClick={() => {
                                                setIsTaskPanelOpen(false);
                                            }}>
                                        </Button>
                                    </CardActions>
                                </div>
                            </div>
                            <div className='d-flex justify-content-left mb-3'>
                                <TitleH1 {...columnProps} />
                            </div>
                            <div className='d-flex flex-column align-items-left mb-5'>
                                <div>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row" style={{ bottom: 0, verticalAlign: "bottom" }}>Assigned To</th>
                                                <td>
                                                    <AssigneesColumn {...columnProps} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Priority</th>
                                                <td>
                                                    <PriorityColumn {...columnProps} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Due Date</th>
                                                <td>
                                                    <DueDateColumn {...columnProps} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Tags</th>
                                                <td>
                                                    <TagColumn {...columnProps} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div>
                            <p>
                                Description
                            </p>
                        </div>
                        <div>
                            <textarea rows="5" className="taskDesc" defaultValue={task?.description ?? ""} style={{ width: "90%" }}
                                onChange={(e) => { task.description = e.target.value }}
                                onBlur={(event) => {
                                    task.description = null
                                    updateTask({ ...task, description: event.target.value }, focusItems?.workstreamIndex, focusItems?.taskIndex)
                                }}></textarea>
                        </div>


                        <div className="sub-task-section mb-5">
                            <div className="border-bottom h2">
                                SubTasks
                            </div>
                            <div>

                                <DragDropContext onDragEnd={handleDrop}>
                                    <Droppable droppableId="listr">
                                        {(provided) => (

                                            <div className="row listr"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                <div>
                                                    {
                                                        task?._displaySubTasks
                                                            ?.sort((a, b) => (a.index > b.index) ? 1 : -1)
                                                            ?.map((st, sti) => (
                                                                <Draggable key={st?._id} draggableId={st?._id} index={sti}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                        >
                                                                            <div
                                                                                className={"col-md-12 border-bottom " + (st?.toDelete ? "d-none" : "")}>
                                                                                <div className={"row st " + (st?.status == "Complete" ? "st-complete" : "")}>
                                                                                    <div className='col-md-3'>
                                                                                        <span
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                className="drag-handles st-icon hover-hidden"
                                                                                                onClick={e => updateSubTask({ ...st, status: st?.status == "Complete" ? "New" : "Complete" },
                                                                                                    focusItems?.workstreamIndex,
                                                                                                    focusItems?.taskIndex,
                                                                                                    sti)}
                                                                                                icon={faHandRock} />
                                                                                        </span>
                                                                                        <FontAwesomeIcon className="st-icon hover-hidden"
                                                                                            onClick={e => updateSubTask({ ...st, toDelete: true },
                                                                                                focusItems?.workstreamIndex,
                                                                                                focusItems?.taskIndex,
                                                                                                sti)}
                                                                                            icon={faTrashCan} />
                                                                                        <FontAwesomeIcon className='st-completion-button'
                                                                                            onClick={e => updateSubTask({ ...st, status: st?.status == "Complete" ? "New" : "Complete" },
                                                                                                focusItems?.workstreamIndex,
                                                                                                focusItems?.taskIndex,
                                                                                                sti)}
                                                                                            icon={faCheckCircle} />
                                                                                    </div>
                                                                                    <div className='col-md-7 text-right' contentEditable>
                                                                                        <span
                                                                                            onBlur={e => updateSubTask({ ...st, title: e.currentTarget.textContent },
                                                                                                focusItems?.workstreamIndex,
                                                                                                focusItems?.taskIndex,
                                                                                                sti)}
                                                                                        >
                                                                                            {st?.title}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='col-md-2'></div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )}
                                                                </Draggable>
                                                            ))
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <SubTaskInput
                                                        updateSubTask={updateSubTask}
                                                        workstreamIndex={focusItems?.workstreamIndex}
                                                        taskIndex={focusItems?.taskIndex} />
                                                </div>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                            </div>
                        </div>
                        <div style={{ width: "97%" }}>
                            <div className="border-bottom h2">
                                Activity
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="comment-widgets m-b-20">
                                        <Chat>
                                            {
                                                task?.history ?
                                                    task?.history.map((event, ind) => {
                                                        // TODO: Need to have other history besides comments

                                                        var author = UserService.getUser(event.author);

                                                        switch (event?.type) {
                                                            case "comment":
                                                                return (
                                                                    <ChatGroup
                                                                        key={ind}
                                                                        messages={[event]}
                                                                        user={author}
                                                                        isReply={event.author == user.sub}
                                                                    />
                                                                )
                                                                break;
                                                            case "email":
                                                                return (
                                                                    <EmailChatGroup
                                                                        key={ind}
                                                                        messages={[event]}
                                                                        user={author}
                                                                        isReply={event.author == user.sub}
                                                                    />
                                                                )
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    })
                                                    : <></>
                                            }
                                        </Chat>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FileInput {...columnProps} onSubmit={async (textAreaContent, filesToUpload) => {
                        await addComment(task, textAreaContent, filesToUpload, focusItems?.workstreamIndex, focusItems?.taskIndex)
                    }} />
                </div>
            </OffCanvasBody>
        </OffCanvas>
    );
};
TaskPanel.propTypes = {
    setIsTaskPanelOpen: PropTypes.func.isRequired,
    isTaskPanelOpen: PropTypes.bool.isRequired,
};

export default TaskPanel;
