import React, { useState, useEffect, createRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import { Dots } from 'loading-animations-react';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../bootstrap/Card';
import { FileDropPad } from '../../pages/projectdashboard/Widgets/FilesWidget/FileDropPad';
import UserService from '../../services/UserService';
import Button from '../bootstrap/Button';
import moment from 'moment';
import { TitleColumn } from '../../pages/projecttasks/columns/TitleColumn';
import TaskPanel from '../../pages/projecttasks/TaskPanel';
import SimpleTaskPanel from '../../pages/projecttasks/SimpleTaskPanel';
import Badge from '../bootstrap/Badge';
import Input from '../bootstrap/forms/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-regular-svg-icons';
import { NewFileModal } from '../../pages/projectdashboard/Widgets/FilesWidget/NewFileModal';
import FileService from '../../services/FileService';
import DelphiLogger from '../../services/DelphiLogger';
import './FileList.css'
import Icon from '../icon/Icon';
import { Link } from 'react-router-dom';
import { AddFileModal } from '../../pages/projectdashboard/Widgets/FilesWidget/AddFileModal';

export const FileList = ({ curProject, list, updateFile, refreshData, isMinimized = false }) => {

    const navigate = useNavigate();
    const [processedList, setProcessedList] = useState([])
    const [searchedList, setSearchedList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("")
    const [filesToAdd, setFilesToAdd] = useState([]);
    const [files, setFiles] = useState([]);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
    const [fileModalOpen, setFileModalOpen] = useState(false);

    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const { items, requestSort, getClassNamesFor } = useSortableData(searchedList);

    const [isSimpleTaskPanelOpen, setIsSimpleTaskPanelOpen] = useState(false)
    const [focusTaskId, setFocusTaskId] = useState(null)

    const focusOnTask = (taskId) => {
        setFocusTaskId(taskId)
        setIsSimpleTaskPanelOpen(true)
    }

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    useEffect(async () => {
        setIsLoading(true)
        await processList(list)
        setIsLoading(false)
    }, [list])

    function isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    const searchFilter = (file, searchText) => {

        if (isEmptyOrSpaces(searchText)) {
            return true
        }
        if (file?.searchText.includes(searchText?.toLowerCase())) {
            return true
        }

        return false
    }

    const processList = async () => {
        await UserService.initialize()
        const fList = list?.filter(x => !x.toDelete)?.map((file, i) => {
            var uploader = file?.uploader
            var author = UserService.getUser(uploader)

            // Flatten for sort
            file._displayAuthor = author
            file._displayAuthorName = author?.name

            const aiTitles = file?._displayActionItems?.map(ai => ai?.title).join("-")
            file.searchText = (`${file?.title}-${file?._displayAuthorName}-${aiTitles}`).toLowerCase()

            return file
        })
        setProcessedList(fList)
        setSearchText(null)
        setSearchedList(fList)
    }

    const shortened = (str) => {
        const n = 10
        const s = (str.length > n) ? str.substr(0, n - 1) + '...' : str;
        return s
    }

    const updateSearch = (text) => {
        setSearchText(text)
        const searchList = processedList?.filter(x => searchFilter(x, text))
        setSearchedList(searchList)
    }

    const deleteFileInfo = async (i) => {
        var file = files[i]
        const newFile = { ...file, toDelete: true }

        await FileService.putFileInfo([newFile])
        var newF = [...files]
        newF[i] = newFile
        setFiles(newF)
    }

    const updatePendingFileInfo = (i, newInfo) => {
        var newF = [...filesToAdd]
        newF[i] = newInfo
        setFilesToAdd(newF)
    }

    const deletePendingFileInfo = (i) => {
        var newF = [...filesToAdd]
        newF.splice(i, 1);
        setFilesToAdd(newF)
    }

    const startFileAdd = (files) => {
        const newF = [...filesToAdd]

        Object.keys(files).map(function (key, index) {

            const file = files[key]
            var fileExtRe = /(?:\.([^.]+))?$/;
            var matches = fileExtRe.exec(file.name);
            if (matches.length < 1) {
                throw Error('File Name is invalid and cannot determine extension')
            }
            const extension = matches[1]
            var type = "Other"
            switch (extension) {
                case 'pdf':
                    type = "PDF"
                    break;
                case 'ppt':
                case 'pptx':
                    type = "PowerPoint"
                    break;
            }

            newF.push({
                'title': file.name,
                'description': '',

                'file': file,
                'type': type
            })
        });
        setFilesToAdd(newF)
        setFileModalOpen(true)

        return false
    }

    const addFiles = async () => {
        try {
            DelphiLogger.logMessage(`Uploading ${filesToAdd.filter(x => !x.toDelete).length} files`)
            const filesCreated = await FileService.uploadFiles(filesToAdd.map(f => f.file), curProject)
            DelphiLogger.logMessage(`Updating FileInfo for ${filesToAdd.length} files`)

            filesCreated.map((fileCreated, i) => {
                var fileInfo = filesToAdd.find(f => f?.file?.name == fileCreated.filename)
                delete fileInfo.file
                fileInfo._id = fileCreated._id
            })

            await FileService.putFileInfo(filesToAdd)
            DelphiLogger.logMessage(`Success: Uploaded ${filesToAdd.length} files`)

            if (refreshData) {
                await refreshData()
            }
            setFilesToAdd([])
            setFileModalOpen(false)
        }
        catch (err) {
            DelphiLogger.logError("File Upload failed", err.message)
        }
    }

    const downloadLink = createRef()


    return (
        isLoading ? <></> :
            <Card stretch>
                <CardHeader tag='h4' className='h5'>
                    <CardLabel icon={"FileEarmark"} iconColor="success">
                        <CardTitle>
                            {
                                curProject ? `All Files For Deal '${curProject?.title}'`: "File List"
                            }
                        </CardTitle>
                    </CardLabel>
                    <CardActions>{
                        isMinimized ?
                            <>
                                <Button
                                    color='success'
                                    icon='Upload'
                                    onClick={() => { setIsUploadModalOpen(true) }}
                                />
                                <Button
                                    color='info'
                                    icon='ChevronRight'
                                    isLight
                                    onClick={() => { navigate(`/files/deal/${curProject._id}`) }}
                                // TODO: New tracker modal
                                >
                                    More
                                </Button>
                            </> :
                            <Input
                                placeholder="Search"
                                style={{ maxWidth: "1400px" }}
                                onChange={e => updateSearch(e.target.value)}

                            />
                    }
                    </CardActions>
                </CardHeader>
                <CardBody isScrollable>
                    {
                        !isMinimized && startFileAdd && <div className='row'>
                            <div className='col-auto'>
                                <FileDropPad startFileAdd={startFileAdd} />
                                <br />
                                <br />
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-12'>
                            <table className='table table-modern' id="demoDT">
                                <thead>
                                    <tr>
                                        {
                                            !isMinimized &&
                                            <th scope="row"
                                                // onClick={() => requestSort('date')}
                                                className='int cursor-pointer text-decoration-underline'
                                            >
                                            </th>
                                        }
                                        <th
                                            className='int cursor-pointer text-decoration-underline' scope="row"
                                            onClick={() => requestSort('title')}
                                        >
                                            File Name
                                        </th>
                                        {!isMinimized &&
                                            <>
                                                <th
                                                    className='int cursor-pointer text-decoration-underline' scope="row"
                                                    onClick={() => requestSort('_displayAuthorName')}
                                                >
                                                    Author
                                                </th>
                                                <th
                                                    className='int' scope="row"
                                                >
                                                    Action Items
                                                </th>
                                                <th
                                                    className='int cursor-pointer text-decoration-underline' scope="row"
                                                    onClick={() => requestSort('uploadSize')}
                                                >
                                                    Size
                                                </th>
                                            </>
                                        }
                                        {
                                            isMinimized &&
                                            <th
                                                className='int cursor-pointer text-decoration-underline' scope="row"
                                                onClick={() => requestSort('createdDate')}
                                            >
                                                Type
                                            </th>
                                        }
                                        {!isMinimized &&
                                            <th
                                                className='int cursor-pointer text-decoration-underline' scope="row"
                                                onClick={() => requestSort('createdDate')}
                                            >
                                                Created
                                            </th>
                                        }
                                        <th
                                            className='int cursor-pointer text-decoration-underline' scope="row"
                                            onClick={() => requestSort('updatedDate')}
                                        >
                                            Last Updated
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataPagination(items, currentPage, perPage).map((file, i) => {

                                        const link = createRef()
                                        const titleLink = createRef()

                                        return <tr>
                                            {
                                                !isMinimized &&
                                                <th>
                                                    <div className="file-button-set">
                                                        <Button color="danger" icon="xLg"
                                                            onClick={
                                                                () => updateFile({ ...file, toDelete: true })
                                                            }
                                                        />
                                                        <a role='button' className='file-btn' ref={link}  >
                                                            <Button onClick={async () => { await FileService.navigateToDownload(file, link) }} icon="CloudArrowDown" color="success" >
                                                            </Button>
                                                        </a>
                                                        {
                                                            file?.type == "PDF" &&
                                                            <Link role='button' to={`/pdfView/${file._id}`}>
                                                                <Button onClick={async () => { }} icon="Pencil" color="warning" >
                                                                </Button>
                                                            </Link>
                                                        }
                                                    </div>
                                                </th>
                                            }
                                            <td>
                                                {
                                                    isMinimized ?
                                                        <div >
                                                            <span>
                                                                <a role='button' className='file-btn' ref={titleLink}  >
                                                                    <Button onClick={async () => { await FileService.navigateToDownload(file, titleLink) }} icon="Download" >
                                                                    </Button>
                                                                </a>
                                                                {file?.title}
                                                            </span>
                                                        </div>
                                                        :
                                                        <TitleColumn task={file} updateTask={(file, i, j) => updateFile(file)} />
                                                }

                                            </td>


                                            {!isMinimized &&
                                                <>
                                                    <td>
                                                        <div style={{ color: "black" }}><img className="avatarImg" src={file?._displayAuthor?.picture} height="30px" width="30px" />{file?._displayAuthor?.name} </div>
                                                    </td>
                                                    <td>
                                                        <div className='row'>
                                                            {
                                                                file?._displayActionItems?.map(ai => {
                                                                    return <div class="col-4">
                                                                        <Badge
                                                                            onClick={() => focusOnTask(ai?._id)}
                                                                        >
                                                                            {shortened(ai?.title)}
                                                                        </Badge>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {bytesToSize(file?.uploadSize)}
                                                    </td>
                                                </>
                                            }
                                            {
                                                isMinimized &&
                                                <td>
                                                    {file?.type}
                                                </td>
                                            }
                                            {!isMinimized &&
                                                <td>
                                                    {moment(file?.createdDate).fromNow()}
                                                </td>
                                            }
                                            <td>
                                                {moment(file?.updatedDate).fromNow()}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table >
                        </div>
                    </div>
                </CardBody>
                {
                    !isMinimized &&
                    <PaginationButtons
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                }

                <SimpleTaskPanel
                    isSimpleTaskPanelOpen={isSimpleTaskPanelOpen}
                    setIsSimpleTaskPanelOpen={setIsSimpleTaskPanelOpen}
                    taskId={focusTaskId}
                />

                <NewFileModal
                    setIsOpen={setFileModalOpen}
                    isOpen={fileModalOpen}
                    files={filesToAdd}
                    updateInfo={updatePendingFileInfo}
                    deletePendingFileInfo={deletePendingFileInfo}
                    addFiles={addFiles}
                />

                <AddFileModal
                    setIsOpen={setIsUploadModalOpen}
                    isOpen={isUploadModalOpen}
                    curProject={curProject}
                    callback={refreshData}
                />
            </Card >
    )
}