import { resolveHookState } from 'react-use/lib/misc/hookState';
import DelphiLogger from '../services/DelphiLogger';
import DelphiConfigProvider from './DelphiConfigProvider';
import { v4 as uuidv4 } from 'uuid';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0ClientService from './Auth0ClientService';
import UserService from './UserService';
class ProjectService {
    static auth0client = null;

    static async getTrackers(projectId) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects/${projectId}/trackers`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async getProjectWorkstreams() {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects/all/projectWorkstreams`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async getProject(projectId) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects/${projectId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }


    // TODO should be by org ID
    static getProjects() {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                };

                const url = `${DelphiConfigProvider.config.apiEndpoint}/projects`

                return fetch(url, requestOptions)
            }).then(async (resp) => {

                const projects = await resp.json()

                // Return the number of tasks saved
                DelphiLogger.logMessage(
                    'Projects retrieved successfully',
                )

                return (projects)
            })
            .catch((error) => {
                DelphiLogger.logError(
                    `Projects retrieve failed`,
                    `Projects retrieve failed ${error}`,
                )
            })
    }

    // TODO should be by org ID
    static getProjectHistory(projectIds) {

        const projectIdString = projectIds.join(',')

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                };

                const url = `${DelphiConfigProvider.config.apiEndpoint}/history/project/${projectIdString}`

                return fetch(url, requestOptions)
            }).then(async (resp) => {
                // Return the number of tasks saved
                const history = await resp.json()
                DelphiLogger.logMessage(
                    'Project history retrieved successfully',
                    'Project history retrieved successfully'
                )
                return (history)
            })
            .catch((error) => {
                DelphiLogger.logError(
                    `Project history  retrieve failed`,
                    `Project history  retrieve failed ${error}`,
                )
            })
    }

    // TODO should be by org ID
    static getProjectStats(projectIds) {

        const projectIdString = projectIds?.join(',')

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                };

                const url = `${DelphiConfigProvider.config.apiEndpoint}/stats/project/${projectIdString}`

                return fetch(url, requestOptions)
            }).then(async (resp) => {

                // Return the number of tasks saved
                const stats = await resp.json()
                DelphiLogger.logMessage(
                    'Project history retrieved successfully',
                    'Project history retrieved successfully'
                )
                return (stats)
            })
            .catch((error) => {
                DelphiLogger.logError(
                    `Project history  retrieve failed`,
                    `Project history  retrieve failed ${error}`,
                )
            })
    }

    static saveProject(projects) {

        for (var project of projects) {

            const projectId = project._id
            const org = UserService.getMyCurrentOrg()

            if (!projectId) {
                const uid = uuidv4()
                project._id = uid
            }

            if (!project.organization && org) {
                project.organization = org.id
            }

            return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
                .then((accessToken) => {

                    const requestOptions = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        },
                        body: JSON.stringify(projects)
                    };

                    return fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects`, requestOptions)
                })
                .then(() => {

                    // Return the number of tasks saved
                    DelphiLogger.logMessage(
                        'Project saved successfully',
                        `'${project.title}' saved`,
                    )

                    return (project)
                })
                .catch((error) => {
                    DelphiLogger.logError(
                        `Project save failed`,
                        `Project save failed ${error}`,
                    )
                })

        }
    }

    static async getAnnouncements(project) {
        var announcements = []
        try {
            const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
            };

            const url = `${DelphiConfigProvider.config.apiEndpoint}/projects/${project._id}/announcements`
            var resp = await fetch(url, requestOptions)
            announcements = await resp.json()

            DelphiLogger.logMessage(
                'Announcements retrieved successfully',
                `'${announcements.length}' retrieved`,
            )

            return announcements
        }
        catch (err) {
            DelphiLogger.logError(
                `Announcements retrieve failed`,
                `Announcements retrieve failed ${err}`,
            )

            return announcements
        }

    }

    static async saveLists(p, lists, overwrite = false) {
        const projectId = p._id
        for (const l of lists) {
            l.project = p._id
            if (!l._id) {
                const uid = uuidv4()
                l._id = uid
            }
        }

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const overwriteString = overwrite ? "?overwrite=true" : ""
        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects/${projectId}/lists${overwriteString}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(lists)
        })

        const ret = await resp.json()
        return ret
    }

    static async getLists(p, listId = null) {

        const projectId = p._id
        const listIdPath = listId ? `/${listId}` : "";

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects/${projectId}/lists${listIdPath}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async putAnnouncement(project, announcements) {
        try {
            const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            const url = `${DelphiConfigProvider.config.apiEndpoint}/projects/${project._id}/announcements`

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(announcements)
            };

            var resp = await fetch(url, requestOptions)
            const newAnnouncements = await resp.json()

            DelphiLogger.logMessage(
                'Announcements put successfully',
                `${newAnnouncements.length}`,
            )
            return newAnnouncements
        }
        catch (err) {
            DelphiLogger.logError(
                `Announcements put failed`,
                `Announcements put failed ${err}`,
            )
        }
    }

    static async deleteAnnouncement(project, announcements) {
        try {
            const announceIds = announcements.map(x => x._id).join(',')
            const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

            const url = `${DelphiConfigProvider.config.apiEndpoint}/projects/${project._id}/announcements/${announceIds}`
            const requestOptions = {
                method: 'Delete',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            };
            var ret = await fetch(url, requestOptions);

            DelphiLogger.logMessage(
                'Announcements deleted successfully',
                `${announcements.length} deleted`,
            )

            return ret
        }
        catch (err) {
            DelphiLogger.logError(
                `Announcements delete failed`,
                `Announcements delete failed ${err}`,
            )
        }
    }
}

export default ProjectService;