// import FormGroup from './bootstrap/forms/FormGroup';
// import PhaseService from '../../services/PhaseService';
import Modal, {
    ModalBody, ModalHeader
} from '../../components/bootstrap/Modal';
import { BuyerTrackingRowSelect } from './NewTracker/WizardSteps/BuyerTrackingRowSelect';

export const TrackerRowModal = ({ isTrackerRowModalOpen, setIsTrackerRowModalOpen }) => {
    return (
        <Modal
            setIsOpen={setIsTrackerRowModalOpen}
            isOpen={isTrackerRowModalOpen}
            titleId='projectPhases'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsTrackerRowModalOpen}>
                Edit Project Phases
            </ModalHeader>
            <ModalBody>
                <BuyerTrackingRowSelect />
            </ModalBody>
        </Modal>
    )
}
