const ENV = {
	REACT_APP_MOBILE_BREAKPOINT_SIZE: 768,
	REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE: 1024,
	REACT_APP_META_DESC: 'Finance Made Simple',

	REACT_APP_MODERN_DESGIN: 'true',
	REACT_APP_ASIDE_TOUCH_STATUS: 'true',

	REACT_APP_DARK_MODE: 'false',

	REACT_APP_ASIDE_WIDTH_PX: 195,
	REACT_APP_SPACER_PX: 13,

	REACT_APP_PRIMARY_COLOR: '#6c5dd3',
	REACT_APP_SECONDARY_COLOR: '#ffa2c0',
	REACT_APP_SUCCESS_COLOR: '#46bcaa',
	REACT_APP_INFO_COLOR: '#4d69fa',
	REACT_APP_WARNING_COLOR: '#ffcf52',
	REACT_APP_DANGER_COLOR: '#f35421',
	REACT_APP_LIGHT_COLOR: '#e7eef8',
	REACT_APP_DARK_COLOR: '#1f2128',
	REACT_APP_SITE_NAME: 'Finspace',
	REACT_APP_STORYBOOK_URL: 'http://localhost:6006/?path=',
};

export default ENV;
