import { Dots } from 'loading-animations-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../components/bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import classNames from 'classnames';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../components/bootstrap/Card';
import Progress from '../../components/bootstrap/Progress';
import AvatarTeam from '../../components/common/AvatarTeam';
import Icon from '../../components/icon/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import DelphiLogger from '../../services/DelphiLogger';
import PhaseService from '../../services/PhaseService';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';

export const EmailOutreachItem = ({ cols = 4, project, inProjectStats, updateProject, i, isMinimized = true, ...props }) => {

    const { darkModeStatus } = useDarkMode();
    const [isLoading, setIsLoading] = useState(true);
    const [projectStats, setprojectStats] = useState({})

    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false)
    const navigateToTracker = () => {
        if (!isDeleted) {
            navigate(`/trackerBoard/${project._displayTrackers[0]._id}`);
        }
    }

    useEffect(async () => {
        setIsLoading(true)
        await UserService.initialize()
        const projectStats = inProjectStats ?? await ProjectService.getProjectStats([project._id])
        setprojectStats(projectStats)
        setIsLoading(false)
    }, []);

    const deleteProject = () => {

        const isConfirmed = window.confirm("Are you sure want to delete");

        if (isConfirmed) {
            updateProject(i, { ...project, toDelete: true })
        }

    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={`col-md-${cols}`} {...props}>
            <Card stretch>
                {
                    isLoading ? <></> :
                        <>
                            <CardHeader
                                className={classNames({
                                    "cursor-pointer": true,
                                })}
                                style={isMinimized ? { borderRadius: "2rem 2rem 2rem 2rem" } : {}}
                                onClick={
                                    () => { navigateToTracker() }
                                }
                            >
                                <CardLabel icon='Envelope' iconColor="info">
                                    <Link to={`/trackerBoard/${project._displayTrackers[0]._id}`}>
                                        <CardTitle>{project.title}</CardTitle>
                                    </Link>
                                    <CardSubTitle>Tracker: {project._displayTrackers[0].title}</CardSubTitle>
                                </CardLabel>
                            </CardHeader>
                        </>
                }
            </Card>
        </div>
    )
}

export default EmailOutreachItem;