import { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { FileUploader } from "react-drag-drop-files";
import Button from '../bootstrap/Button';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../bootstrap/Card';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import Label from '../bootstrap/forms/Label';
import DelphiLogger from '../../services/DelphiLogger';
var xlsx = require("xlsx");

export const BuyerListImport = ({ buyersList, updateBuyersList }) => {

    const [isOverWrite, setIsOverWrite] = useState(true)
    const startFileAdd = (files) => {

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);

            if (isOverWrite) {
                updateBuyersList(json)
            }
            else {
                updateBuyersList([...buyersList, ...json])
            }
            console.log(json);
        };
        reader.readAsArrayBuffer(files[0]);
    }

    const styleOptions = {
        search: true,
        pageSize: 5,
        exportButton: true,
        defaultExpanded: true,
        maxBodyHeight: "300px"
    }

    const actions = [
        {
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (event, rowData) => { }
        }
    ]

    const cols = [
        {
            title: "Fund Name",
            field: "Fund Name",
        },
        {
            title: "Contact Name",
            field: "Contact Name",
        },
        {
            title: "Contact Email",
            field: "Contact Email",
        },
        {
            title: "Fund Size",
            field: "Fund Size",
        },
        {
            title: "Enabled",
            field: "Enabled",
        },
        {
            title: "Type",
            field: "Type",
        },
        {
            title: "Tier",
            field: "Tier",
        }
    ]

    return (
        <Card isCompact className='mb-0'>
            <CardHeader>
                <CardLabel>
                    <CardTitle>(Optional) Start importing your buyer list.
                    </CardTitle>
                    <br />
                    <CardSubTitle>
                        You can also add buyer to the deal later. The import excel must have the buyers as the first page. The
                        first page must contain a "Fund Name", "Contact Name", "Contact Email" column. Optional columns include "Fund Size", "Enabled" (TRUE/FALSE),
                        "Type" (Strategic/Financial), "Tier" (1,2,3)
                    </CardSubTitle>
                    <br />
                </CardLabel>
            </CardHeader>
            <CardBody>
                <div className='row'>
                    <div className='col-auto'>
                        <Button
                            color='success'
                            icon='CloudDownload'
                            isLight
                            tag='a'
                            to='/BuyersList.xlsx'
                            target='_blank'
                            download>
                            Example
                        </Button>
                    </div>
                    <div className='col-auto'>
                        <FileUploader onSelect={(file) => { startFileAdd(file); }} onDrop={(file) => { startFileAdd(file); }} name="file" multiple={true} />
                    </div>
                    <div className='col-auto'>
                        <FormGroup className='col-lg-6'>
                            <Label>OverWrite</Label>
                            <Checks
                                type='checkbox'
                                id='exampleLabelThree'
                                onChange={e => setIsOverWrite(e.target.checked)}
                                checked={isOverWrite}
                            />
                        </FormGroup>
                    </div>
                    <br />
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <br />
                        <MaterialTable
                            className="TaskTable "
                            data={buyersList}
                            columns={cols}
                            options={styleOptions}
                            editable={{
                                onRowAdd: async newData => {
                                    updateBuyersList([...(buyersList ?? []), newData]);
                                },
                                onRowUpdate: async (newData, oldData) => {
                                    const dataUpdate = [...buyersList];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    updateBuyersList([...dataUpdate]);
                                },
                                onRowDelete: async oldData => {
                                    const dataDelete = [...buyersList];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    updateBuyersList([...dataDelete]);
                                }
                            }}
                        />
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}
