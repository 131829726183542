import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dots } from 'loading-animations-react';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import ProjectService from '../../../../services/ProjectService';
import { ProjectItem } from '../../../allprojects/ProjectItem';
import Button from '../../../../components/bootstrap/Button';
import { NewProjectModal } from '../../../projecttasks/modals/NewProjectModal/NewProjectModal';

export const ProjectsWidget = ({ }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
    const [projects, setProjects] = useState([])
    const navigate = useNavigate();

    const refreshData = async () => {
        var projects = await ProjectService.getProjects()
        projects = projects?.filter((x, i) => i < 3) ?? []
        setProjects(projects)
    }

    const updateProject = async (i, newProject) => {
        await ProjectService.saveProject([newProject])

        var newProjects = [...projects]
        newProjects[i] = newProject
        setProjects(newProjects)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, []);

    return (
        isLoading ? <></> :
            <Card stretch>
                <CardHeader>
                    <CardLabel icon='Files' iconColor='danger'>
                        <CardTitle tag='h4' className='h5'>
                            My Deals
                        </CardTitle>
                        {
                            /* <CardSubTitle>
                                {
                                    showComplete &&
                                    <Progress
                                        height={8}
                                        max={listLength}
                                        value={completeTaskLength}
                                        color={completeTaskLength === listLength ? 'success' : 'primary'}
                                    />
                                }
                            </CardSubTitle> */
                        }
                    </CardLabel>
                    <CardActions>
                        <Button
                            color='info'
                            icon='ArrowRight'
                            isLight
                            onClick={() => navigate("/deals")}>
                            View All
                        </Button>
                    </CardActions>
                </CardHeader>
                <CardBody isScrollable>
                    <div className='row mt-3'>
                        {
                            projects.filter((p, i) => !p?.toDelete).map((project, i) => {
                                return <ProjectItem
                                    isMinimized={true}
                                    cols={3}
                                    style={{ display: "inline-block" }}
                                    updateProject={updateProject}
                                    i={i} project={project} />
                            })
                        }
                        <div className='col-md-3'>
                            <Card stretch>
                                <CardBody className='d-flex align-items-center justify-content-center'>
                                    <Button
                                        color='info'
                                        size='lg'
                                        isLight
                                        className='w-100 h-100'
                                        icon='AddCircle'
                                        onClick={() => setIsNewProjectModalOpen(true)}>
                                        Add New Deal
                                    </Button>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </CardBody>
                <NewProjectModal
                    setIsOpen={setIsNewProjectModalOpen}
                    isOpen={isNewProjectModalOpen}
                />
            </Card>
    )
}