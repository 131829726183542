import Auth0ClientService from './Auth0ClientService';
import DelphiConfigProvider from './DelphiConfigProvider';

class HistoryService {

    static putHistory(itemId, historyEntries) {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(historyEntries)
                };

                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/history/${itemId}`, requestOptions);

            })
    }

    static deleteHistory(commentId) {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'Delete',
                    Authorization: `Bearer ${accessToken}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                };

                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/history/${commentId}`, requestOptions);

            })

    }
}

export default HistoryService;