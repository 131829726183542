import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faComment, faCheckCircle, faTrashCan, faDiagramPredecessor, faDotCircle } from '@fortawesome/free-regular-svg-icons'
import classNames from 'classnames'

const TitleColumn = ({ task, updateTask, workstreamIndex, taskIndex }) => {

    const isEmptyOrSpaces = (str) => {
        return str === null || str.match(/^ *$/) !== null;
    }

    let spanStyle = {
        minWidth: "40px",
        display: "inline-block"
    }

    if (task.title == null || isEmptyOrSpaces(task.title)) {
        spanStyle.border = "red"
        spanStyle.borderStyle = "solid"
        spanStyle.borderRadius = "4px"
    }

    return (
        <div className="task-tit-div">
            <a>
                <span style={{ display: task.history && task.history.some(x => x.type == 'comment') ? "" : "none" }} clickable="true" className='taskTableComments'>
                    <FontAwesomeIcon icon={faComment} />
                </span>
            </a>
            <a>
                <span style={{ display: task._displaySubTasks && task._displaySubTasks?.filter(x => !x?.toDelete).length > 0 ? "" : "none" }} clickable="true" className='taskTableComments'>
                    <FontAwesomeIcon icon={faDotCircle} />
                </span>
            </a>
            <span
                style={spanStyle}
                className={'title-column-span truncate-line-2 task-label' + (task.status === "Complete" ? "task-complete " : "")}
                contentEditable
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        e.target.blur();
                    }
                }}
                onBlur={updateTask && (e => updateTask({ ...task, title: e.currentTarget.textContent }, workstreamIndex, taskIndex))}
            >
                {task.title}
            </span>
        </div>
    )
}

const TitleH1 = ({ task, updateTask, workstreamIndex, taskIndex }) => {

    const isEmptyOrSpaces = (str) => {
        return str === null || str.match(/^ *$/) !== null;
    }

    let spanStyle = {
        minWidth: "40px",
        display: "inline-block"
    }

    if (task.title == null || isEmptyOrSpaces(task.title)) {
        spanStyle.border = "red"
        spanStyle.borderStyle = "solid"
        spanStyle.borderRadius = "4px"
    }

    return (
        <div>
            <h1>
                <span
                    className='title-column-span'
                    style={spanStyle}
                    contentEditable
                    onBlur={e => updateTask({ ...task, title: e.currentTarget.textContent }, workstreamIndex, taskIndex)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            e.target.blur();
                        }
                    }}
                >
                    {task.title}
                </span>
            </h1>
        </div>
    )
}

const TitleHeader = ({ sortProperty, setSortProperty }) => {
    return (
        <th style={{ width: "95%" }} scope="col">Task</th>
    )
}


export { TitleColumn, TitleHeader, TitleH1 }