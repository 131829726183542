import config from './config/config'
import ppeConfig from './config/config.ppe'
import prodConfig from './config/config.prod'
import env from "react-dotenv";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

class DelphiConfigProvider {

  static config = {}
  static isProd = false
  static showLogNotifications = true;
  static showErrorNotifications = true;

  static appendConfig(config) {
    DelphiConfigProvider.config = mergeDeep(DelphiConfigProvider.config, config)
  }

  static
  {
    DelphiConfigProvider.appendConfig(config)

    switch (window.location.host) {
      case 'finspace.app':
      case 'www.finspace.app':
      case 'app.finspace.app':
        DelphiConfigProvider.appendConfig(prodConfig)
        DelphiConfigProvider.isProd = true
        DelphiConfigProvider.showLogNotifications = false
        break;
      case 'ppe.finspace.app':
        DelphiConfigProvider.appendConfig(ppeConfig)
        break;
      default:
        break;

    }
  }
}

export default DelphiConfigProvider;