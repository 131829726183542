import { useAuth0 } from "@auth0/auth0-react";
import { Dots } from 'loading-animations-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../services/DelphiLogger';
import EmailService from '../../services/EmailService';
import UserService from '../../services/UserService';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle
} from '../bootstrap/Card';
import Select from '../bootstrap/forms/Select';
import { EmailAttachment } from '../../pages/tracker/NewTracker/WizardSteps/EmailAttachment';
import { OtherAttachment } from '../../pages/tracker/NewTracker/WizardSteps/OtherAttachment';
import './EmailPreview.css';

export const NewEmailPreview = ({ selectedRows, email, me }) => {

    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfFile, setPdfFile] = useState(false);
    const [pdfInstance, setPdfInstance] = useState(false);
    const [documentViewer, setDocumentViewer] = useState(null);
    const [rowIndex, setRowIndex] = useState(0)
    const [emailbody, setEmailBody] = useState('');
    const [org, setOrg] = useState(null)
    const viewer = useRef(null);
    const { fileId } = useParams();
    const navigate = useNavigate();
    const { user, logout } = useAuth0();

    DelphiLogger.debug(email, "EmailPreview")

    const selectedItem = selectedRows?.length > 0 ? selectedRows[rowIndex] : {}

    var convertedEmail = email
    if (selectedRows?.length > 0) {
        convertedEmail = EmailService.fillTemplate(email, selectedItem, user, org)
    }

    useEffect(async () => {
        setIsLoading(true)
        await UserService.initialize()
        const org = await UserService.getMyCurrentOrg()
        setOrg(org)
        setIsLoading(false)
    }, [])


    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            : !(selectedRows?.length > 0) ?
                <PageWrapper className="justify-content-center text-center" title="Tasks">
                    <p class="lead fw-bold">No Recipients Selected</p>
                </PageWrapper> :
                <>
                    <Card>
                        <CardHeader>
                            <CardLabel icon='ExclamationTriangleFill' iconColor="danger">
                                <CardTitle>You are about to send ({selectedRows?.length}) emails. Validate the content here.</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p className='lead fw-bold'>Email</p>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>Customer:</strong> </div>
                                </div>
                                <div className='col-md-12'>
                                    <div style={{ paddingTop: "5px" }}>
                                        <Select
                                            value={rowIndex}
                                            onChange={e => setRowIndex(e.target.value)}>
                                            {
                                                selectedRows?.map((row, i) => {
                                                    return <option value={i}>{row["user_metadata"] ? row["user_metadata"]["company_name"] : ""} ({row["name"]})</option>
                                                }) ?? []
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className='col-md-12'>
                                    {selectedRows?.length > 0 && <div ><strong>To: </strong>{selectedItem["name"]} ({selectedItem["email"]}) </div>}
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>Subject: </strong>{convertedEmail?.subject}</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>From: </strong>{me?.name} ({email?.fromEmail}@finspace.app)</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>ReplyTo: </strong>{me?.name} ({me?.email})</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>CC: </strong>{email?.cc}</div>
                                </div>
                                <br />
                                <div className='col-md-12'>
                                    <div ><strong>Body:</strong> </div>
                                </div>
                                <br />
                                <div className='col-md-12'>
                                    <div >
                                        <ReactQuill
                                            value={convertedEmail?.body}
                                            onChange={() => { }}
                                            readOnly={true}
                                            modules={{ toolbar: false }}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </CardBody>
                    </Card>
                    {
                        email?.attachments?.map((attachment, i) => {
                            switch (attachment?.type) {
                                case "PDF":
                                    return <EmailAttachment
                                        row={selectedRows?.length > 0 ? selectedRows[rowIndex] : {}}
                                        enabled={false}
                                        attachment={attachment}
                                        setAttachment={(a) => {
                                        }}
                                        deleteAttachment={() => { }}
                                    />
                                    break;

                                default:
                                    return <OtherAttachment
                                        enabled={false}
                                        attachment={attachment}
                                        setAttachment={(a) => {
                                        }}
                                        deleteAttachment={() => { }}
                                    />
                                    break;
                            }
                        })
                    }
                </>
    )
}
