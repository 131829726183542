import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faComment, faCheckCircle, faTrashCan, faGrip } from '@fortawesome/free-regular-svg-icons'
import { useAuth0, } from "@auth0/auth0-react";
import { TitleColumn } from './columns/TitleColumn'
import { StatusColumn } from './columns/StatusColumn'
import { PriorityColumn } from './columns/PriorityColumn'
import { DueDateColumn } from './columns/DueDateColumn'
import { TagColumn } from './columns/TagColumn'
import { ActionColumn } from './columns/ActionColumn'
import { AssigneesColumn } from './columns/AssigneesColumn'

import CreatableSelect from 'react-select/creatable';
import HistoryService from '../../services/HistoryService';
import DelphiLogger from '../../services/DelphiLogger';
import { display } from '@mui/system';
import { ProjectColumn } from './columns/ProjectColumn';
import { WorkstreamColumn } from './columns/WorkstreamColumn';

export const TaskItem = ({ provided, filterSort, snapshot, selectMode, task, workstreamIndex, taskIndex,
    updateTask, updateTaskTags, dropDownOptions, onTagChange, setIsTaskPanelOpen,
    setFocusTask, displaySettings, isMyTasks = false }) => {

    const { user } = useAuth0();

    // TODO
    const filterTags = (t, s) => {
        return t?.tags?.find(x => x?.label?.toLowerCase().includes(s))
    }

    // TODO
    const filterAssignee = (t, s) => {
        return false
    }

    const filterTask = (t) => {
        var matches = true
        var search = filterSort?.search?.toLowerCase()
        matches = matches && (!search || (
            t?.title?.toLowerCase().includes(search) ||
            t?.priority?.toLowerCase().includes(search) ||
            t?.status?.toLowerCase().includes(search) ||
            t?.title?.toLowerCase().includes(search) ||
            filterAssignee(t, search) ||
            filterTags(search)
        ))
        return matches
    }

    const isFiltered = !filterTask(task)

    const columnProps = {
        task: task,
        updateTask: updateTask,
        workstreamIndex: workstreamIndex,
        dropDownOptions: dropDownOptions,
        taskIndex: taskIndex,
        selectMode: selectMode,
        updateTaskTags: updateTaskTags
    }

    return (
        <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            className={`task-row ${task.status == 'Complete' ? "task-complete " : ""} ${task.toDelete || isFiltered ? "d-none" : ""}`}
            // style={rowStyle}}
            // onClick={() => setFocusTask(task, workstreamIndex, taskIndex)}
            onDoubleClick={() => {
                setFocusTask(task, workstreamIndex, taskIndex)
                setIsTaskPanelOpen(true)
            }}
        >

            <td style={{ maxWidth: "80px" }} className="task-action-btn task-check">
                <ActionColumn provided={provided} {...columnProps} />
            </td>
            <td className="task-label">
                <TitleColumn  {...columnProps} />
            </td>
            {
                !isMyTasks &&
                <td className="task-label">
                    <AssigneesColumn {...columnProps} />
                </td>
            }
            {
                !isMyTasks && dropDownOptions.projectOptions &&
                <>
                    <td className="task-label" >
                        <ProjectColumn {...columnProps} />
                    </td>
                    <td className="task-label">
                        <WorkstreamColumn {...columnProps} />
                    </td>
                </>
            }
            {
                !dropDownOptions.projectOptions &&
                <>
                    <td className="task-label">
                        <StatusColumn {...columnProps} />
                    </td>
                    <td className="task-label">
                        <PriorityColumn {...columnProps} />
                    </td>
                </>
            }
            <td className="task-label">
                <DueDateColumn {...columnProps} />
            </td>
        </tr>
    )
}
