import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../../services/DelphiLogger';
import ProjectService from '../../../services/ProjectService';
import Button from '../../bootstrap/Button';
import Modal, {
    ModalBody, ModalFooter, ModalHeader, ModalTitle
} from '../../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../bootstrap/OffCanvas';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../bootstrap/Card';
import UserService from '../../../services/UserService';
import * as Yup from 'yup';
import { WizardGenerator } from '../../WizardGenerator/WizardGenerator';
import TrackerService from '../../../services/TrackerService';
import TaskService from '../../../services/TaskService';
import TemplateService from '../../../services/TemplateService';

export const TrackerSaveTemplateModal = ({ isTrackerSaveTemplateModalOpen, setIsTrackerSaveTemplateModalOpen, trackerWorkstreams, curProject }) => {

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Loading")

    const refreshData = async () => {
        await UserService.initialize()
        var user = UserService.getMe()
        setUser(user)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, []);

    const onSubmit = async (formData) => {
        const savedWorkStreams = await Promise.all(
            trackerWorkstreams?.map(x => TemplateService?.saveWorkStreamTemplate(x))
        )
        
        const wsIds = savedWorkStreams?.map(x => x?._id)

        TemplateService.saveTrackerTemplate(
            {
                title: formData?.name,
                workStreams: wsIds
            }
        )

        setIsTrackerSaveTemplateModalOpen(false)
    }

    const wizardConfig = {
        onSubmit: onSubmit,
        icon: "Bullseye",
        steps: [
            {
                title: `Save Tracker As Template - ${trackerWorkstreams?.length} Tasks (Columns)`,
                cards: [
                    {
                        fields: [
                            {
                                id: "name",
                                title: "Template Name",
                                type: "input",
                                init: `${curProject?.title} Sell-Side Template`,
                                validate: Yup.string().required("Template Name is required")
                            },
                            {
                                id: "saveMine",
                                title: "Save To My Templates",
                                type: "check"
                            },
                            {
                                id: "saveOrg",
                                title: "Save To Org Templates",
                                type: "check"
                            },
                            {
                                id: "saveGlobal",
                                title: "Save To Global Templates",
                                type: "check"
                            }
                        ]
                    }
                ]
            }
        ]
    }


    return (
        <Modal
            setIsOpen={setIsTrackerSaveTemplateModalOpen}
            isOpen={isTrackerSaveTemplateModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsTrackerSaveTemplateModalOpen}>
                <OffCanvasTitle id='upcomingEdit'></OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                {
                    isLoading ?
                        <PageWrapper className="justify-content-center text-center" title="Tasks">
                            <div className=" justify-content-center text-center" title="Tasks">
                                <Dots text={loadingMessage}></Dots>
                            </div>
                        </PageWrapper>
                        :
                        <WizardGenerator wizardObject={wizardConfig} />
                }
            </ModalBody>
        </Modal>
    )
}