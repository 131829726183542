import { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { FileUploader } from "react-drag-drop-files";
import Button from '../bootstrap/Button';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../bootstrap/Card';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import Label from '../bootstrap/forms/Label';
import DelphiLogger from '../../services/DelphiLogger';
import { Input } from '@material-ui/core';
import UserService from '../../services/UserService';
import classNames from 'classnames';
var xlsx = require("xlsx");

function setProperty(obj, path, value) {
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
}

function getProperty(obj, path) {
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {}
        schema = schema[elem];
    }

    return schema[pList[len - 1]];
}

export const WorkingGroupListImport = ({ workingGroupList, updateWorkingGroupList }) => {

    const isEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isNullOrWhiteSpace = (str) => {
        return str === null || str.match(/^ *$/) !== null;
    }

    const getCell = (rowData, colName) => {

        return <div className="center-block">
            <span
                style={{ fontWeight: rowData.isDirty ? "bold" : "normal" }}
                contentEditable
                placeholder='Add Email'
                className={classNames({
                    'bg-danger': colName == "email" && !isEmail(rowData.email)
                })}
                onBlur={e => {
                    var newData = { ...rowData }
                    setProperty(newData, colName, e.currentTarget.textContent)

                    const newWg = [...workingGroupList];
                    const index = rowData.tableData.id;
                    newWg[index] = newData
                    updateWorkingGroupList(newWg);
                }
                }>
                {getProperty(rowData, colName)}
            </span>
        </div>
    }

    const addByEmail = (emailStr) => {
        const emails = emailStr.split(/[\s,;,\,\t]+/)

        const users = emails?.map(e => e.trim()).filter(x => !isNullOrWhiteSpace(x))?.filter(e => {
            const toAdd = isEmail(e)
            if (!toAdd) {
                DelphiLogger.logError(`Invitee must be an email address`)
            }
            return toAdd
        }).map(email => {
            var user = UserService.getUser(email)
            user = user?.isFalseUser ? { email: email } : user
            return user
        })

        updateWorkingGroupList([...workingGroupList, ...users])
    }

    const [isOverWrite, setIsOverWrite] = useState(false)
    const [emailToAdd, setEmailToAdd] = useState("")

    const startFileAdd = (files) => {

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            var json = xlsx.utils.sheet_to_json(worksheet);
            json = json.map(j => ({
                given_name: j["First Name"],
                family_name: j["Last Name"],
                email: j["Email"],
                title: j["Title"],
                phone_number: j["Primary Contact Number"],
                user_metadata: {
                    position: j["Title"]
                }
            }))

            if (isOverWrite) {
                updateWorkingGroupList(json)
            }
            else {
                updateWorkingGroupList([...workingGroupList, ...json])
            }
            console.log(json);
        };
        reader.readAsArrayBuffer(files[0]);
    }

    const styleOptions = {
        search: true,
        pageSize: 5,
        exportButton: true,
        defaultExpanded: true,
        maxBodyHeight: "300px"
    }

    const actions = [
        {
            icon: 'add',
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: (event) => {
                updateWorkingGroupList([...workingGroupList, {}])
            }
        }
    ]

    const cols = [
        {
            title: "Email (required)",
            field: "email",
            render: rowData => getCell(rowData, "email")
        },
        {
            title: "First Name",
            field: "given_name",
            render: rowData => getCell(rowData, "given_name")
        },
        {
            title: "Last Name",
            field: "family_name",
            render: rowData => getCell(rowData, "family_name")
        },
        {
            title: "Primary Contact Number",
            field: "phone_number",
            render: rowData => getCell(rowData, "phone_number")
        },
        {
            title: "Title",
            field: "user_metadata.position",
            render: rowData => getCell(rowData, "user_metadata.position")
        }
    ]

    return (
        <Card isCompact className='mb-0'>
            <CardHeader>
                <CardLabel>
                    <CardSubTitle>
                        The import excel must have the list members as the first page. The first page must contain an "Email", column. Optional columns include "First Name", "Last Name", "Title", "Primary Contact Number", and "Mobile Number"
                    </CardSubTitle>
                    <br />
                </CardLabel>
            </CardHeader>
            <CardBody>
                <div className='row'>
                    <div className="col-md-auto">
                        <Input
                            placeholder="+Add By Email"
                            value={emailToAdd}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault()

                                    addByEmail(emailToAdd)
                                    setEmailToAdd("")
                                }
                            }}
                            onChange={e => setEmailToAdd(e.target.value)}
                        />
                    </div>
                    <div className="col-md-2">
                        <Button
                            color='success'
                            icon='PlusLg'
                            onClick={() => {
                                addByEmail(emailToAdd)
                                setEmailToAdd("")
                            }}>
                        </Button>
                    </div>
                    <div className='col-auto'>
                        <FileUploader onSelect={(file) => { startFileAdd(file); }} onDrop={(file) => { startFileAdd(file); }} name="file" multiple={true} />
                    </div>
                    <div className='col-auto'>
                        <FormGroup className='col-lg-6'>
                            <Label>OverWrite</Label>
                            <Checks
                                type='checkbox'
                                id='exampleLabelThree'
                                onChange={e => setIsOverWrite(e.target.checked)}
                                checked={isOverWrite}
                            />
                        </FormGroup>
                    </div>
                    <br />
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <br />
                        <MaterialTable
                            className="TaskTable "
                            data={[...workingGroupList]}
                            columns={cols}
                            options={styleOptions}
                            actions={actions}
                            editable={{
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            const dataDelete = [...workingGroupList];
                                            const index = oldData.tableData.id;
                                            dataDelete.splice(index, 1);
                                            updateWorkingGroupList([...dataDelete]);

                                            resolve()
                                        }, 1000)
                                    }),
                            }}
                        />
                    </div>
                </div>
                <br />
            </CardBody>
        </Card>
    )
}
