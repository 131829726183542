import React from 'react'
import Select, { createFilter } from 'react-select';

const AssigneesColumn = ({ task, updateTask, updateTaskTags, workstreamIndex, taskIndex, dropDownOptions }) => {

    var filter = {
        // default filter configuration
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.data.name} ${option.data.email}`,
        trim: true,
    }

    return (
        <div style={{ minWidth: "200px" }}>
            <Select className="tagSelect"
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={dropDownOptions.orgUserOptions}
                minMenuHeight={10}
                onChange={(newValue, actionMetaData) => updateTask({ ...task, assignees: newValue ? [newValue.user_id] : [], displayAssignees: newValue ? [newValue] : [] },
                    workstreamIndex, taskIndex)}
                form="false"
                value={task?.displayAssignees?.length > 0 ? task.displayAssignees[0] : null}
                filterOption={createFilter(filter)}
                isClearable={true}
            />
        </div>
    )
}

const AssigneesHeader = () => {
    return (
        <th scope="col">Assignee</th>
    )
}


export { AssigneesColumn, AssigneesHeader }