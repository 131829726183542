import React, { useState, useEffect } from 'react';
import { Calendar as DatePicker } from 'react-date-range';
import moment from 'moment';
import { useFormik } from 'formik';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Todo from '../../../components/extras/Todo';
import Label from '../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Badge from '../../../components/bootstrap/Badge';
import Progress from '../../../components/bootstrap/Progress';
import { useNavigate, useParams } from 'react-router-dom';
import ENV from '../../../ENV';
import TaskService from '../../../services/TaskService';
import { Dots } from 'loading-animations-react';
import MyTasksTodo from './MyTasksTodo';
import UserService from '../../../services/UserService';
import DelphiLogger from '../../../services/DelphiLogger';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { SubHeaderRight } from '../../../layout/SubHeader/SubHeader';

const MyTasksWidget = ({ title = "My Tasks", style = {}, icon = "AssignmentTurnedIn", curProject, useOrg = false, showComplete = true, filterTasks = () => true, showBanner = false,
    getNewTask }) => {

    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newTaskInput, setNewTaskInput] = useState("")
    const navigate = useNavigate();

    const incompleteFilter = (task) => task?.status != "Complete"
    const completeFilter = (task) => task?.status == "Complete"
    const allFilter = (task) => true
    const [viewNumber, setViewNumber] = useState(showComplete ? 3 : 1)
    var viewFilter = viewNumber == 1 ? incompleteFilter :
        (viewNumber == 2 ? completeFilter : allFilter)

    const navigateToTasks = curProject ? () => naviagteToProjectTasks() : () => navigateToMyTasks()

    const naviagteToProjectTasks = () => {
        const link = `/dealtasks/${curProject._id}`
        navigate(link);
    }

    const navigateToMyTasks = (taskId) => {
        const link = `/mytasks`
        navigate(link);
    }

    const updateTask = (newTask, ind) => {
        TaskService.putTask(newTask)
        var newTasks = [...tasks]
        newTasks[ind] = newTask

        DelphiLogger.debug(newTasks, "New Tasks")

        newTasks = newTasks?.filter((task) => !task.toDelete)
        newTasks = newTasks.filter(x => filterTasks(x))
        setTasks(newTasks)
    }

    const pullTasks = async (projectId) => {
        var workFlowStyle = projectId ? false : true
        var resp = await TaskService.getMyTasks([projectId], workFlowStyle)
        var tasks = workFlowStyle ? [...resp[0].tasks, ...resp[1].tasks] : resp ?? []
        tasks = tasks?.filter((task) => !task.toDelete)
        tasks = tasks.filter(x => filterTasks(x))

        setTasks(tasks)
        return tasks
    }

    const pullAll = () => {
        setIsLoading(true)
        var curProjectId = curProject?._id
        Promise.all([
            pullTasks(curProjectId),
            UserService.initialize()
        ])
            .then((data) => {
                // TODO: Pull workstreams from global scope instead of promise
                setIsLoading(false)
            })
    }

    useEffect(() => {
        pullAll()
    }, []);

    /**
     * To/Do List
     */
    const hiPriLength = tasks?.filter(x => x.priority == "High").length ?? 0;
    const incompleteHiPriLength = tasks?.filter(x => x.priority == "High").filter(x => x.status != "Complete").length ?? 0;
    const listLength = tasks?.length ?? 0;

    const completeTaskLength = tasks?.filter(x => x.status == "Complete").length ?? 0;

    /**
     * Add New Modal Status
     */
    const [modalStatus, setModalStatus] = useState(false);

    /**
     * New To/Do Day
     */
    const [date, setDate] = useState(new Date());

    const validate = (values) => {
        const errors = {};
        if (!values.todoTitle) {
            errors.todoTitle = 'Required';
        } else if (values.todoTitle.length > 40) {
            errors.todoTitle = 'Must be 40 characters or less';
        }

        return errors;
    };
    const formik = useFormik({
        initialValues: {
            todoTitle: '',
            todoBadges: '',
        },
        validate,
        onSubmit: (values, { resetForm }) => {
            setModalStatus(false);
            resetForm({ values: '' });
        },
    });

    const addNewTask = (newTaskIn = {}) => {

        TaskService.putTask(newTaskIn)
        setTasks([...tasks, newTaskIn])
    }

    // const filteredTasks = tasks?.filter(t => viewFilter(t))

    const taskCompletionBanner = showBanner ?
        showComplete ?
            `(${listLength} tasks) ${hiPriLength > 0 ? `${hiPriLength} High Priority` : ""}` :
            `(${listLength - completeTaskLength} tasks incomplete) ${incompleteHiPriLength > 0 ? `${incompleteHiPriLength} High Priority` : ""}`
        : ""

    return (
        isLoading ? <></> :

            <Card style={style} stretch>
                <CardHeader>
                    <CardLabel icon={icon} iconColor='danger'>
                        <CardTitle tag='h4' className='h5'>
                            {
                                title
                            }
                            <span className='todo-subtitle text-muted small'>{taskCompletionBanner}</span>
                        </CardTitle>
                        <CardSubTitle>
                            {
                                showComplete &&
                                <Progress
                                    height={8}
                                    max={listLength}
                                    value={completeTaskLength}
                                    color={completeTaskLength === listLength ? 'success' : 'primary'}
                                />
                            }
                        </CardSubTitle>
                    </CardLabel>
                    <CardActions>
                        <SubHeaderRight>
                            <Button
                                color='info'
                                icon='ArrowRight'
                                isLight
                                onClick={() => navigateToTasks()}>
                                {
                                    curProject ? "Deal Tasks" : "My Tasks"
                                }
                            </Button>
                            <Dropdown variant="success" id="dropdown-basic">
                                <DropdownToggle hasIcon={false}>
                                    <Button icon='Eye' color='warning' />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                        <Button onClick={() => setViewNumber(1)}>View Incomplete</Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button onClick={() => setViewNumber(2)}>View Complete</Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button onClick={() => setViewNumber(3)}>View All</Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </SubHeaderRight>
                    </CardActions>
                </CardHeader>
                <CardBody isScrollable>
                    {
                        tasks?.length > 0 ?
                            <MyTasksTodo
                                showProject={curProject ? false : true}
                                tasks={tasks}
                                viewFilter={viewFilter}
                                updateTask={updateTask} /> :
                            <div className="justify-content-center text-center">
                                No tasks yet
                            </div>
                    }
                </CardBody>
                <CardFooter>
                    {
                        getNewTask &&
                        <div className="row" style={{ width: "100%" }}>
                            <div className='col-md-12'>
                                <Input placeHolder="Add New Task"
                                    value={newTaskInput}
                                    onChange={e => setNewTaskInput(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            const newTask = getNewTask({ title: newTaskInput })
                                            addNewTask(newTask)
                                            setNewTaskInput("")
                                        }
                                    }} />
                            </div>
                        </div>
                    }
                </CardFooter>
            </Card>
    );
};

export default MyTasksWidget;
