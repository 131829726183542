import React from 'react'
import Select, { createFilter } from 'react-select';


const WorkstreamColumn = ({ task, updateTask, dropDownOptions, workstreamIndex, taskIndex }) => {

    const wsOptions = dropDownOptions?.projectOptions?.find(x => x?._id == task?.project)?._displayWorkStreams ?? []

    const getProject = (projectId) => {
        const ret = wsOptions.find(x => x?._id == projectId)
        return { label: ret?.title, value: ret?._id }
    }

    return (
        <div>

            <Select className="tagSelect"
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={[
                    ...(wsOptions?.map(o => ({ label: o?.title, value: o?._id })) ?? [])
                ]}
                minMenuHeight={10}
                onChange={(newValue, actionMetaData) => updateTask({ ...task, workstream: newValue?.value }, workstreamIndex, taskIndex)}
                form="false"
                value={getProject(task?.workstream)}
                isClearable={true}
            />
        </div>
    )
}

const WorkstreamHeader = () => {
    return (
        <th scope="col" style={{ minWidth: "175px" }}>WorkStream</th>
    )
}


export { WorkstreamColumn, WorkstreamHeader }