import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Checks from '../../../components/bootstrap/forms/Checks';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../hooks/useDarkMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { NavLink } from 'react-router-dom';
import DelphiLogger from '../../../services/DelphiLogger';
import { Link } from 'react-router-dom';

/**
 * Prop Types
 * @type {{list: Requireable<(InferPropsInner<Pick<{date: Requireable<object>, badge: Requireable<InferProps<{color: Requireable<string>, text: Requireable<string>}>>, id: Requireable<NonNullable<InferType<Requireable<string>|Requireable<number>>>>, title: Requireable<NonNullable<InferType<Requireable<string>|Requireable<number>>>>, status: Requireable<boolean>}, never>> & Partial<InferPropsInner<Pick<{date: Requireable<object>, badge: Requireable<InferProps<{color: Requireable<string>, text: Requireable<string>}>>, id: Requireable<NonNullable<InferType<Requireable<string>|Requireable<number>>>>, title: Requireable<NonNullable<InferType<Requireable<string>|Requireable<number>>>>, status: Requireable<boolean>}, "date" | "badge" | "id" | "title" | "status">>>)[]>}}
 */

export const MyTasksTodoItem = forwardRef(({ task, index, updateTask, showProject, ...props }, ref) => {
    const dueDate = Date.parse(task.duedate)
    const nowDate = Date.now();

    const { themeStatus } = useDarkMode();
    DelphiLogger.debug(task, "Task")
    DelphiLogger.debug(showProject, "ShowP")

    var dropDownOptions = {
        stateOptions: [
            "New",
            "Active",
            "Complete",
        ],
        priorityOptions:
            [
                "",
                "Low",
                "Medium",
                "High"
            ]
    }

    let statusColor = "light"

    var flipStatus = task.status == "Complete" ? "New" : "Complete"

    switch (task.status) {
        case 'Complete':  // if (x === 'value1')
            statusColor = "success"
            break;
        case 'Active':  // if (x === 'value2')
            statusColor = "warning"
            break;
        case 'Cancelled':  // if (x === 'value1')
        case 'Blocked':  // if (x === 'value2')
            statusColor = "danger"
            break;
        case 'New':  // if (x === 'value2')
            statusColor = "info"
            break;
        case 'Queued':  // if (x === 'value2')
            statusColor = "light"
            break;
        default:
            statusColor = null
            break;
    }


    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div ref={ref} className={classNames('todo-item')} {...props}>
            <div className='todo-bar'>
                <div
                    className={classNames('h-100 w-100', 'rounded', `bg-${statusColor}`)}
                />
            </div>
            <div className='todo-check'>
                <Checks checked={task.status == "Complete"} onClick={() => updateTask({ ...task, status: flipStatus }, index)} />
            </div>
            <div className='todo-content'>
                <span contentEditable onBlur={(e) => updateTask({ ...task, title: e.currentTarget.textContent }, index)}
                    className={classNames('todo-title', {
                        'text-decoration-line-through': task.status == "Complete",
                    })}>
                    {
                        task.priority == "High" &&
                        (<FontAwesomeIcon className='text-danger' icon={faDotCircle}></FontAwesomeIcon>)
                    }
                    {task.title}
                </span>
                <div className='todo-subtitle text-muted small'>
                    {
                        dueDate ?
                            <span className={classNames({ "text-danger": nowDate > dueDate, "me-2": true, fontSize: "12px" })}>
                                Due: {moment(dueDate).fromNow()}
                            </span> : <></>
                    }
                    {
                        showProject && task?._displayProject ?
                            <Link to={`/dealtasks/${task?._displayProject._id}/${task?._id}`}>
                                <span className='text-muted small' style={{ fontSize: "12px" }}>
                                    Project: {task?._displayProject?.title}
                                </span>
                            </Link> : <></>
                    }
                </div>
            </div>
            <div className='todo-extras'>
                <Dropdown>
                    <DropdownToggle hasIcon={false}>
                        <span className='me-2'>
                            <Badge isLight color={statusColor}>
                                {task.status}
                            </Badge>
                        </span>
                    </DropdownToggle>
                    <DropdownMenu isAlignmentEnd>
                        {dropDownOptions.stateOptions.map((p, ind) => (
                            <DropdownItem key={`${index}-${ind}`} onClick={() => updateTask({ ...task, status: p }, index)}>
                                {p}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <span>
                    <Dropdown>
                        <DropdownToggle hasIcon={false}>
                            <Button color={themeStatus} icon='MoreHoriz' />
                        </DropdownToggle>
                        <DropdownMenu isAlignmentEnd>
                            <DropdownItem>
                                <Button onClick={() => updateTask({ ...task, toDelete: true }, index)} icon='Delete'>
                                    Delete
                                </Button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </span>
            </div>
        </div >
    );
});

const MyTasksTodo = ({ tasks, viewFilter, updateTask, showComplete, showProject }) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={classNames('MyTasksTodo')}>
            {tasks?.map((i, index) => (
                viewFilter(i) ?
                    <MyTasksTodoItem
                        key={index}
                        index={index}
                        task={i}
                        updateTask={updateTask}
                        showProject={showProject} /> :
                    <></>
            ))}
        </div>
    );
};

export default MyTasksTodo;
