import React from 'react'
import { CardFooter } from '../../../../components/bootstrap/Card'
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle, CardActions
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';

export const OtherAttachment = ({ attachment, setAttachment, deleteAttachment, enabled }) => {
    return (
        <Card>
            <CardHeader>
                <CardLabel>
                    <CardTitle>
                        {attachment?.title}
                    </CardTitle>
                </CardLabel>
                {enabled && <CardActions>
                    <Button icon="XLg"
                        onClick={() => deleteAttachment()}
                    />
                </CardActions>}
            </CardHeader>
            <CardFooter />
        </Card>
    )
}
