import Img1 from './stock/proj.webp';
import Img2 from './stock/todo.jpeg';
import Img3 from './stock/deal.jpeg';
import Img4 from './stock/files.jpeg';
import COLORS from '../../../../common/data/enumColors';
import { NewProjectModal } from '../../../projecttasks/modals/NewProjectModal/NewProjectModal';
import { NewTrackerModal } from '../../../../components/NewTrackerModal/NewTrackerModal';

const TAGS = {
	NPM: {
		text: 'NPM',
		color: COLORS.SUCCESS.name,
	},
	YARN: {
		text: 'Yarn',
		color: COLORS.DANGER.name,
	},
	BOOTSTRAP: {
		text: 'Bootstrap',
		color: COLORS.PRIMARY.name,
	},
	DEPENDENCIES: {
		text: 'Dependencies',
		color: COLORS.INFO.name,
	},
};

export const CATEGORIES = {
	DOCUMENTATION: {
		value: 'documentation',
		text: 'Documentation',
	},
	SETTINGS: {
		value: 'settings',
		text: 'Settings',
	},
	COLORS: {
		value: 'colors',
		text: 'Colors',
	},
};

const data = [
	{
		id: 1,
		title: 'Deal Management',
		icon: "Briefcase",
		description: 'Easy to use tools to track, stay organized, and collaborate with team members on your deal stages.',
		image: Img1,
		color: COLORS.WARNING.name,
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		start: {
			text: "Start a New Deal",
			action: (isOpen, setIsOpen) => <NewProjectModal isOpen={isOpen} setIsOpen={setIsOpen} />
		},
		content:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer facilisis faucibus interdum. Donec dapibus fringilla elementum. Pellentesque et quam quis mauris suscipit laoreet. Integer a eleifend magna. Quisque iaculis massa sit amet molestie eleifend. Nunc id finibus massa, vel eleifend turpis. Maecenas interdum neque non neque porta venenatis. Duis nec viverra nisi. Aenean enim nulla, egestas at congue et, vehicula eget sem. Donec molestie bibendum fermentum. Sed tempor, augue sit amet scelerisque vehicula, lacus nunc eleifend tellus, at lobortis felis erat eu dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas libero mi, lacinia a purus sagittis, aliquam fringilla magna. Mauris commodo mollis iaculis. Cras metus mauris, tincidunt ac dapibus in, facilisis vel ipsum.',
	},
	{
		id: 2,
		title: 'Deal Tasks',
		icon: "ListTask",
		description: 'Keep track of your to-dos and all steps in a transaction process. Assign tasks to team members, communicate on each task for additional clarity with files sending optionality, and more with the intention to increase visibility and transparency across your deals. Goal: eliminate catch up calls asking \'What\'s the status of this deliverable?\' and not missing important deal process items',
		image: Img2,
		color: COLORS.PRIMARY.name,
		categories: [CATEGORIES.DOCUMENTATION],
		start: {
			text: "Start a New Deal",
			action: (isOpen, setIsOpen) => <NewProjectModal isOpen={isOpen} setIsOpen={setIsOpen} />
		},
		content:
			'Aliquam sodales tempor ullamcorper. Quisque non nibh consequat, dapibus magna et, commodo erat. Proin fringilla nibh mollis, vestibulum dui a, laoreet purus. Aliquam vehicula libero est, ut ornare quam eleifend at. Cras accumsan interdum nulla ut accumsan. Duis ornare, est vel rutrum bibendum, magna odio vehicula sem, non hendrerit dui eros ac erat. Sed et justo ac elit pellentesque ornare sit amet quis magna. Curabitur sagittis, leo pulvinar imperdiet consectetur, libero nisi rhoncus magna, non facilisis tortor mi et felis. Ut aliquet diam at eros faucibus, quis gravida nisl volutpat. Quisque eu nibh orci. Praesent posuere orci ligula, a lacinia mauris venenatis non.',
	},
	{
		id: 3,
		title: 'Email Outreach',
		icon: "EnvelopeOpenFill",
		description:
			'Blast emails out to 100s of parties with NDAs and other deal related materials (Teaser, CIP, etc.) automatically with the ability to track and organize activity.',
		image: Img3,
		color: COLORS.DANGER.name,
		start: {
			text: "Create a New Tracker",
			action: (isOpen, setIsOpen) => <NewTrackerModal isOpen={isOpen} setIsOpen={setIsOpen} />
		},
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		content:
			'Pellentesque vehicula dolor a nisi tincidunt, vitae ornare enim tempus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc viverra neque vel diam hendrerit volutpat. Pellentesque placerat justo a sollicitudin molestie. Etiam bibendum lacus nec tortor viverra, a consectetur neque elementum. Donec at sodales purus. Fusce in urna ac elit pulvinar efficitur in non eros. Praesent eleifend, dolor nec sollicitudin eleifend, est massa egestas metus, vitae aliquet magna erat sed turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris rutrum a augue et suscipit. In id augue ullamcorper libero tincidunt luctus.',
	},
	{
		id: 4,
		title: 'Data Room',
		icon: "FileEarmarkLock",
		description:
			'Streamline data flow between management teams, investors, investment bankers, and due diligence teams. AI enabled request organizer that will automatically sort and group similar requests. Drag and drop files to close out specific requests and always have an updated tracker for everyone working on a deal.',
		image: Img4,
		color: COLORS.SUCCESS.name,
		start: {
			text: "Secure My Files",
			action: (isOpen, setIsOpen) => <NewProjectModal isOpen={isOpen} setIsOpen={setIsOpen} />
		},
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		content:
			'Nam vitae blandit elit. Pellentesque efficitur venenatis finibus. Integer at ante rutrum, bibendum ipsum id, viverra mauris. Maecenas rhoncus ligula at lectus ullamcorper, sit amet suscipit massa tristique. Ut mattis feugiat ex, at finibus est ullamcorper in. Maecenas volutpat, odio id aliquam pulvinar, lectus velit malesuada sem, quis rutrum magna lectus quis lacus. Morbi egestas mollis nisl, quis ultrices enim iaculis vitae. Sed maximus blandit mollis. Vestibulum suscipit nibh sit amet vehicula rhoncus. Etiam gravida eu leo ac placerat. Integer vitae nunc ipsum. Phasellus maximus ullamcorper eros, nec blandit ex dignissim non. Donec vulputate molestie risus, vel hendrerit est aliquam vel.',
	}
];

export default data;
