const config = {
    auth0apiAudience: "https://finspace.app/api",
    auth0userApiAudience: "https://finspace.app/userapi",

    apiEndpoint: "http://localhost:3001",
    userApiEndpoint: "http://localhost:3002",
    domain: "login.ai4us.net",
    clientId: "ev3vekslDMS7C3KNLtFdy64pPvXzRStk"
}

export default config;