import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Select from '../../../../../components/bootstrap/forms/Select';
import Textarea from '../../../../../components/bootstrap/forms/Textarea';

export const MainProjectInformation = ({ formik, templateOptions, projectType, updateProjectType }) => {

    const projectTypes = [
        {
            title: "Sell-Side",
            value: "sellSide"
        },
        {
            title: "Buy-Side",
            value: "buySide"
        }
    ]

    return (
        <Card isCompact className='mb-0'>
            <CardHeader>
                <CardLabel icon={'Assignment'} iconColor="success">
                    <CardTitle>Deal Information</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody>
                <div className='row g-4'>
                    <div className='col-12'>
                        <FormGroup id='title' label='Company or Deal Name' isFloating>
                            <Input
                                placeholder='Company or Deal Name'
                                onChange={formik.handleChange}
                            />
                        </FormGroup>
                    </div>
                    <div className='col-12'>
                        <FormGroup id='projectType' label='Select Deal Type' isFloating>
                            <Select
                                placeholder='Deal Type'
                                onChange={e => updateProjectType(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={projectType}
                                isTouched={formik.touched.type}
                            >
                                {
                                    projectTypes.map((type, i) => (
                                        <option value={type.value}>{type.title}</option>
                                    ))
                                }
                            </Select>
                        </FormGroup>
                    </div>
                    <div className='col-12'>
                        <FormGroup id='projectTemplate' label='Select Deal Template' isFloating>
                            <Select
                                placeholder='Select Deal Template'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.projectTemplate}
                                isTouched={formik.touched.projectTemplate}
                            >
                                <option value={"-1"}>No template</option>
                                {
                                    templateOptions.map((o, i) => (
                                        <option value={o.value}>{o.label}</option>
                                    ))
                                }
                            </Select>
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
        </Card >
    )
}
