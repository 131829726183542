import { Dots } from 'loading-animations-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../components/bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import classNames from 'classnames';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../components/bootstrap/Card';
import Progress from '../../components/bootstrap/Progress';
import AvatarTeam from '../../components/common/AvatarTeam';
import Icon from '../../components/icon/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import DelphiLogger from '../../services/DelphiLogger';
import PhaseService from '../../services/PhaseService';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';

export const TrackerItem = ({ cols = 4, project, inProjectStats, updateProject, i, isMinimized = true, ...props }) => {

    const typeMap = {
        "buyer": "Buyer",
        "lender": "Lender",
    }

    const { darkModeStatus } = useDarkMode();
    const [isLoading, setIsLoading] = useState(true);
    const [projectStats, setprojectStats] = useState({})

    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false)
    const navigateToTracker = () => {
        if (!isDeleted) {
            navigate(`/trackerBoard/${project._id}`);
        }
    }

    useEffect(async () => {
        setIsLoading(true)
        await UserService.initialize()
        const projectStats = inProjectStats ?? await ProjectService.getProjectStats([project._id])
        setprojectStats(projectStats)
        setIsLoading(false)
    }, []);

    const deleteProject = () => {

        const isConfirmed = window.confirm("Are you sure want to delete");

        if (isConfirmed) {
            updateProject(i, { ...project, toDelete: true })
        }

    }

    const rowsExited = project?._displayRows.length - project?._displayActiveRows.length
    const completionBanner = `${rowsExited}/${project?._displayRows.length} ${project?.trackerType}(s) exited`
    const completionNumber = project?._displayRows.length ? Math.floor((100 * rowsExited) / project?._displayRows.length) : 0
    DelphiLogger.debug(completionNumber, "Completion number")

    const percent = projectStats?.totalTasks > 0 ? Math.floor(100 * ((projectStats?.projectStatus?.Complete ?? 0) / projectStats?.totalTasks)) : 0

    const numRecentActivities = Math.floor(Math.random() * 50)
    const taskOwnerIds = projectStats?.projectUserCompletion?.filter(x => x._id).map((x, i) => x._id) ?? [];

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={`col-md-${cols}`} {...props}>
            <Card stretch>
                {
                    isLoading ? <></> :
                        <>
                            <CardHeader
                                className={classNames({
                                    "cursor-pointer": true,
                                })}
                                style={isMinimized ? { borderRadius: "2rem 2rem 2rem 2rem" } : {}}
                                onClick={
                                    () => { navigateToTracker() }
                                }
                            >
                                <CardLabel icon='Bullseye' iconColor="info">
                                    <Link to={`/trackerBoard/${project._id}`}>
                                        <CardTitle>{project.title}</CardTitle>
                                    </Link>
                                    <CardSubTitle>Tracker Type: {typeMap[project?.trackerType]}</CardSubTitle>
                                </CardLabel>
                            </CardHeader>
                            {
                                !isMinimized && <CardBody className="card-body align-items-center justify-content-center">
                                    <div className='row g-2 mb-3'>
                                        <div className='col-auto'>
                                            {
                                                projectStats?.fileCount.length > 0 &&
                                                <Link to={`/files/deal/${project._id}`}>
                                                    <Badge color={darkModeStatus ? 'light' : 'dark'} isLight>
                                                        <Icon icon='AttachFile' /> {projectStats?.fileCount[0].count}
                                                    </Badge>
                                                </Link>
                                            }
                                        </div>
                                        <div className='col-auto'>
                                            <Link to={`/dealtasks/${project._id}`}>
                                                <Badge color={darkModeStatus ? 'light' : 'dark'} isLight>
                                                    <Icon icon='TaskAlt' /> {projectStats?.totalTasks}
                                                </Badge>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {completionBanner}
                                            <Progress isAutoColor value={completionNumber ?? 0} height={10} />
                                        </div>
                                    </div>
                                </CardBody>
                            }
                        </>
                }
            </Card>
        </div>
    )
}

export default TrackerItem;