import {
    faCaretSquareDown,
    faCaretSquareRight,
    faCheckCircle,
    faCircleCheck,
    faHandRock,
    faSave,
    faTrashCan
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { AddTaskRow } from './AddTaskRow'
import { TaskItem } from './TaskItem'
import './WorkstreamSection.css'
import { ActionHeader } from './columns/ActionColumn'
import { AssigneesHeader } from './columns/AssigneesColumn'
import { DueDateHeader } from './columns/DueDateColumn'
import { PriorityHeader } from './columns/PriorityColumn'
import { ProjectHeader } from './columns/ProjectColumn'
import { StatusHeader } from './columns/StatusColumn'
import { TitleHeader } from './columns/TitleColumn'
import { WorkstreamHeader } from './columns/WorkstreamColumn'

const WorkstreamSection = ({ provided, filterSort, setFilterSort, selectMode, index, workstream, updateAllWSTasks, startWorkstreamTemplateSave,
    dropDownOptions, updateWorkstream, deleteWorkstream, addNewTask, updateTask, updateTaskTags, setIsTaskPanelOpen, setFocusTask,
    hidden }) => {

    const indexProperty = updateWorkstream ? "index" : "myIndex";
    const wiCompleted = workstream.tasks?.every((i) => i.status == "Complete") && workstream.tasks?.length > 0;
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [sortProperty, setSortProperty] = useState(null)

    const collpasedClass = isCollapsed ? "ws-collapsed" : "";
    const sectionStyle = {}

    if (workstream.toDelete || hidden) {
        sectionStyle.display = "none"
    }

    const isNullOrWhiteSpace = (str) => {
        return !str || str.match(/^ *$/) !== null;
    }

    const handleDrop = (droppedItem) => {

        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...workstream.tasks];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        updatedList = updatedList.map((i, index) => {
            var ret = { ...i }
            ret[indexProperty] = index
            return ret
        })

        // Update State
        updateAllWSTasks(index, updatedList);
    }

    return (
        <div style={sectionStyle} className={"task-wrapper " + collpasedClass} >
            <div className="workstream-bar-section">
                <div className={"workstream-bar " + (wiCompleted ? "workstream-bar-complete" : "")} >
                    <h2 >
                        <FontAwesomeIcon title={isCollapsed ? 'Expand' : 'Collapse'}
                            icon={isCollapsed ? faCaretSquareRight : faCaretSquareDown}
                            onClick={() => setIsCollapsed(!isCollapsed)} />
                        <FontAwesomeIcon className="wi-status-closed text-success" icon={faCheckCircle} />
                        <span className={'workstream-title ' + (isNullOrWhiteSpace(workstream.title) ? 'title-empty' : '')} contentEditable autoFocus onBlur={
                            e => updateWorkstream({ ...workstream, title: e.currentTarget.textContent }, index)
                        }>
                            {
                                updateWorkstream &&
                                <span className='workstream-drag-grip' {...provided.dragHandleProps}>
                                    <FontAwesomeIcon
                                        icon={faHandRock}
                                        style={{ float: "left" }}
                                    />
                                </span>
                            }
                            {workstream.title}
                        </span>
                        <span className="text-muted" style={{ fontSize: "large", marginRight: "10px" }}>
                            {
                                (
                                    workstream?.tasks?.length > 0 && `${workstream?.tasks?.length} tasks`
                                )
                            }
                        </span>
                    </h2>
                    <div className='work-stream-actions action-set'>
                        <FontAwesomeIcon title="Complete all tasks" className="workstream-complete-all" icon={faCircleCheck} onClick={() => {
                            updateAllWSTasks(
                                index,
                                workstream?.tasks?.map(task => ({ ...task, status: "Complete" })),
                                true
                            )
                        }}
                        />
                        {
                            startWorkstreamTemplateSave &&
                            <FontAwesomeIcon title="Save workstream as template" className="workstream-save-template" icon={faSave} onClick={() => { startWorkstreamTemplateSave({ ...workstream }) }}
                            />
                        }
                        {
                            deleteWorkstream &&
                            <FontAwesomeIcon title="Delete WorkStreams" className="workstream-delete" icon={faTrashCan} onClick={() => {
                                deleteWorkstream(workstream, index)
                            }}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="task-list-container">
                <table className="table">
                    <thead>
                        <tr>
                            <ActionHeader />
                            <TitleHeader />
                            {
                                !workstream?.isMyTasks &&
                                <AssigneesHeader />

                            }
                            {
                                !workstream?.isMyTasks && dropDownOptions.projectOptions &&
                                <>
                                    <ProjectHeader />
                                    <WorkstreamHeader />
                                </>
                            }
                            {
                                !dropDownOptions.projectOptions &&
                                <>
                                    <StatusHeader />
                                    <PriorityHeader />
                                </>
                            }
                            <DueDateHeader />
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="lr1">

                            {(provided) => (
                                <tbody
                                    className='lr1'
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}>                                    {
                                        workstream?.tasks?.sort((a, b) => (a[indexProperty] > b[indexProperty]) ? 1 : -1)
                                            .map((item, taskIndex) => (


                                                <Draggable key={item?._id} draggableId={item?._id} index={item?.index ?? taskIndex}>
                                                    {(provided, snapshot) => (

                                                        <TaskItem
                                                            filterSort={filterSort}
                                                            provided={provided}
                                                            snapshot={snapshot}
                                                            isMyTasks={workstream?.isMyTasks}
                                                            selectMode={selectMode}
                                                            key={taskIndex}
                                                            task={item}
                                                            workstreamIndex={index}
                                                            taskIndex={taskIndex}
                                                            updateTask={updateTask}
                                                            updateTaskTags={updateTaskTags}
                                                            dropDownOptions={dropDownOptions}
                                                            setIsTaskPanelOpen={setIsTaskPanelOpen}
                                                            setFocusTask={setFocusTask}
                                                        />

                                                    )}
                                                </Draggable>


                                            ))
                                    }

                                    <tr className="add-task-row">
                                        <AddTaskRow addNewTask={addNewTask} workstreamIndex={index} />
                                    </tr>
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>
            </div>
        </div >
    )
}

export default WorkstreamSection;
