import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import { Dots } from 'loading-animations-react';
import OffCanvas, {
    OffCanvasBody,
    OffCanvasHeader,
    OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
// import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Popovers from '../../../../components/bootstrap/Popovers';
import Button from '../../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../../../services/DelphiLogger';

export const NewFileModal = ({ setIsOpen, isOpen, files, updateInfo, deleteFileInfo, addFiles }) => {

    const FileTypeOptions = [
        { value: 'PDF', label: 'PDF' },
        { value: 'PowerPoint', label: 'PowerPoint' },
        { value: 'Other', label: 'Other' }
    ]

    const [isLoading, setIsLoading] = useState(false)

    const _addFiles = async () => {
        setIsLoading(true)
        try {
            await addFiles()
        } catch (error) {
            DelphiLogger.logError("File Upload failed")
        }
        setIsLoading(false)
    }



    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper> :
            <Modal
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                titleId='upcomingEdit'
                isCentered
                isScrollable
                size='lg'>
                <ModalHeader setIsOpen={setIsOpen}>
                    <OffCanvasTitle id='upcomingEdit'>Add Files</OffCanvasTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            Add New File(s) to Deal
                        </div>
                        <div className='col-12'>
                            <Card isCompact className='mb-0'>
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle>Description</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th style={{ width: "20%" }} scope="col">File Name</th>
                                                <th style={{ width: "50%" }} scope="col">Description</th>
                                                <th style={{ width: "20%" }} scope="col">Type</th>
                                                <th style={{ width: "10%" }} scope="col">Size</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                files.map((file, i) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row"><div><FontAwesomeIcon className='deleteIcon' size='' icon={faTrashCan} onClick={() => deleteFileInfo(i)} /></div>  </th>
                                                            <td><span contentEditable onBlur={(e) => updateInfo(i, { ...file, title: e.currentTarget.textContent })}>{file.title}</span></td>
                                                            <td><span contentEditable onBlur={(e) => updateInfo(i, { ...file, description: e.currentTarget.textContent })}>{file.description}</span></td>
                                                            <td><Select options={FileTypeOptions} onChange={(v) => updateInfo(i, { ...file, type: v.value })} value={{ value: file.type, label: file.type }} /></td>
                                                            <td>{file?.file?.size} B</td>
                                                        </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='bg-transparent'>
                    <Button onClick={async () => await _addFiles()}>
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
    )
}
