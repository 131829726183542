import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../services/DelphiLogger';
import ProjectService from '../../services/ProjectService';
import Button from '../bootstrap/Button';
import Modal, {
    ModalBody, ModalFooter, ModalHeader
} from '../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../bootstrap/OffCanvas';
import { BuyerListImport } from './BuyerListImport';


export const BuyerListModal = ({ setIsBuyersListModalOpen, isBuyersListModalOpen, project, refreshData }) => {

    const projectId = project?._id
    const [buyersList, setBuyersList] = useState({ items: [] });
    const [isLoading, setisLoading] = useState(true);

    const updateBuyersList = async (newItems) => {
        const newList = { ...buyersList }
        newList.items = newItems
        await ProjectService.saveLists({ _id: projectId }, [newList], true)
        setBuyersList(newList)
    }

    useEffect(async () => {
        setisLoading(true)
        if (project) {
            const lists = await ProjectService.getLists(project)
            const buyersList = lists.find(x => x.type == "buyer")
            setBuyersList(buyersList)
        }
        setisLoading(false)
    }, [project]);
    DelphiLogger.debug(buyersList, "BuyersListModal")

    return (
        <Modal
            setIsOpen={setIsBuyersListModalOpen}
            isOpen={isBuyersListModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsBuyersListModalOpen}>
                <OffCanvasTitle id='upcomingEdit'>Add Deal</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>

                {
                    isLoading ?
                        <PageWrapper className="justify-content-center text-center" title="Tasks">
                            <div className=" justify-content-center text-center" title="Tasks">
                                <Dots></Dots>
                            </div>
                        </PageWrapper>
                        :
                        <BuyerListImport buyersList={buyersList?.items ?? []} updateBuyersList={updateBuyersList} />
                }
            </ModalBody>
            <ModalFooter className='bg-transparent'>
                <Button
                    onClick={() => {
                        refreshData()
                        setIsBuyersListModalOpen(false)
                    }}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    )
}
