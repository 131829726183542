import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../../components/PaginationButtons';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import DelphiLogger from '../../../services/DelphiLogger';
import useSortableData from '../../../hooks/useSortableData';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../../components/bootstrap/Card';
import Badge from '../../bootstrap/Badge';
import Popovers from '../../bootstrap/Popovers';
import './TrackerBoard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';

export const TrackerBoard = ({
    curProject,
    tracker,
    trackerRows, updateTrackerRow, updateTrackerRows,
    trackerWorkstreams, updateWorkstream, updateWorkstreams,
    trackerAIs, updateAI, setFocusAI, getOrDefaultActionItem,
    setIsColumnModalOpen, setIsTrackerRowModalOpen, setIsTaskPanelOpen, setIsBuyersListModalOpen, setIsTrackerSaveTemplateModalOpen,
    startEmailBlast
}) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['25']);
    const { items, requestSort, getClassNamesFor } = useSortableData(trackerRows);

    const numTotalRows = trackerRows?.length
    const numActiveRows = trackerRows?.filter(x => x?.status == "Active").length
    const screenheight = window.screen.height
    const maxTableHeight = .6 * screenheight

    DelphiLogger.debug(trackerRows, "TrackerRows")

    // END :: Upcoming Events
    const stateOptions = [
        "New",
        "Active",
        "Complete",
    ]
    // END :: Upcoming Events
    const buyerStateOptions = [
        "Potential",
        "Active",
        "Exited",
    ]

    const getCompletedForWorkStream = (wsid) => {

        const ret = trackerRows.filter(x => {
            const aiId = `${x?._id}${wsid}`
            const ai = trackerAIs[aiId]
            return (ai?.status == "Complete")
        }).length

        return ret
    }

    const getStateIcon = (status) => {

        var statusIcon = ''

        switch (status) {
            case 'Complete':  // if (x === 'value1')
                statusIcon = "CheckLg"
                break;
            case 'Active':  // if (x === 'value2')
                statusIcon = "ArrowRepeat"
                break;
            default:
                statusIcon = null
                break;
        }

        return statusIcon
    }

    const getBuyerStateColor = (status) => {

        var statusClass = ''

        switch (status) {
            case 'Active':  // if (x === 'value2')
                statusClass = "success"
                break;
            case 'Cancelled':  // if (x === 'value1')
            case 'Blocked':  // if (x === 'value2')
            case 'Exited':  // if (x === 'value2')
                statusClass = "danger"
                break;
            case 'Potential':  // if (x === 'value2')
            default:
                statusClass = "light"
                break;
        }

        return statusClass
    }

    const getStateColor = (status) => {

        var statusClass = ''

        switch (status) {
            case 'Complete':  // if (x === 'value1')
                statusClass = "success"
                break;
            case 'Active':  // if (x === 'value2')
                statusClass = "blue"
                break;
            case 'Cancelled':  // if (x === 'value1')
            case 'Blocked':  // if (x === 'value2')
            case 'Exited':  // if (x === 'value2')
                statusClass = "danger"
                break;
            case 'New':  // if (x === 'value2')
            case 'Potential':  // if (x === 'value2')
                statusClass = "grey"
            case 'Queued':  // if (x === 'value2')
                statusClass = "light"
                break;
            default:
                statusClass = null
                break;
        }

        return statusClass
    }

    return (
        <Card style={{ height: "94%" }} >
            <CardHeader borderSize={1}>
                <CardLabel icon='Bullseye' iconColor='info'>
                    <CardTitle>{tracker?.title}</CardTitle>
                    <CardSubTitle>{numActiveRows}/{numTotalRows} Active Buyers</CardSubTitle>
                </CardLabel>
                <CardActions>
                    <Button
                        color='success'
                        icon='Plus'
                        tag='a'
                        onClick={() => { navigate(`/buyerList/${curProject?._id}`) }}
                    >
                        Add Buyer To Tracker
                    </Button>
                    <Button
                        color='warning'
                        icon='Person'
                        tag='a'
                        onClick={() => { setIsBuyersListModalOpen(true) }}
                    >
                        Buyers List
                    </Button>
                    <Button
                        color='primary'
                        icon='Plus'
                        isLight
                        tag='a'
                        onClick={() => { navigate(`/deal/${curProject._id}/newtracker?trackerType=buyer`) }}
                    >
                        New Tracker
                    </Button>
                    <Button
                        color='warning'
                        onClick={() => { setIsTrackerSaveTemplateModalOpen(true) }}
                    >
                        <span>
                            <FontAwesomeIcon title="Save tracker as template" className="button-fa" icon={faSave} />Save As Template</span>
                    </Button>
                    <Button
                        color='info'
                        icon='CloudDownload'
                        isLight
                        tag='a'
                        to='/somefile.txt'
                        target='_blank'
                        download>
                        Export
                    </Button>
                </CardActions>
            </CardHeader>
            <CardBody className='table-responsive' style={{ maxHeight: `${maxTableHeight}px`, paddingTop: "0px", paddingLeft: "0px" }}>
                <table className='table table-modern' id="demoDT">
                    <thead>
                        <tr>
                            <th scope="row"
                                // onClick={() => requestSort('date')}
                                className='stick int cursor-pointer text-decoration-underline tracker-col'
                                onClick={() => requestSort('status')}
                            >
                                Status
                            </th>
                            <th className="stick int tracker-col" scope="row"
                            // onClick={() => requestSort('date')}
                            >
                                Buyer
                            </th>
                            {
                                trackerWorkstreams?.map(x => {
                                    const stats = x?.stats ?? { "completed": 0, "total": 0 }
                                    const numCompleted = getCompletedForWorkStream(x?._id)
                                    const completePerc = numTotalRows > 0 ? (numCompleted * 100) / numTotalRows : 0

                                    return (
                                        !x?.toDelete &&
                                        <>
                                            <th className="stick trackerHeader ch tracker-col">
                                                <div className="row">
                                                    <div className="col-auto" style={{ padding: "0px" }}>
                                                        <div>
                                                            <ProgressBar style={{ width: "60px" }} variant='success' now={completePerc} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div>
                                                            {numCompleted}/{numTotalRows}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <span style={{ padding: "0" }}>{x?.title}
                                                        {x?.type == "EmailBlast" &&
                                                            <Icon
                                                                className='action-cell cursor-pointer brand-aside-toggle-close'
                                                                color='danger'
                                                                icon="Mail"
                                                                onClick={() => startEmailBlast(x)
                                                                }
                                                            />
                                                        }
                                                    </span>
                                                </div>
                                            </th>
                                        </>
                                    )
                                })
                            }
                            <th className="stick">
                                <Button
                                    color="success"
                                    icon="PlusLg"
                                    onClick={() => { setIsColumnModalOpen(true) }}
                                />
                            </th>
                            <td />
                        </tr>
                    </thead>
                    <tbody>
                        {dataPagination(items, currentPage, perPage).map((trackerRow, i) => {
                            const listItem = trackerRow?._displaylistItems?.find(x => x) ?? {}

                            return (
                                < tr key={trackerRow?.id} className={(trackerRow?.status == "Exited" ? 'buyer-exited' : '')} >
                                    <th scope="row">
                                        <Dropdown style={{ textAlign: "center", zIndex: "1030" }}>
                                            <DropdownToggle style={{ textAlign: "center", zIndex: "1030" }} hasIcon={false}>
                                                {
                                                    <Badge color={getBuyerStateColor(trackerRow?.status)}>{trackerRow?.status}</Badge>
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd style={{ zIndex: "1030" }}>
                                                {buyerStateOptions.map((p, ind) => (
                                                    <DropdownItem style={{ textAlign: "center", zIndex: "1030" }}
                                                        onClick={() => updateTrackerRow({ ...trackerRow, status: p })}
                                                        key={`${trackerRow?._id}-state`} >
                                                        {p}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </th>
                                    <th scope="row">
                                        <div>
                                            <div>{listItem["Fund Name"]}</div>
                                            <div className='small text-muted'>
                                                {listItem["Contact Name"]}
                                            </div>
                                        </div>
                                    </th>
                                    {
                                        trackerWorkstreams?.map(trackerWorkstream => {

                                            const trid = trackerRow._id;
                                            const wsid = trackerWorkstream._id;
                                            const aiId = `${trid}${wsid}`
                                            const item = trackerAIs[aiId] ?? getOrDefaultActionItem(trackerRow, trackerWorkstream, trackerAIs)
                                            DelphiLogger.debug(item?.history, "Comment")

                                            return !trackerWorkstream?.toDelete &&
                                                <>
                                                    <td className='tracker-board-cell'>
                                                        <div
                                                            onClick={() => setFocusAI(item)}
                                                            onDoubleClick={() => {
                                                                setFocusAI(item)
                                                                setIsTaskPanelOpen(true)
                                                            }}
                                                        >
                                                            <Dropdown style={{ textAlign: "center" }}>
                                                                <DropdownToggle hasIcon={false}>
                                                                    <div style={{ height: "100%", width: "100%" }}>
                                                                        <div style={{ minHeight: "30px", minWidth: "30px" }}>
                                                                            {
                                                                                item?.status != "New" && <Icon
                                                                                    size='lg'
                                                                                    icon={getStateIcon(item?.status)}
                                                                                    color={getStateColor(item?.status)}
                                                                                />
                                                                            }
                                                                            {
                                                                                item?.history?.filter(x => x?.type == "comment")?.length > 0 && <Icon
                                                                                    size='lg'
                                                                                    icon="ChatDots"
                                                                                />
                                                                            }
                                                                            {
                                                                                item?.history?.filter(x => x?.type == "email")?.length > 0 && <Icon
                                                                                    size='lg'
                                                                                    icon="Envelope"
                                                                                />
                                                                            }
                                                                            {
                                                                                item?.files?.length > 0 && <Icon
                                                                                    size='lg'
                                                                                    icon="FileEarmark"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </DropdownToggle>
                                                                <DropdownMenu isAlignmentEnd>
                                                                    {stateOptions.map((p, ind) => (
                                                                        <DropdownItem
                                                                            onClick={() => updateAI({ ...item, status: p })}
                                                                            key={aiId} >
                                                                            {p}
                                                                        </DropdownItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </>
                                        })
                                    }
                                </tr>)
                        })}
                    </tbody>
                </table >
            </CardBody>
            <PaginationButtons
                data={items}
                label='items'
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
            />
        </Card>
    )
}
