import React from 'react'
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Chip from '@mui/material/Chip';

const StatusColumn = ({ task, updateTask, workstreamIndex, taskIndex, dropDownOptions }) => {

    let dropDownStyle = {
        minWidth: "40px",
        minHeight: "40px",
        height: "100%",
        width: "100%",
    }

    let statusClass = "task-complete bg-light"

    switch (task?.status) {
        case 'Complete':  // if (x === 'value1')
            statusClass = "bg-success"
            break;
        case 'Active':  // if (x === 'value2')
            statusClass = "bg-warning"
            break;
        case 'Cancelled':  // if (x === 'value1')
        case 'Blocked':  // if (x === 'value2')
            statusClass = "bg-danger"
            break;
        case 'New':  // if (x === 'value2')
            statusClass = "grey"
        case 'Queued':  // if (x === 'value2')
            statusClass = "light"
            break;
        default:
            statusClass = null
            break;
    }

    return (
        <div>
            <Dropdown style={{ textAlign: "center" }}>
                <DropdownToggle hasIcon={false}>
                    <div style={dropDownStyle} clickable className='full-cell-div'>
                        {statusClass ? <Chip size="small" label={task.status} clickable className={statusClass} /> : ""}
                    </div>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                    {dropDownOptions.stateOptions.map((p, ind) => (
                        <DropdownItem key={`${workstreamIndex}-${taskIndex}-${ind}`} onClick={() => updateTask({ ...task, status: p }, workstreamIndex, taskIndex)}>
                            {p}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

const StatusHeader = () => {
    return (
        <th scope="col" style={{ width: "5%" }}>Status</th>
    )
}


export { StatusColumn, StatusHeader }