import React from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const PhaseArrow = ({ project, style, phase, phaseFilter, togglePhase, startPhaseEdit, index }) => {

    const { height, width } = useWindowDimensions()
    const totalChars = width <= 930 ? 70 : (70 + Math.min((width - 930) / 8))

    const truncateString = (str) => {
        const n = Math.min(totalChars / (project?.phases?.length > 0 ? project?.phases?.length : 1))
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    var classList = [
        "step",
        ((project.completedPhases ?? []).includes(phase?._id) ? "done" : ""),
        (!(phaseFilter ?? []).includes(phase?._id) ? "unfiltered" : ""),
        (phase?._id == project.currentPhase ? "current" : ""),
    ]

    var classString = classList.join(' ')

    return (
        <div style={style}
            class={classString}
            onClick={() => { togglePhase(phase) }}
            onDoubleClick={() => { startPhaseEdit(index) }}>
            <span>{truncateString(phase?.title)}</span>
        </div>
    )
}
