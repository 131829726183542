import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faComment, faCheckCircle, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import DatePicker from "react-datepicker";
import Rainbow from 'rainbowvis.js'

const DueDateColumn = ({ task, updateTask, workstreamIndex, taskIndex }) => {

  const dueDate = task.duedate && typeof task.duedate === "string" ? Date.parse(task.duedate) : task.duedate;
  const dateNow = Date.now();
  const isLate = dateNow > dueDate && task.status != "Complete"
  const textStyle = {

  }

  // If is late use yellow to red gradient for lateness
  if (isLate) {
    const diffTime = Math.abs(dateNow - dueDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let lateness = diffDays > 30 ? 30 : diffDays;
    var numberOfItems = 30;

    var rainbow = new Rainbow();
    rainbow.setNumberRange(0, numberOfItems);
    rainbow.setSpectrum('#ffaa00', '#dd0000');
    var s = '';
    var color = rainbow.colourAt(lateness);

    textStyle.color = color
  }


  return (
    <div>
      <DatePicker selected={dueDate} style={textStyle} onChange={(date) => { updateTask({ ...task, duedate: date }, workstreamIndex, taskIndex) }} />
    </div>
  )
}

const DueDateHeader = () => {
  return (
    <th scope="col" style={{ width: "10%" }}>Due Date</th>
  )
}


export { DueDateColumn, DueDateHeader }