import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Icon from '../../components/icon/Icon';
import Page from '../../layout/Page/Page';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../components/bootstrap/Card';
import USERS from '../../common/data/userDummyData';
import Badge from '../../components/bootstrap/Badge';
import Button from '../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Label from '../../components/bootstrap/forms/Label';
import Input from '../../components/bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import SERVICES from '../../common/data/serviceDummyData';
import { demoPages } from '../../menu';
import useTourStep from '../../hooks/useTourStep';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import { NewProjectModal } from '../projecttasks/modals/NewProjectModal/NewProjectModal';
import { Dots } from 'loading-animations-react';
import useDarkMode from '../../hooks/useDarkMode';
import AvatarTeam from '../../components/common/AvatarTeam';
import Avatar, { AvatarGroup } from '../../components/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown, faCaretSquareRight } from '@fortawesome/free-regular-svg-icons';
import { NewTrackerModal } from '../../components/NewTrackerModal/NewTrackerModal';
import DelphiLogger from '../../services/DelphiLogger';
import { NewEmailOutreachModal } from '../../components/NewEmailOutreach/NewEmailOutreachModal';
import EmailOutreachItem from './EmailOutreachItem';

const EmailOutreachPage = () => {

    useTourStep(18);
    const { darkModeStatus } = useDarkMode();
    const navigate = useNavigate();

    const [currentOrg, setCurrentOrg] = useState(null);
    const [teams, setTeams] = useState([]);
    const [workStreams, setWorkStreams] = useState([]);
    const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
    const [isNewTrackerModalOpen, setIsNewTrackerModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    const [projectsExpanded, setProjectsExpanded] = useState(true)
    const [trackersExpanded, setTrackersExpanded] = useState(true)
    const [taskBoardsExpanded, setTaskBoardsExpanded] = useState(false)

    const navigateToTracker = (ws) => {
        const link = `/trackerBoard/${ws.tracker}`
        navigate(link);
    }

    const [filterMenu, setFilterMenu] = useState(false);

    const formik = useFormik({
        initialValues: {
            available: false,
            searchInput: '',
            services: [],
        },
        // eslint-disable-next-line no-unused-vars
        onSubmit: (values) => {
            setFilterMenu(false);
            // alert(JSON.stringify(values, null, 2));
        },
    });

    useEffect(async () => {
        setIsLoading(true)
        const allStats = await ProjectService.getProjectStats()
        await (UserService.initialize())
        const curOrg = UserService.getMyCurrentOrg()
        DelphiLogger.debug(allStats, "All stats")
        const emailWorkstreams = allStats["allEmailedWorkStreams"]


        setCurrentOrg(curOrg)
        setWorkStreams(emailWorkstreams)
        setIsLoading(false)
    }, []);

    const searchProjects = workStreams?.filter(
        (p) =>
            p?.title?.includes(formik.values.searchInput.toLowerCase())
    ) ?? []

    DelphiLogger.debug(searchProjects, "Search projects")

    return (

        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Project Dashboard">
                <div className=" justify-content-center text-center" title="Project Dashboard">
                    <Dots></Dots>
                </div>
            </PageWrapper> :
            <PageWrapper title={demoPages.projectManagement.subMenu.list.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <strong className='fs-5'>{currentOrg?.display_name}</strong>
                        <SubheaderSeparator />
                        <span>
                            There are{' '}
                            <Badge color='success' isLight>
                                {workStreams?.length} active email blasts
                            </Badge>
                            .
                        </span>
                    </SubHeaderLeft>
                </SubHeader>
                <Page>
                    <div className='row mt-3'>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <div className='display-4 fw-bold py-3'><span><FontAwesomeIcon
                                    title={trackersExpanded ? 'Collapse' : 'Expand'}
                                    icon={trackersExpanded ? faCaretSquareDown : faCaretSquareRight}
                                    onClick={() => setTrackersExpanded(!trackersExpanded)}
                                />Mass Emails Sent</span></div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <Card stretch>
                                <CardBody className='d-flex align-items-center justify-content-center'>
                                    <Button
                                        color='info'
                                        size='lg'
                                        isLight
                                        className='w-100 h-100'
                                        icon='AddCircle'
                                        onClick={() => setIsNewProjectModalOpen(true)}>
                                        Start a new outreach
                                    </Button>
                                </CardBody>
                            </Card>
                        </div>
                        {
                            searchProjects.filter(p => !p?.toDelete).map((project, i) => {
                                return <EmailOutreachItem cols={3} style={{ display: ((trackersExpanded || i < 2) ? "inline-block" : "none") }} i={i} project={project} />
                            })
                        }
                    </div>
                </Page>

                <NewEmailOutreachModal
                    setIsNewEmailOutreachModalOpen={setIsNewProjectModalOpen}
                    isNewEmailOutreachModalOpen={isNewProjectModalOpen}
                />
            </PageWrapper>
    );
};

export default EmailOutreachPage;
