import React from 'react';
import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import PageError from './pages/presentation/auth/PageError';

console.log(window.location.href)
console.log(process.env.PUBLIC_URL)
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString)
var error = null
var error_description = null

if(urlParams.has('error')){
	error = urlParams.get('error')
	error_description = urlParams.get('error_description')
}

if (error) {
	ReactDOM.render(
		<PageError error={"Verify Your Email"} error_desc={error_description}/>,
		document.getElementById('root')
	)
}
else {

	ReactDOM.render(
		<Auth0Provider
			domain="login.ai4us.net"
			clientId="ev3vekslDMS7C3KNLtFdy64pPvXzRStk"
			redirectUri={`${window.location.origin}`}>
			<Router
				basename={process.env.PUBLIC_URL}
			>
				<React.StrictMode>
					<ThemeContextProvider>
						<App />
					</ThemeContextProvider>
				</React.StrictMode>
			</Router>
		</Auth0Provider>,
		document.getElementById('root'),
	);

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
