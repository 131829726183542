import DelphiConfigProvider from './DelphiConfigProvider';
import createAuth0Client from '@auth0/auth0-spa-js';

class Auth0ClientService {
    static auth0ClientProm = null;

    static resetClient() {
        console.log(`Resetting the auth0 client`)
        Auth0ClientService.auth0ClientProm = createAuth0Client({
            domain: DelphiConfigProvider.config.domain,
            client_id: DelphiConfigProvider.config.clientId,
            redirect_uri: `${window.location.origin}`,
        })
    }

    static {
        console.log(`Initializing auth0 client for Auth0ClientService`)
        Auth0ClientService.resetClient()
    }

    static async getUser(userId = null) {
        var user = null
        var retries = 3

        while (user == null && retries-- > 0) {
            var auth0client = await (Auth0ClientService.auth0ClientProm)
            user = await (auth0client.getUser())
            console.log(`getUser attempt ${retries}: User: ${user}, Prom: ${Auth0ClientService.auth0ClientProm}`)
            if (!user) {
                Auth0ClientService.resetClient()
            }
        }
        return user
    }

    static async getAuthToken(audience, scopes = null) {
        var accessToken = null
        var auth0client = await Auth0ClientService.auth0ClientProm

        try {
            accessToken = await auth0client.getTokenSilently({
                audience: audience,
                redirect_uri: `${window.location.origin}`,
            });
        } catch (error) {
            if (error.error !== 'login_required') {
                accessToken = await auth0client.getTokenWithPopup({
                    audience: audience,
                    redirect_uri: `${window.location.origin}`,
                });
            }
        }

        return accessToken
    }
}

export default Auth0ClientService;