import React, { useState, useEffect, useRef } from 'react'
import { useAuth0, } from "@auth0/auth0-react";
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import Auth0ClientService from '../../../../services/Auth0ClientService';
import { Dots } from 'loading-animations-react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle,
} from '../../../../components/bootstrap/Card';
import UserService from '../../../../services/UserService';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import { useNavigate, useParams } from 'react-router-dom';
import useMinimizeAside from '../../../../hooks/useMinimizeAside';
import WebViewer from '@pdftron/webviewer'
import FileService from '../../../../services/FileService';
import { useDropzone } from 'react-dropzone';
import ReactQuill from 'react-quill';
import DelphiLogger from '../../../../services/DelphiLogger';
import './EmailPreview.css'
import EmailService from '../../../../services/EmailService';
import { EmailAttachment } from './EmailAttachment';
import { OtherAttachment } from './OtherAttachment';

export const EmailPreview = ({ trackerRows, selectedRows, attachments, body, subject, cc, fromEmail, me }) => {

    const email = { body: body, subject: subject }

    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfFile, setPdfFile] = useState(false);
    const [pdfInstance, setPdfInstance] = useState(false);
    const [documentViewer, setDocumentViewer] = useState(null);
    const [rowIndex, setRowIndex] = useState(0)
    const [emailbody, setEmailBody] = useState('');
    const [org, setOrg] = useState(null)
    const viewer = useRef(null);
    const { fileId } = useParams();
    const navigate = useNavigate();
    const { user, logout } = useAuth0();

    DelphiLogger.debug(email, "EmailPreview")

    const selectedItem = selectedRows?.length > 0 ? selectedRows[rowIndex]._displaylistItem : {}

    var convertedEmail = email
    if (selectedRows?.length > 0) {
        convertedEmail = EmailService.fillTemplate(email, selectedItem, user, org)
    }

    useEffect(async () => {
        setIsLoading(true)
        await UserService.initialize()
        const org = await UserService.getMyCurrentOrg()
        setOrg(org)
        setIsLoading(false)
    }, [])


    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            : !(selectedRows?.length > 0) ?
                <PageWrapper className="justify-content-center text-center" title="Tasks">
                    <p class="lead fw-bold">No Recipients Selected</p>
                </PageWrapper> :
                <>
                    <Card>
                        <CardHeader>
                            <CardLabel icon='ExclamationTriangleFill' iconColor="danger">
                                <CardTitle>You are about to send ({selectedRows?.length}) emails. Validate the content here.</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p className='lead fw-bold'>Email</p>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>Customer:</strong> </div>
                                </div>
                                <div className='col-md-12'>
                                    <div style={{ paddingTop: "5px" }}>
                                        <Select
                                            value={rowIndex}
                                            onChange={e => setRowIndex(e.target.value)}>
                                            {
                                                selectedRows?.map((row, i) => {
                                                    return <option value={i}>{row?._displaylistItem["Fund Name"]}</option>
                                                }) ?? []
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className='col-md-12'>
                                    {selectedRows?.length > 0 && <div ><strong>To: </strong>{selectedItem["Contact Name"]} ({selectedItem["Contact Email"]}) </div>}
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>Subject: </strong>{convertedEmail?.subject}</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>From: </strong>{me?.name} ({fromEmail}@finspace.app)</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>ReplyTo: </strong>{me?.name} ({me?.email})</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>CC: </strong>{cc}</div>
                                </div>
                                <br />
                                <div className='col-md-12'>
                                    <div ><strong>Body:</strong> </div>
                                </div>
                                <br />
                                <div className='col-md-12'>
                                    <div >
                                        <ReactQuill
                                            value={convertedEmail?.body}
                                            onChange={() => { }}
                                            readOnly={true}
                                            modules={{ toolbar: false }}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </CardBody>
                    </Card>
                    {
                        attachments.map((attachment, i) => {
                            switch (attachment?.type) {
                                case "PDF":
                                    return <EmailAttachment
                                        row={selectedRows?.length > 0 ? selectedRows[rowIndex] : {}}
                                        enabled={false}
                                        attachment={attachment}
                                        setAttachment={(a) => {
                                        }}
                                        deleteAttachment={() => { }}
                                    />
                                    break;

                                default:
                                    return <OtherAttachment
                                        enabled={false}
                                        attachment={attachment}
                                        setAttachment={(a) => {
                                        }}
                                        deleteAttachment={() => { }}
                                    />
                                    break;
                            }
                        })
                    }
                </>
    )
}
