import React, { useState, useEffect } from 'react'
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0, } from "@auth0/auth0-react";
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Auth0ClientService from '../../../services/Auth0ClientService';
import { Avatar } from '@mui/material';
import { Dots } from 'loading-animations-react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

const AuthHeader = () => {

    const { user, logout } = useAuth0();
    const navigate = useNavigate();
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const styledBtn = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };

    useEffect(async () => {

        var currentUser = await Auth0ClientService.getUser()
        console.debug(currentUser)
        setCurrentUser(currentUser)
        setIsLoading(false)
    }, []);

    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <></>
                </div>
            </PageWrapper>
            :
            <Header>
                <HeaderLeft>
                    <div class="row g-3">
                        <div className='row'>
                            <div className='col-auto position-relative'>
                                <Avatar srcSet={currentUser?.picture} size={32} />
                            </div>
                            <div style={{ marginTop: "13px" }} className='col-auto position-relative'>
                                <span>
                                    <strong>{(currentUser?.given_name ?? '') + ' ' + (currentUser?.family_name ?? '')}</strong>
                                </span>
                            </div>
                            <div style={{ marginTop: "13px" }} className='col-auto position-relative'>
                                <span>
                                    <strong>Welcome To FinSpace!</strong>
                                    <span className='text-muted'> Let's Get Started</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </HeaderLeft>
                <HeaderRight >
                    <div className='row g-3'>
                        <div className='col-auto position-relative'>
                            <Button
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...styledBtn}
                                icon='Home'
                                aria-label='Home'
                                onClick={() => navigate(`/`)}
                            />
                        </div>
                        <div className='col-auto position-relative'>
                            <Dropdown>
                                <DropdownToggle hasIcon={false}>
                                    <Button
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...styledBtn}
                                        icon='Person'
                                        aria-label='User Options'
                                    />
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>

                                    <DropdownItem key="btn-logout">
                                        <Button
                                            onClick={() => logout()}>
                                            Logout
                                        </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </HeaderRight>
            </Header >
    );
};

export default AuthHeader;
