import Auth0ClientService from "./Auth0ClientService";
import DelphiConfigProvider from './DelphiConfigProvider';
import { useAuth0, } from "@auth0/auth0-react";
import DelphiLogger from '../services/DelphiLogger';
import { isNull } from "util";
import download from 'downloadjs'

class EmailService {

    static replaceTokens = (text, item, user, org) => {
        if (text && item) {
            text = text?.replaceAll("{{me.first_name}}", user?.first_name ?? user?.name?.split(' ').find(x => x) ?? "")
            text = text?.replaceAll("{{me.company}}", org?.display_name ?? "")


            text = text?.replaceAll("{{buyer.contact}}", item["Contact Name"] ?? item["given_name"] ?? "")
            text = text?.replaceAll("{{buyer.first_name}}", item["given_name"] ?? item["Contact Name"] ?? "")
            text = text?.replaceAll("{{buyer.last_name}}", item["family_name"] ?? "")

            const company = item["Firm Name"] ?? item["Company"] ?? (item["user_metadata"] && item["user_metadata"]["company_name"] ? item["user_metadata"]["company_name"] : null) ?? ""
            text = text?.replaceAll("{{buyer.company}}", company)
        }
        return text ?? ""
    }

    static fillTemplate = (emailTemplate, item, user, org) => {

        const email = {
            ...emailTemplate,
            body: EmailService.replaceTokens(emailTemplate?.body, item, user, org),
            subject: EmailService.replaceTokens(emailTemplate?.subject, item, user, org)
        }

        return email

    }

    static async saveEmails(emails) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/emails`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(emails)
        })

        const ret = await resp.json()
        return ret
    }
}

export default EmailService;