import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { Dots } from 'loading-animations-react';
import WebViewer from '@pdftron/webviewer'
import FileService from '../../services/FileService';


const PDFViewer = () => {

    const [isLoading, setisLoading] = useState(true);
    const viewer = useRef(null);
    const { fileId } = useParams();
    const navigate = useNavigate();

    useEffect(async () => {
        setisLoading(true)
        const file = await FileService.getTempFile(fileId)
        setisLoading(false)

        WebViewer(
            {
                path: '/webapp/lib'
            },
            viewer.current,
        ).then((instance) => {
            console.log(`Got to this point ${file.name}`)
            instance.UI.loadDocument(file.blob, { filename: file?.name ?? "basic.pdf" });
            const { documentViewer } = instance.Core;
            // you can now call WebViewer APIs here...
        });
    }, []);

    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper> :

            <PageWrapper className="justify-content-center text-center">
                <div className=" justify-content-center text-center">
                    <div className="MyComponent">
                        <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div>
                    </div>
                </div>
            </PageWrapper >
    )
}

export default PDFViewer;