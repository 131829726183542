import React from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../bootstrap/Modal'
import { OffCanvasTitle } from '../../bootstrap/OffCanvas'
import { EmailBlast } from './EmailBlast'

export const EmailBlastModal = ({ isEmailBlastModalOpen, setIsEmailBlastModalOpen, ...props }) => {
    return (
        <Modal
            setIsOpen={setIsEmailBlastModalOpen}
            isOpen={isEmailBlastModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='xl'>
            <ModalHeader setIsOpen={setIsEmailBlastModalOpen}>
                <OffCanvasTitle id='upcomingEdit'>Send Email Blast ({props.focusWorkStream?.title})</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                <EmailBlast
                    {...props}
                    setIsEmailBlastModalOpen={setIsEmailBlastModalOpen}
                />
            </ModalBody>
        </Modal>
    )
}
