import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import useDarkMode from '../../../hooks/useDarkMode';
import Auth0ClientService from '../../../services/Auth0ClientService';
import { useTour } from '@reactour/tour';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';

const DashboardBookingHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const [user, setUser] = useState(null)
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { setIsOpen } = useTour();

	useEffect(async () => {
		const user = await Auth0ClientService.getUser()
		setUser(user)
	}, [])

	const name = user?.given_name ?? user?.name ?? null

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							{name && <div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								Hi, {name}!
							</div>}
						</div>
					</div>
				</div>
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderChat />
			</HeaderRight>
		</Header>
	);
};

export default DashboardBookingHeader;
