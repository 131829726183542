import { useNavigate } from 'react-router-dom';
import Wizard, { WizardItem } from '../../components/Wizard';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { BuyerNDASend } from '../tracker/NewTracker/WizardSteps/BuyerNDASend';
import { BuyerTrackingRowSelect } from '../tracker/NewTracker/WizardSteps/BuyerTrackingRowSelect';
import { EmailPreview } from '../tracker/NewTracker/WizardSteps/EmailPreview';

export const EmailBlastPage = () => {
    const navigate = useNavigate();
    return (
        <PageWrapper title="Send Email Blast">
            <Wizard
                isHeader
                stretch
                color='info'
                noValidate
                onSubmit={() => {
                    const link = `/tracker/1`
                    navigate(link);
                }}
                className='shadow-3d-info'>
                <WizardItem id='step1' title='Select Recipients'>
                    <BuyerTrackingRowSelect />
                </WizardItem>
                <WizardItem id='step2' title='Add Email Content'>
                    <BuyerNDASend />
                </WizardItem>
                <WizardItem id='step3' title='Preview Email'>
                    <EmailPreview />
                </WizardItem>
            </Wizard>
        </PageWrapper>
    )
}
