import { Dots } from 'loading-animations-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../bootstrap/Button';
// import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
// import { templateOptions } from './templateOptions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Import bootstrap(v3 or v4) dependencies
// import { EmailAttachment } from './EmailAttachment';
// import DelphiLogger from '../../../../services/DelphiLogger';
// import { FileDropPad } from '../../../projectdashboard/Widgets/FilesWidget/FileDropPad';
// import { OtherAttachment } from './OtherAttachment';
// import FileService from '../../../../services/FileService';
import InputGroup, { InputGroupText } from '../bootstrap/forms/InputGroup';
import Label from '../bootstrap/forms/Label';
// import Icon from '../../../../components/icon/Icon';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { FileDropPad } from '../../pages/projectdashboard/Widgets/FilesWidget/FileDropPad';
import { EmailAttachment } from '../../pages/tracker/NewTracker/WizardSteps/EmailAttachment';
import { OtherAttachment } from '../../pages/tracker/NewTracker/WizardSteps/OtherAttachment';
import { templateOptions } from '../../pages/tracker/NewTracker/WizardSteps/templateOptions';
import DelphiLogger from '../../services/DelphiLogger';
import FileService from '../../services/FileService';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle
} from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Select from '../bootstrap/forms/Select';
import Popovers from '../bootstrap/Popovers';
import Icon from '../icon/Icon';
// import Select from '../bootstrap/forms/Select';

export const NewEmailCompose = ({ email, setEmail, me }) => {

    const [reactQuillRef, setReactQuillRef] = useState(null)
    const [quillRef, setQuillRef] = useState(null)

    const buyerFields = [
        {
            label: "First Name",
            value: "{{buyer.first_name}}"
        },
        {
            label: "Last Name",
            value: "{{buyer.last_name}}"
        },
        {
            label: "Company",
            value: "{{buyer.company}}"
        }
    ]


    const attachQuillRefs = () => {
        // Ensure React-Quill reference is available:
        if (!(reactQuillRef?.getEditor)) return;
        // Skip if Quill reference is defined:
        if (quillRef != null) return;

        const quillRef = reactQuillRef.getEditor();
        if (quillRef != null) setQuillRef(quillRef);
    }

    useEffect(() => {
        attachQuillRefs()
    }, [reactQuillRef]);

    const quillInsert = (txt) => {
        var range = quillRef.getSelection();
        let position = range ? range.index : 0;
        quillRef.insertText(position, txt)
    }


    // subject,
    // setSubject,
    // body,
    // setBody, setEmail, attachments, setAttachments, cc, setCc, fromEmail, setFromEmail, me }) => {

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video', 'formula'],          // add's image support
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const [isLoading, setIsLoading] = useState(false);
    const { fileId } = useParams();
    const navigate = useNavigate();

    const startFileAdd = async (files) => {

        const fileProms = Object.keys(files)?.map(async (key, index) => {

            const file = files[key]
            var fileExtRe = /(?:\.([^.]+))?$/;
            var matches = fileExtRe.exec(file.name);
            if (matches.length < 1) {
                throw Error('File Name is invalid and cannot determine extension')
            }
            const extension = matches[1]
            var type = "Other"
            switch (extension) {
                case 'pdf':
                    type = "PDF"
                    break;
                case 'ppt':
                case 'pptx':
                    type = "PowerPoint"
                    break;
                default:
                    type = "Other"
                    break;
            }

            const url = await (FileService.toString(file))

            const newF = {
                'title': file.name,
                'description': '',
                'file': file,
                'type': type,
                'url': url
            }

            return newF
        });

        const newFiles = await Promise.all(fileProms)
        const prevAttachments = email?.attachments ?? [];
        setEmail({ ...email, attachments: [...prevAttachments, ...newFiles] })
        return false
    }

    const deleteAttachment = (i) => {

        if (i > -1) { // only splice array when item is found
            email?.attachments.splice(i, 1); // 2nd parameter means remove one item only
        }

        const newAttachments = [...email?.attachments]
        setEmail({ ...email, attachments: newAttachments })
    }

    const updateBody = (e) => {
        setEmail({ ...email, body: e })
    }


    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            :
            <>
                <Card >
                    <CardHeader>
                        <CardLabel>
                            <CardTitle>Email Content</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            <div className='col-md-12'>
                                <Label htmlFor='Template'>Template</Label>
                                <Select
                                    placeholder='Select Email Template'
                                    onChange={(e) => {
                                        {
                                            DelphiLogger.debug(e, "E")
                                            const t = templateOptions?.find(x => x?.title == e.target.value)
                                            setEmail({ ...email, body: t?.body, subject: t?.subject })
                                        }
                                    }}
                                >
                                    {
                                        templateOptions?.map((template, option) => (
                                            <option value={template?.title}>{template?.title}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className='col-md-12'>
                                <Label htmlFor='Subject'>Subject</Label>
                                <Input
                                    contentEditable
                                    placeholder='Subject'
                                    onChange={e => setEmail({ ...email, subject: e.target.value })}
                                    value={email?.subject}
                                />
                            </div>
                            <div className='col-md-12'>
                                <Label htmlFor='CC'>CC</Label>
                                <Input
                                    contentEditable
                                    placeholder='i.e. jim@a.com; jane@b.com'
                                    onChange={e => setEmail({ ...email, cc: e.target.value })}
                                    value={email?.cc}
                                />
                            </div>
                            <div className='col-md-6'>
                                <FormGroup>
                                    <Label htmlFor='From alias'>
                                        <span>
                                            <Popovers
                                                title='Customize the sender alias'
                                                desc={`This alias, (${email?.fromEmail}) and your name '${me?.name}' will be the email sender. However, when recipients reply to the email, they will reply to '${me?.email}'`}
                                                trigger='hover'
                                                delay={1000}>
                                                <Icon icon="InfoCircle">
                                                </Icon>
                                            </Popovers>
                                            From Email Address
                                        </span>
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id='exampleMailAddress'
                                            placeholder="i.e ProjectEfficient"
                                            aria-label="From alias"
                                            autoComplete='email'
                                            ariaDescribedby='addon2'
                                            onChange={e => setEmail({ ...email, fromEmail: e.target.value })}
                                            value={email?.fromEmail}
                                        />
                                        <InputGroupText id='addon2'>
                                            @finspace.app
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className='col-md-12'>
                                <div className='row' >
                                    {
                                        buyerFields?.map(f => {
                                            return <div className='col-auto'>
                                                <Button
                                                    color="success"
                                                    onClick={() => { quillInsert(f.value) }}
                                                >
                                                    {f.label}
                                                </Button>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div>
                                    <ReactQuill
                                        ref={(el) => { setReactQuillRef(el) }}
                                        modules={
                                            {
                                                toolbar: toolbarOptions
                                            }
                                        }
                                        value={email?.body ?? ""}
                                        onChange={e => {
                                            setEmail({ ...email, body: e })
                                        }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </CardBody >
                </Card>
                {
                    email?.attachments?.map((attachment, i) => {
                        switch (attachment?.type) {
                            case "PDF":
                                return <EmailAttachment
                                    attachment={attachment}
                                    setAttachment={(a) => {
                                        const newAttachments = [...email?.attachments]
                                        newAttachments[i] = a
                                        setEmail({ ...email, attachments: newAttachments })
                                    }}
                                    deleteAttachment={() => deleteAttachment(i)}
                                />
                                break;

                            default:
                                return <OtherAttachment
                                    attachment={attachment}
                                    setAttachment={(a) => {
                                        const newAttachments = [...email?.attachments]
                                        newAttachments[i] = a
                                        setEmail({ ...email, attachments: newAttachments })
                                    }}
                                    deleteAttachment={() => deleteAttachment(i)}
                                />
                                break;
                        }
                    })
                }
                <div className='row'>
                    <div className='col-auto'>
                        <FileDropPad startFileAdd={startFileAdd} />
                    </div>
                    <div className='col-auto'>
                        Size limit (20MB)
                    </div>
                </div>
            </>
    )
}
