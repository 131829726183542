import Auth0ClientService from './Auth0ClientService';
import DelphiConfigProvider from './DelphiConfigProvider';
import { v4 as uuidv4 } from 'uuid';

class TrackerService {
    static auth0client = null;

    static async getTrackers(trackerId, excludeActionItems = false) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const exStr = excludeActionItems ? "?excludeActionItems=true" : ""

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/trackers/${trackerId}${exStr}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async getTrackerBreakdown() {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/projects/trackerBreakdown`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async getTracker(trackerId) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/trackers/${trackerId}/detailed`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async saveTrackers(trackers) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        for (var tracker of trackers) {
            if (!tracker?._id) {
                const uid = uuidv4()
                tracker._id = uid
            }
        }

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/trackers`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(trackers)
        })

        const ret = await resp.json()
        return ret
    }

    static async saveTrackerRows(trackerId, trackerRows, overwrite = false) {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const overwriteString = overwrite ? "?overwrite=true" : ""

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/trackers/${trackerId}/trackerRows${overwriteString}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(trackerRows)
        })

        const ret = await resp.json()
        return ret
    }
}
export default TrackerService;