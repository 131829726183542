import React from 'react';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../bootstrap/Card';
import Modal, {
    ModalBody, ModalHeader
} from '../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../bootstrap/OffCanvas';
// import FormGroup from './bootstrap/forms/FormGroup';
import { AvatarGroupMemberList } from './AvatarGroupMemberList';

export const AvatarGroupMemberModal = ({ setIsOpen, isOpen, members, updateMembers }) => {

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='AvatarGroupMemberModal'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>Members</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                <div className='row g-4'>
                    <div className='col-12'>
                        Add Members
                    </div>
                    <div className='col-12'>
                        <Card isCompact className='mb-0'>
                            <CardHeader>
                                <CardLabel>
                                    <CardTitle>Members</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody >
                                <AvatarGroupMemberList members={members} updateMembers={updateMembers} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}