import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faComment, faCheckCircle, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import DatePicker from "react-datepicker";
import Input from '../../components/bootstrap/forms/Input';

const AddTaskRow = ({ addNewTask, workstreamIndex }) => {

    const [newTaskTitle, setNewTaskTitle] = useState("")

    return (
        <>
            <td style={{ maxWidth: "80px" }} className="task-action-btn task-check at-td">
            </td>
            <td className="add-task-label at-td" >
                <input
                    className='addNewTaskBox'
                    onChange={e => setNewTaskTitle(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            setNewTaskTitle("")
                            addNewTask(workstreamIndex, { title: e.target.value })
                        }
                    }}
                    value={newTaskTitle}
                    placeholder="+Add New Task"
                    type="text"
                />
            </td>
        </>
    )
}

export { AddTaskRow }


