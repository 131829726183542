import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../services/DelphiLogger';
import ProjectService from '../../services/ProjectService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from '../bootstrap/Button';
import Modal, {
    ModalBody, ModalFooter, ModalHeader, ModalTitle
} from '../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../bootstrap/OffCanvas';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../bootstrap/Card';
import UserService from '../../services/UserService';
import * as Yup from 'yup';
import { WizardGenerator } from '../WizardGenerator/WizardGenerator';
import { BuyerListImport } from '../BuyerList/BuyerListImport';
import TrackerService from '../../services/TrackerService';
import TemplateService from '../../services/TemplateService';
import TaskService from '../../services/TaskService';

export const NewTrackerModal = ({ isOpen, setIsOpen }) => {

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>New Tracker</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                
            </ModalBody>
        </Modal>
    )
}