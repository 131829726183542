import React, { useState } from 'react'
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../components/bootstrap/Card';
import moment from 'moment';
import useSortableData from '../../hooks/useSortableData';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import Button from '../../components/bootstrap/Button';
import { Link } from 'react-router-dom';
import PhaseService from '../../services/PhaseService';
import DelphiLogger from '../../services/DelphiLogger';

export const ProjectsList = ({
    projects,
    setIsNewProjectModalOpen,
    updateProject
}) => {

    const [perPage, setPerPage] = useState(PER_COUNT['25']);
    const { items, requestSort, getClassNamesFor } = useSortableData(projects);
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <Card stretch>
            <CardHeader tag='h4' className='h5'>
                <CardLabel icon="Files" iconColor="success">
                    <CardTitle>
                        Deals ({projects?.length})
                    </CardTitle>
                </CardLabel>
                <CardActions>
                    <Button
                        color='info'
                        icon='Add'
                        isLight
                        onClick={() => setIsNewProjectModalOpen(true)}>
                        Add New Deal
                    </Button>
                </CardActions>
            </CardHeader>
            <CardBody isScrollable>
                <div className='row'>
                    <div className='col-12'>
                        <table className='table table-modern' id="demoDT">
                            <thead>
                                <tr>
                                    <th scope="row"
                                        // onClick={() => requestSort('date')}
                                        className='int cursor-pointer text-decoration-underline'
                                    >
                                    </th>
                                    <th
                                        className='int cursor-pointer text-decoration-underline' scope="row"
                                        onClick={() => requestSort('title')}
                                    >
                                        Deal/Company Name
                                    </th>
                                    <th
                                        className='int cursor-pointer text-decoration-underline' scope="row"
                                        onClick={() => requestSort('updatedDate')}
                                    >
                                        Last Updated
                                    </th>
                                    <th
                                        className='int' scope="row"
                                    >
                                        Phase
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataPagination(items, currentPage, perPage).map((file, i) => {

                                    var phase = PhaseService.getPhase(file.currentPhase)
                                    var phaseInd = file?.phases?.findIndex(x => {
                                        const curPhase = PhaseService.getPhase(x)
                                        DelphiLogger.debug(phase, "Phase")
                                        DelphiLogger.debug(curPhase, "X")
                                        return phase?._id == curPhase?._id
                                    })
                                    phaseInd = phaseInd > -1 ? phaseInd + 1 : 0
                                    const phaseString = `${phase?.title} (${phaseInd}/${file?.phases?.length})`

                                    return <tr>
                                        <th>
                                            <div className="file-button-set">
                                                <Button color="danger" icon="xLg"
                                                    onClick={
                                                        () => updateProject({ ...file, toDelete: true })
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <td>
                                            {
                                                <Link to={`/dealdashboard/${file?._id}`}>
                                                    <span>
                                                        {file?.title}
                                                    </span>
                                                </Link>
                                            }
                                        </td>
                                        <td>
                                            {moment(file?.updatedDate).fromNow()}
                                        </td>
                                        <td>
                                            {
                                                phaseString
                                            }
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table >
                    </div>
                </div>
            </CardBody>
            <PaginationButtons
                data={items}
                label='items'
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
            />
        </Card>)
}
