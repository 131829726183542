import React, { useState, useCallback } from 'react';
import { useAuth0, } from "@auth0/auth0-react";
import InputGroup from './InputGroup';
import Textarea from './Textarea';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave, faClone, faPaperPlane, faLink, faCircle } from '@fortawesome/free-regular-svg-icons'
import { ChatAvatar } from '../../Chat';
import { useDropzone } from 'react-dropzone'
import './FileInput.css'
import DelphiLogger from '../../../services/DelphiLogger';

const FileInput = ({ onSubmit, task }) => {

    const onDrop = useCallback(acceptedFiles => {
        var newFilesToUpload = [...filesToUpload]
        acceptedFiles.map((file) => {
            var index = newFilesToUpload.findIndex(x => x.name == file.name);
            index === -1 ? newFilesToUpload.push(file) : console.log(`${file.name} item already in list to be uploaded`);
        })
        setFilesToUpload(newFilesToUpload)
    }, [])

    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
        onDrop: onDrop,
        noClick: true
    })

    const { user } = useAuth0();
    const [textAreaContent, setTextAreaContent] = useState('');
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [isCommentSubmitting, setIsCommentSubmitting] = useState(false);

    return (
        <div className="w-100 p-2 add-commentSection" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p style={{ height: "70px" }}>+ Drop files here</p> :
                    <InputGroup>
                        <ChatAvatar style={{ marginRight: "10px" }} isOnline={true}
                            srcSet={user?.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"}
                            src={user?.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"} />

                        <Textarea rows={2}
                            value={textAreaContent}
                            onChange={(event) => {
                                setTextAreaContent(event.target.value);
                            }}>
                        </Textarea>
                        {/* <Button
                            onClick={open}
                            className="bg-success">
                            <FontAwesomeIcon icon={faLink} />
                        </Button> */}
                        <Button isDisable={isCommentSubmitting || textAreaContent?.length < 2}
                            className="bg-info"
                            onClick={async () => {
                                setIsCommentSubmitting(true)
                                try {
                                    await onSubmit(textAreaContent, filesToUpload);
                                    setTextAreaContent('')
                                    setFilesToUpload([])
                                } catch (err) {
                                    DelphiLogger.logError("Comment failed", err.message)
                                }
                                setIsCommentSubmitting(false)
                            }}>
                            {
                                isCommentSubmitting ?
                                    <FontAwesomeIcon icon={faCircle} className="spinner" /> :
                                    <FontAwesomeIcon icon={faPaperPlane} />
                            }
                        </Button>
                    </InputGroup>

            }
            {
                filesToUpload.map((file, ind) => {
                    return <div>{file.name} {file.size} B</div>
                })
            }
        </div>
    )
}

export { FileInput }