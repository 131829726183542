import DelphiConfigProvider from './DelphiConfigProvider';
import DelphiLogger from './DelphiLogger';
import Auth0ClientService from './Auth0ClientService'
import user6 from '../assets/img/user6.png';
import { v4 as uuidv4 } from 'uuid';

class UserService {

    static errored = false;
    static initializing = false;
    static initialized = false;
    static prom = null;

    static currentOrganization = null;
    static organizations = null;
    static coWorkers = [];
    static coWorkersDict = {};
    static allUsers = [];
    static allUsersDict = {};
    static instance = null;
    static auth0client = null;
    static me = null;

    static isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    static async getGroups(groupIds) {
        const groupIdString = groupIds.join(',')

        var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const resp = await fetch(`${DelphiConfigProvider.config.userApiEndpoint}/groups/${groupIdString}`, requestOptions)
        const ret = await resp.json()
        DelphiLogger.debug(ret, "groups")
        return ret
    }

    static async saveGroup(group) {

        DelphiLogger.debug(group, "Working Group")
        var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify([group])
        }

        const resp = await fetch(`${DelphiConfigProvider.config.userApiEndpoint}/groups?overwrite=true&updateMembers=true`, requestOptions)

        const ret = await resp.json()
        DelphiLogger.debug(ret, "groups")
        return ret
    }

    static async fetchMyOrgs() {

        var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const url = `${DelphiConfigProvider.config.userApiEndpoint}/users/me/orgs`

        try {
            const resp = await fetch(url, requestOptions)
            const orgs = await resp.json()

            if (orgs.length < 0) {
                throw new Error("User does not belong to an organization")
            }
            UserService.organizations = orgs
            UserService.currentOrganization = (UserService.organizations ?? []).shift()

            // Return the number of tasks saved
            DelphiLogger.logMessage(
                'Orgs retrieved successfully',
                'Orgs retrieved successfully',
            )

            return orgs
        }
        catch (err) {
            // Return the number of tasks saved
            DelphiLogger.logError(
                'Orgs retrieved failed',
                'Orgs retrieved failed'
            )
        }
    }

    static generateCoworkersDict() {
        var newDict = {}

        for (var user of UserService.coWorkers) {
            newDict[user.email] = user
            newDict[user.user_id] = user
        }

        UserService.coWorkersDict = newDict
    }

    static addUserToDict(user) {
        UserService.allUsersDict = { ...UserService.allUsersDict }
        UserService.allUsersDict[user.email] = user
        UserService.allUsersDict[user.user_id] = user
    }

    static generateAllUsersDict() {
        var newDict = {}

        for (var user of UserService.allUsers) {
            newDict[user.email] = user
            newDict[user.user_id] = user
        }

        UserService.allUsersDict = newDict
    }

    static async fetchMyCoworkers(org) {

        try {
            var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }

            const url = `${DelphiConfigProvider.config.userApiEndpoint}/orgs/${org.id}/users`
            const resp = await fetch(url, requestOptions)
            const coworkers = await resp.json()
            UserService.coWorkers = coworkers;
            UserService.generateCoworkersDict();

            // Return the number of tasks saved
            DelphiLogger.logMessage(
                'Coworkers retrieved successfully',
            )

            return coworkers
        }
        catch (err) {
            // DelphiLogger.logError(
            //     "Coworkers retrieval failed",
            //     `Coworkers retrieval failed ${err}`
            // )
        }
    }

    static async fetchAllUsers() {

        try {
            var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }

            const url = `${DelphiConfigProvider.config.userApiEndpoint}/users`
            const resp = await fetch(url, requestOptions)
            const allUsers = await resp.json()
            UserService.allUsers = allUsers;
            UserService.generateAllUsersDict();

            // Return the number of tasks saved
            DelphiLogger.logMessage(
                'allUsers retrieved successfully',
            )

            return allUsers
        }
        catch (err) {
            DelphiLogger.logError(
                "allUsers retrieval failed",
                `allUsers retrieval failed ${err}`
            )
        }
    }

    static async getPossibleOrganizations() {

        try {
            var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }

            const url = `${DelphiConfigProvider.config.userApiEndpoint}/orgs/myorgoptions`
            const resp = await fetch(url, requestOptions)
            const orgs = await resp.json()

            // Return the number of tasks saved
            DelphiLogger.logMessage(
                'Org options retrieved successfully',
            )

            return orgs
        }
        catch (err) {
            DelphiLogger.logError(
                "Org options retrieval failed",
                `Org options retrieval failed ${err}`
            )
        }
    }

    static async initialize() {

        if (!UserService.prom) {
            UserService.initializing = true
            const p = async () => {
                try {
                    await UserService.fetchMyOrgs()
                    await UserService.fetchMyCoworkers(UserService.currentOrganization)
                    await UserService.fetchAllUsers()
                    await UserService._getMe()
                    UserService.initialized = true
                    UserService.initializing = false

                    return true
                }
                catch (err) {
                    UserService.initializing = false
                    UserService.initialized = true
                    UserService.errored = true

                    DelphiLogger.logError(
                        "UserService initialize failed",
                        `UserService initialize failed ${err.message}`
                    )

                    return false
                }
            }

            UserService.prom = p()
        }

        return await UserService.prom
    }

    static async _getMe() {

        var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const url = `${DelphiConfigProvider.config.userApiEndpoint}/users/me`
        const resp = await fetch(url, requestOptions)
        const me = await resp.json()

        // Return the number of tasks saved
        DelphiLogger.logMessage(
            'User Profile retrieved successfully',
        )

        UserService.me = me
    }

    static async getOrCreateUser(email) {

        var userOption = this.getUser(email)

        if (!userOption || UserService.isEmptyOrSpaces(userOption?.email)) {

            var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }

            const url = `${DelphiConfigProvider.config.userApiEndpoint}/users/getcreate/${email}`
            const resp = await fetch(url, requestOptions)
            userOption = await resp.json()

            UserService.addUserToDict(userOption)

            // Return the number of tasks saved
            DelphiLogger.logMessage(
                'User Profile retrieved successfully',
            )
        }

        return userOption
    }

    static getMe() {
        return UserService.me
    }

    static getMyCurrentOrg() {
        return UserService.currentOrganization
    }

    static getOrgUsers() {
        return UserService.coWorkers
    }

    static getUser(id) {
        var user = null
        if (UserService.initialized && UserService.coWorkersDict) {
            user = UserService.allUsersDict[id]
            if (!user) {
                user = {
                    email: '',
                    name: 'Deleted User',
                    picture: user6,
                    user_id: id,
                    isFalseUser: true
                }
            }
        }
        return user
    }

    static async submitUserProfile(formData) {

        var accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(formData)
        }

        const url = `${DelphiConfigProvider.config.userApiEndpoint}/users/me`
        await fetch(url, requestOptions)
    }

    static async uploadAvatar(file) {

        var imageUrl = null

        DelphiLogger.logMessage(
            'Uploading file',
            `Uploading file: ${file.name}`,
        )

        const formData = new FormData();
        formData.append("file", file);
        formData.append("filename", file.name);

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0userApiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.userApiEndpoint}/users/me/avatar`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        var respObj = await resp.json()
        imageUrl = respObj.url;

        DelphiLogger.logMessage(
            'Avatar uploaded successfully',
            `Successful: ${imageUrl}`,
        )

        return imageUrl
    };
}

export default UserService;