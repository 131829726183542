import { useAuth0 } from "@auth0/auth0-react";
import { faListCheck, faPlus, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/bootstrap/Button';
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from '../../components/bootstrap/Dropdown';
import { PhaseArrowProgressBar } from '../../components/Phase/PhaseArrow/PhaseArrowProgressBar';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import TaskPanel from './TaskPanel';
import TaskTable from './TaskTable';

import HistoryService from '../../services/HistoryService';
import TagService from '../../services/TagService';
import TaskService from '../../services/TaskService';
import TemplateService from '../../services/TemplateService';
import UserService from '../../services/UserService';

import { NewWorkstreamModal } from './modals/NewWorkstreamModal';
import { SaveWorkstreamTemplateModal } from './modals/SaveWorkstreamTemplateModal';


import DelphiLogger from '../../services/DelphiLogger';
import FileService from '../../services/FileService';
import ProjectService from '../../services/ProjectService';
import { NewProjectModal } from './modals/NewProjectModal/NewProjectModal';
import { NewWorkstreamFromTemplateModal } from './modals/NewWorkstreamFromTemplateModal';
import { ProjectCompletionBanner } from './ProjectCompletionBanner';
import PhaseService from "../../services/PhaseService";
import { Input } from "@mui/material";
import Breadcrumb from "../../components/bootstrap/Breadcrumb";

const ProjectTaskPage = () => {

  const { id, taskId } = useParams();
  const navigate = useNavigate();

  const [toggleRightPanel, setToggleRightPanel] = useState(false);

  const newTags = new Set()
  const { user } = useAuth0();

  const [isNewWSModalOpen, setIsNewWSModalOpen] = useState(false);
  const [isWSTemplateModalOpen, setIsWSTemplateModalOpen] = useState(false);
  const [isWSTemplateModalAddOpen, setIsWSTemplateModalAddOpen] = useState(false);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [workStreamToSave, setWorkStreamToSave] = useState({});

  const [isLoading, setisLoading] = useState(true);
  const [projectFound, setProjectFound] = useState(true);
  const [curProject, setCurProject] = useState({});
  const [projectCompletionStats, setProjectCompletionStats] = useState({});
  const [selectMode, setSelectMode] = useState(false);
  const [projects, setProjects] = useState([]);
  const [phaseFilter, setPhaseFilter] = useState([]);
  const [workStreams, setWorkStreams] = useState([]);
  const [templateWorkstreams, setTemplateWorkstreams] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [isTaskPanelOpen, setIsTaskPanelOpen] = useState(false);
  const [focusItems, setFocusItems] = useState({ task: {}, workstreamIndex: -1, taskIndex: -1 });
  const [filterSort, setFilterSort] = useState({})

  const uploadFiles = () => {
  }

  const addNewWorkstream = (ws) => {

    const uid = uuidv4()
    ws._id = ("t" + uid)
    ws.project = curProject._id
    ws.index = workStreams?.length

    const tasks = ws?.tasks?.map((task, i) => {
      return {
        ...task,
        project: curProject._id,
        workstream: ws._id,
        _id: uuidv4(),
        g: uuidv4(),
        status: "New",
        comments: [],
        history: [],
        type: "task"
      }
    }) ?? []

    ws.tasks = tasks
    TaskService.putWorkstream(ws)
    TaskService.putTasks(tasks)

    const workStreamsTarget = [...workStreams]

    workStreamsTarget.push(ws)
    setWorkStreams(workStreamsTarget)
    setIsNewWSModalOpen(false)
  }

  const updateSubTask = (subTask, workstreamIndex, taskIndex, subTaskIndex) => {


    const workStreamsTarget = [...workStreams]
    var task = workStreamsTarget[workstreamIndex].tasks[taskIndex]
    task = { ...task }
    var subTasks = workStreamsTarget[workstreamIndex].tasks[taskIndex]?._displaySubTasks ?? []
    subTasks = [...subTasks]

    if (!(subTaskIndex >= 0)) {

      subTask = {
        ...subTask,
        parent: task?._id,
        status: "New",
        comments: [],
        project: id,
        history: [],
        workstream: workStreamsTarget[workstreamIndex]._id,
        index: subTasks.length,
        type: "task"
      }

      subTaskIndex = subTasks.length
      subTasks.push(subTask)
    }
    else {
      subTasks[subTaskIndex] = subTask
    }

    task._displaySubTasks = subTasks
    workStreamsTarget[workstreamIndex].tasks[taskIndex] = task
    TaskService.putTask(subTask)
    setWorkStreams(workStreamsTarget)
    var projectCompletionStats = getProjectCompletionState(curProject, workStreamsTarget)
    setProjectCompletionStats(projectCompletionStats)

    setFocusItems(
      {
        type: 'task',
        task: task,
        workstreamIndex: workstreamIndex,
        taskIndex: taskIndex
      }
    )

    DelphiLogger.debug({
      subTask: subTask,
      workstreamIndex: workstreamIndex,
      taskIndex: taskIndex,
      subTaskIndex: subTaskIndex,
      task: task
    },
      "Full Update")
  }

  const updateAllWSTasks = (workstreamIndex, newTasks, shouldConfirm = false) => {

    const isConfirmed = shouldConfirm ? window.confirm("Are you sure? Old task state is not preserved") : true;

    if (isConfirmed) {
      const workStreamsTarget = [...workStreams]
      workStreamsTarget[workstreamIndex] = { ...workStreamsTarget[workstreamIndex], status: 'Complete' }

      workStreamsTarget[workstreamIndex].tasks = newTasks
      setWorkStreams(workStreamsTarget)
      TaskService.putTasks(newTasks)
    }

    return isConfirmed
  }

  const deleteWorkstream = (workstream, workstreamIndex) => {

    const isConfirmed = updateAllWSTasks(workstreamIndex, { toDelete: true })
    isConfirmed && updateWorkstream({ ...workstream, toDelete: true }, workstreamIndex)
  }

  var getProjectCompletionState = (curProject, workStreams) => {
    var ret = {}

    ret.ProjectName = curProject.title
    ret.project = curProject
    var totalTasks = 0
    var statusBreakDown = {}

    for (var ws of workStreams) {
      for (var task of ws.tasks) {
        var status = task.status
        statusBreakDown[status] = (statusBreakDown[status] || 0) + 1;
        totalTasks = totalTasks + 1
      }
    }

    ret.statusBreakDown = statusBreakDown
    ret.totalTasks = totalTasks
    return ret
  }

  const startWorkstreamTemplateSave = (ws) => {

    const wsToSave = { ...ws, tasks: [] }

    ws.tasks?.map((item, taskIndex) => {
      wsToSave.tasks[taskIndex] = { ...item, isSelected: !selectMode || item.isSelected }
    });

    setWorkStreamToSave(wsToSave)
    setIsWSTemplateModalOpen(true)
  }

  const saveWorkstreamTemplate = (ws) => {
    return TemplateService.saveWorkStreamTemplate(ws).then(() => {
      setTemplateWorkstreams([...templateWorkstreams, ws])
    })
  }

  // When selecting or adding a tag
  const updateTaskTags = (
    newValue,
    actionMeta,
    task,
    workstreamIndex,
    taskIndex
  ) => {

    if (actionMeta.action == 'create-option') {
      const newVal = newValue[newValue.length - 1]

      // Only save a tag if it is not already an option
      if (!(tagOptions.some(x => x.value == newVal))) {
        const newTag = { ...newVal, _id: newVal.value }
        setTagOptions([...tagOptions, newTag])
        TagService.putTag(newTag)
      }
    }

    updateTask({ ...task, tags: newValue }, workstreamIndex, taskIndex)
  };

  const addNewTask = (workstreamIndex, newTaskIn = {}) => {

    const uid = uuidv4()
    const workStreamsTarget = [...workStreams]
    const newTask = {
      ...newTaskIn,
      _id: ("t" + uid),
      g: uuidv4(),
      status: "New",
      comments: [],
      project: id,
      history: [],
      workstream: workStreamsTarget[workstreamIndex]._id,
      index: workStreamsTarget[workstreamIndex]?.tasks?.length,
      type: "task"
    }
    TaskService.putTask(newTask)
    workStreamsTarget[workstreamIndex].tasks.push(newTask)
    setWorkStreams(workStreamsTarget)
  }

  const updateWorkstreams = (newWorkstreams) => {

    const wsToSave = newWorkstreams?.map((ws, i) => ({ ...ws, index: i }))
    TaskService.putWorkstreams(wsToSave)
    // TODO: Focus Workstream?
    setWorkStreams(wsToSave)
  }

  const updateWorkstream = (newWorkstream, workstreamIndex) => {

    TaskService.putWorkstream(newWorkstream)
    // TODO: Focus Workstream?

    const workStreamsTarget = [...workStreams]
    workStreamsTarget[workstreamIndex] = newWorkstream

    setWorkStreams(workStreamsTarget)
  }

  const updateTask = (newTask, workstreamIndex, taskIndex, shouldConfirm = false) => {

    const isConfirmed = shouldConfirm ? window.confirm("Are you sure? Old task state is not preserved") : true;
    if (!isConfirmed) {
      return;
    }

    TaskService.putTask(newTask)
    setFocusItems(
      {
        type: 'task',
        task: newTask,
        workstreamIndex: workstreamIndex,
        taskIndex: taskIndex
      }
    )
    const workStreamsTarget = [...workStreams]
    workStreamsTarget[workstreamIndex].tasks[taskIndex] = newTask

    setWorkStreams(workStreamsTarget)

    var projectCompletionStats = getProjectCompletionState(curProject, workStreamsTarget)
    setProjectCompletionStats(projectCompletionStats)
  }

  const findAndSetFocusTaskFromParam = (workStreams) => {
    if (taskId) {
      workStreams?.map((w, i) => {
        w?.tasks?.map((t, j) => {
          if (t._id == taskId) {
            setFocusTask(t, i, j)
            setIsTaskPanelOpen(true)
            return
          }
        })
      })
    }
  }

  const setFocusTask = (task, workstreamIndex, taskIndex) => {

    if (task._id != focusItems?.task?._id) {
      setFocusItems(
        {
          task: task,
          workstreamIndex: workstreamIndex,
          taskIndex: taskIndex
        }
      )
    }

    const link = `/dealtasks/${task.project}/${task._id}`
    navigate(link);
  }

  const navigateToProject = (p) => {
    const link = `/dealtasks/${p._id}`
    navigate(link);
    pullAll(p._id)
  }

  // Add a comment to a task
  const addComment = async (task, comment, filesToUpload, workstreamIndex, taskIndex) => {

    const commentObj = {
      body: comment,
      author: user.sub,
      type: "comment",
      updatedDate: (new Date()).toISOString(),
      createdDate: (new Date()).toISOString(),
      state: "created"
    }

    var filesCreatedIds = []
    if (filesToUpload.length > 0) {
      const filesCreated = await FileService.uploadFiles(filesToUpload, curProject)
      filesCreatedIds = filesCreated.map(f => f._id)
      commentObj.files = filesCreatedIds
      commentObj._displayFiles = filesCreated
    }

    HistoryService.putHistory(task?._id, [commentObj]).catch((error) => {
      DelphiLogger.logMessage(
        'Comment add FAILED',
        `Comment add FAILED ${error}`,
      )
    })

    const history = task.history ?? []
    updateTask({ ...task, history: [...history, commentObj], files: [...(task.files ?? []), ...filesCreatedIds] }, workstreamIndex, taskIndex)
  }

  var dropDownOptions = {
    stateOptions: [
      "New",
      "Active",
      "Complete",
    ],
    priorityOptions:
      [
        "",
        "Low",
        "Medium",
        "High"
      ],
    tagOptions: tagOptions,
    orgUserOptions: orgUsers
  }

  const resetPhaseFilter = (project) => {
    setPhaseFilter(project.phases)
  }

  const pullProjects = (currentOrg, id) => {
    return ProjectService.getProjects(currentOrg?.id)
      .then((projects) => {
        setProjects(projects)
        let foundProject = projects.find(proj => proj._id == id)

        if (!id) {
          if (projects.length > 0) {
            navigateToProject({ _id: projects[0]._id })
          }
        }
        else if (foundProject == undefined) {
          DelphiLogger.logMessage(
            `Current project not found`,
            "Current project not found",
          )

          setProjectFound(false)
        }
        else {
          setProjectFound(true)
          setCurProject(foundProject)
          resetPhaseFilter(foundProject)
        }

        return foundProject
      })
  }

  const pullWorkstreamTemplates = () => {
    return TemplateService.getWorkStreamTemplates()
      .then(async (resp) => {
        const workstreams = await resp.json()

        setTemplateWorkstreams(workstreams)
        DelphiLogger.logMessage(
          `Workstream template retrieval succeeded`,
          "Workstream template retrieval succeeded",
        )
      }).catch(
        (error) => DelphiLogger.logMessage(
          "Workstream template retrieval failed",
          `Workstream template retrieval failed ${error}`,
        ))
  }

  const preProcessTasks = (tasks) => {
    var index = 0
    var indset = new Set()

    // Collect all current valid indeces
    tasks.map(task => {
      if (task.index){
        indset.add(task.index)
      }
    })

    var ret = tasks.map(task => {
      if (!task.index){
        while(indset.has(index)){
          index+=1
        }
        task.index = index
        indset.add(task.index)
        index+=1
      }
      return task
    })

    return ret
  }

  const preProcessWorkstreams = (workStreams) =>
  {
    var index = 0
    var indset = new Set()

    // Collect all current valid indeces
    workStreams.map(ws => {
      if (ws.index){
        indset.add(ws.index)
      }
    })

    var ret = workStreams.map(ws => {
      ws.tasks = preProcessTasks(ws?.tasks ?? [])
      if (!ws.index){
        while(indset.has(index)){
          index+=1
        }
        ws.index = index
        indset.add(ws.index)
        index+=1
      }
      return ws
    })

    return ret
  }

  const pullWorkStreams = (id) => {
    return TaskService.getProjectWorkflowTasks(id)
      .then(async (resp) => {
        var workStreams = await resp.json()
        workStreams = preProcessWorkstreams(workStreams)
        setWorkStreams(workStreams)
        return workStreams
      })
  }

  const pullTags = (id) => {
    const prom = TagService.getTags().then(
      async (resp) => {
        const tags = await resp.json()
        setTagOptions(tags)
      }
    ).catch(
      (error) => DelphiLogger.logMessage(
        `Tags retrieval failed ${error}`,
        "Tags retrieval failed",
      ))

    return prom
  }

  const updateSearch = (text) => {
    setFilterSort({ ...filterSort, search: text })
  }

  const updateOrgUsers = (curProject) => {
    const Ids = [...UserService.getOrgUsers()?.map(u => u?._id), ...curProject?.collaborators]
    var orgUsers = Ids?.map(x => UserService.getUser(x))
    orgUsers = orgUsers?.filter(x => !x?.isFalseUser)
    return Promise.all(
      orgUsers.map(async (user) => {
        return {
          ...user,
          value: user.user_id,
          label: <div style={{ color: "black" }}><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
        }
      })).then((orgUserOptions) => {
        setOrgUsers(orgUserOptions)
      })
  }

  const loadUsers = async (workStreams) => {
    const workStreamsTarget = [...workStreams]

    await Promise.all(
      workStreamsTarget.map(async (ws) => {
        ws.tasks = [...ws.tasks]

        await Promise.all(
          ws.tasks.map(async (task, i) => {
            var newTask = { ...task, loaded: true, }
            var assignees = task.assignees ?? []
            newTask.displayAssignees = assignees.map((as) => {
              var user = UserService.getUser(as)
              return {
                ...user,
                value: user.user_id,
                label: <div><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
              }
            })
            ws.tasks[i] = newTask
          })
        )
      })
    )

    setWorkStreams(workStreamsTarget)
  }

  const pullAll = async (id) => {
    await UserService.initialize()
    await PhaseService.init()
    var currentOrg = UserService.getMyCurrentOrg()
    Promise.all([
      pullTags(id),
      pullWorkStreams(id),
      pullProjects(currentOrg, id),
      pullWorkstreamTemplates(),
      UserService.initialize()
    ])
      .then((data) => {
        // TODO: Pull workstreams from global scope instead of promise
        var projectCompletionStats = getProjectCompletionState(data[2], data[1])
        setProjectCompletionStats(projectCompletionStats)
        findAndSetFocusTaskFromParam(data[1])
        return Promise.all(
          [
            updateOrgUsers(data[2]),
            loadUsers(data[1])
          ])
      })
  }

  useEffect(async () => {
    setisLoading(true)
    await pullAll(id)
    setisLoading(false)
  }, [id]);

  return (
    <>
      {
        isLoading ?
          <PageWrapper className="justify-content-center text-center" title="Tasks">
            <div className=" justify-content-center text-center" title="Tasks">
              <Dots></Dots>
            </div>
          </PageWrapper>
          :
          <PageWrapper title="Tasks">
            <SubHeader  >
              <SubHeaderLeft>
                <div>
                  <Input
                    placeholder="Search Tasks"
                    style={{ maxWidth: "2000px", minWidth: "300px" }}
                    value={filterSort?.search}
                    onChange={e => updateSearch(e.target.value)}

                  />
                  <FontAwesomeIcon icon={faXmarkCircle} onClick={() => updateSearch("")} />
                </div>
              </SubHeaderLeft>
              <SubHeaderRight>
                <Button
                  icon="PlusLg"
                  color="warning"
                  onClick={() => { setIsWSTemplateModalAddOpen(true) }}
                >
                  New Workstream From Template
                </Button>
                <Button
                  onClick={() => navigate("/mytasks")}
                  icon="Person"
                  color="light"
                />
              </SubHeaderRight>
            </SubHeader>
            <SubHeader zIndex={"800"}>
              <SubHeaderLeft>
                <Breadcrumb style={{ margin: "0" }}
                  list={[
                    {
                      title: "Deals",
                      to: `/deals`,
                    },
                    {
                      title: "Deal:",
                      to: `/deals/${curProject?._id}`,
                    },
                  ]}
                />
                <div>
                  <Dropdown variant="success" id="dropdown-basic">
                    <DropdownToggle>
                      <Button style={{ padding: "0" }} label={curProject?.title} clickable  >{curProject?.title}
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu style={{ zIndex: 1090 }} isAlignmentEnd>
                      {projects.map((p, ind) => (
                        <DropdownItem key={`p-${ind}`} onClick={() => { navigateToProject(p) }}>
                          {p.title}
                        </DropdownItem>
                      ))}
                      <DropdownItem onClick={() => { setIsNewProjectModalOpen(true) }}>
                        +Add New Deal
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </SubHeaderLeft>
              <SubHeaderRight>
                {
                  projectCompletionStats?.totalTasks > 0 ?
                    <ProjectCompletionBanner projectCompletionStats={projectCompletionStats} />
                    :
                    <></>
                }
              </SubHeaderRight>
            </SubHeader>


            {/* <SubHeader zIndex="880">
              <SubHeaderLeft>
                Phases
                <PhaseArrowProgressBar project={curProject} phaseFilter={phaseFilter} updateProject={updateProject} />
              </SubHeaderLeft>
            </SubHeader> */}

            <Page container='fluid'>

              <div className='row'>
                <div className='col-12 table-c'>
                  {
                    !projectFound ?
                      projects?.length > 0 ?
                        <>Project not found</> :
                        <>No projects yet. Create a new project in the dropdown above</> :
                      <TaskTable
                        setFilterSort={setFilterSort}
                        filterSort={filterSort}
                        updateWorkstreams={updateWorkstreams}
                        addNewWorkstream={addNewWorkstream}
                        updateAllWSTasks={updateAllWSTasks}
                        selectMode={selectMode}
                        startWorkstreamTemplateSave={startWorkstreamTemplateSave}
                        workstreams={workStreams}
                        deleteWorkstream={deleteWorkstream}
                        updateWorkstream={updateWorkstream}
                        dropDownOptions={dropDownOptions}
                        updateTaskTags={updateTaskTags}
                        updateTask={updateTask}
                        addNewTask={addNewTask}
                        setIsTaskPanelOpen={setIsTaskPanelOpen}
                        setFocusTask={setFocusTask}
                        phaseFilter={phaseFilter}
                      />
                  }
                </div>
              </div>
            </Page>

            {curProject && <NewWorkstreamModal
              curProject={curProject}
              addNewWorkstream={addNewWorkstream}
              setIsOpen={setIsNewWSModalOpen}
              isOpen={isNewWSModalOpen} />}

            <SaveWorkstreamTemplateModal
              workStreamToSave={workStreamToSave}
              setWorkStreamToSave={setWorkStreamToSave}
              saveWorkstreamTemplate={saveWorkstreamTemplate}
              addNewWorkstream={addNewWorkstream}
              setIsOpen={setIsWSTemplateModalOpen}
              isOpen={isWSTemplateModalOpen}
              dropDownOptions={dropDownOptions} />

            <NewWorkstreamFromTemplateModal
              curProject={curProject}
              setIsOpen={setIsWSTemplateModalAddOpen}
              isOpen={isWSTemplateModalAddOpen}
              workStreamOptions={templateWorkstreams}
              addNewWorkstream={addNewWorkstream}
            />

            {pullAll && <NewProjectModal
              setIsOpen={setIsNewProjectModalOpen}
              isOpen={isNewProjectModalOpen}
              callback={pullAll}
            />}

            <TaskPanel
              updateSubTask={updateSubTask}
              uploadFiles={uploadFiles}
              setIsTaskPanelOpen={setIsTaskPanelOpen}
              isTaskPanelOpen={isTaskPanelOpen}
              focusItems={focusItems}
              updateTask={updateTask}
              addComment={addComment}
              updateTaskTags={updateTaskTags}
              dropDownOptions={dropDownOptions} />
          </PageWrapper>
      }
    </>
  )
}

export default ProjectTaskPage;
