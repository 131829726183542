import React, { useState, useEffect } from 'react'
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import OffCanvas, {
    OffCanvasBody,
    OffCanvasHeader,
    OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Checks from '../../../components/bootstrap/forms/Checks';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import { Checkbox } from '@mui/material';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Chip from '@mui/material/Chip';
import { PriorityColumn } from '../columns/PriorityColumn';
import { CompletionTimeColumn, CompletionTimeHeader } from '../columns/CompletionTimeColumn';
import { Waves } from 'loading-animations-react';


export const SaveWorkstreamTemplateModal = ({ setIsOpen, isOpen, workStreamToSave, setWorkStreamToSave, saveWorkstreamTemplate, dropDownOptions }) => {

    const [isSaving, setIsSaving] = useState(false);

    const updateTask = (newTask, taskIndex) => {
        const workStreamTarget = { ...workStreamToSave }
        workStreamTarget.tasks = [...workStreamTarget.tasks]
        workStreamTarget.tasks[taskIndex] = newTask

        setWorkStreamToSave(workStreamTarget)
    }

    const saveTemplateWorkstream = () => {

        setIsSaving(true)
        saveWorkstreamTemplate(workStreamToSave).then(() => {
            setIsOpen(false)
        }).finally(() => {
            setIsSaving(false);
        })
    }

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'
            workStreamToSave={workStreamToSave}>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>Save Workstream As Template</OffCanvasTitle>
            </ModalHeader>
            <div>
                <ModalBody workStreamToSave={workStreamToSave}>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='title' label='Title' isFloating>
                                <Input
                                    placeholder='Title'
                                    onChange={(event) => setWorkStreamToSave({ ...workStreamToSave, title: event.target.value })}
                                    value={workStreamToSave.title}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='description' label='Description' isFloating>
                                <Textarea
                                    rows={4}
                                    placeholder='Description'
                                    onChange={(event) => setWorkStreamToSave({ ...workStreamToSave, description: event.target.value })}
                                    value={workStreamToSave.description}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <div className="task-list-container">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "4px" }}></th>
                                            <th scope="col">Title</th>
                                            <CompletionTimeHeader phrase={'Est. Time Required'} style={{ width: "25px" }} />
                                            <th scope="col" style={{ width: "25px" }}>Priority</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            workStreamToSave.tasks?.map((task, taskIndex) => (
                                                <tr className="task-row" >
                                                    <td className="task-action-btn task-check">
                                                        <div>
                                                            <Checkbox checked={task.isSelected}
                                                                onClick={() => { updateTask({ ...task, isSelected: !task.isSelected }, taskIndex) }} />
                                                        </div>
                                                    </td>
                                                    <td className="task-action-btn task-check">
                                                        <div>
                                                            <span
                                                                className={'title-column-span task-label '}
                                                                contentEditable
                                                                onBlur={e => updateTask({ ...task, title: e.currentTarget.textContent }, taskIndex)}
                                                            >
                                                                {task.title}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="task-action-btn task-check">
                                                        <CompletionTimeColumn task={task}
                                                            workstreamIndex={0} taskIndex={taskIndex} updateTask={(newTask, workstreamIndex, taskIndex) => updateTask(newTask, taskIndex)} />
                                                    </td>
                                                    <td className="task-action-btn task-check">
                                                        <PriorityColumn task={task}
                                                            updateTask={(newTask, workstreamIndex, taskIndex) => updateTask(newTask, taskIndex)}
                                                            dropDownOptions={dropDownOptions} workstreamIndex={0} taskIndex={taskIndex} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='bg-transparent'>
                    {
                        isSaving ? <Waves text={"Saving..."} waveColor="black" backgroundColor="inherit" /> :
                            <Button color="success" icon="Check" onClick={() => { saveTemplateWorkstream() }} >
                                Save Template
                            </Button>
                    }
                </ModalFooter>
            </div>
        </Modal >
    )
}
