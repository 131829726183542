import { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
// import FormGroup from './bootstrap/forms/FormGroup';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../../../../components/bootstrap/Card';
// import PhaseService from '../../services/PhaseService';
import "./dd.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PhaseService from '../../../../../services/PhaseService';
import Button from '../../../../../components/bootstrap/Button';
import Modal, {
    ModalBody, ModalHeader
} from '../../../../../components/bootstrap/Modal';
import { NewPhaseTypeModal } from './NewPhaseTypeModal';
import Input from '../../../../../components/bootstrap/forms/Input';

export const PhaseSelect = ({ isOpen, setIsOpen, project, updateProject, updatePhases, workstreams, updateWorkStream }) => {
    const projectType = project?.type ?? "sellSide"
    const phases = project?.phases ?? []
    const [newPhaseTypeModalIsOpen, setNewPhaseTypeModalIsOpen] = useState(false);
    const [newPhase, setNewPhase] = useState("")

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...phases];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        updatePhases(updatedList);
    };

    const [phaseDict, setPhaseDict] = useState({});

    // const updatePhaseOptions = async () => {
    //     await PhaseService.init()
    //     const d = await PhaseService.getPhaseDict()
    //     setPhaseDict(d)
    // }

    useEffect(async () => {
        // await updatePhaseOptions()
    }, []);

    const editPhaseName = async (oldName, newName) => {
        var updatedList = [...phases];
        var ind = updatedList?.findIndex(x => x == oldName)
        updatedList[ind] = newName

        await updatePhases(updatedList)

        if (updateProject && project?.currentPhase == oldName)
        {

            var projPhases = [...project?.phases]
            var ind = projPhases?.findIndex(x => x == oldName)
            projPhases[ind] = newName;

            updateProject({...project, currentPhase: newName, phases: projPhases})
        }
    }

    var filter = {
        // default filter configuration
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.data.value} ${option.data.label}`,
        trim: true,
    }

    // const phaseOptions = phaseDict[projectType]

    return (
        <>
            <Modal
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                titleId='projectPhases'
                isCentered
                isScrollable
                size='lg'>
                <ModalHeader setIsOpen={setIsOpen}>
                    Edit Project Phases
                </ModalHeader>
                <ModalBody>

                    <Card >
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Select Project Phases (At Least One)</CardTitle>
                                <br />
                                <CardSubTitle>
                                    Each project consists of 1 or more phases where every phase contains one or more tasks.
                                </CardSubTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <DragDropContext onDragEnd={handleDrop}>
                                    <Droppable droppableId="list-container">
                                        {(provided) => (
                                            <div
                                                className="rounded col-xl-12 list-container"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {phases?.map((item, index) => {
                                                    const phaseInfo = PhaseService.getPhase(item)

                                                    return <Draggable key={item} draggableId={item} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className="rounded item-container"
                                                                ref={provided.innerRef}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                            >
                                                                <div className='row'>
                                                                    <div className='col-auto' >
                                                                        <Button
                                                                            color="danger"
                                                                            icon="XLg"
                                                                            onClick={
                                                                                () => {
                                                                                    var newPhases = [...phases]
                                                                                    newPhases?.splice(index, 1);
                                                                                    updatePhases(newPhases)

                                                                                    // TODO: Delete all workflows of that phase

                                                                                }

                                                                            }
                                                                        />

                                                                    </div>
                                                                    <div style={{ marginTop: "7px" }} contentEditable onBlur={e => editPhaseName(phaseInfo?.title, e.currentTarget.textContent)} className='col-auto'>{phaseInfo?.title}</div>
                                                                    <div className='col-auto'>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className='row' style={{ paddingBottom: "70px" }}>
                                <div className='col-6'>
                                    <Input
                                        placeholder="New Phase Name"
                                        onChange={async e => {
                                            setNewPhase(e.target.value)
                                        }}
                                    />

                                    {
                                        // Flatten phases
                                        /* <Select className="tagSelect"
                                            placeholder="+Add Phase"
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            options={phaseOptions?.filter(x => (phases?.indexOf(x._id) == -1)).map(x => (
                                                { value: x._id, label: x.title }
                                            ))}
                                            minMenuHeight={10}
                                            onChange={async (newValue, actionMetaData) => {
                                                const unique = [...new Set([...(phases ?? []), newValue.value])]
                                                await updatePhases(unique)
                                            }}
                                            form="false"
                                            value={null}
                                            filterOption={createFilter(filter)}
                                            isClearable={true}
                                        /> */
                                    }
                                </div>
                                <div className='col-6'>
                                    {
                                        // Flatten phases
                                        <Button
                                            color='success'
                                            icon="Plus"
                                            onClick={
                                                async () => {
                                                    const unique = [...new Set([...(phases ?? []), newPhase])]
                                                    await updatePhases(unique)
                                                }
                                            }
                                        >
                                            Add New Phase
                                        </Button>
                                    }
                                </div>
                            </div>
                        </CardBody >
                    </Card>
                </ModalBody>
            </Modal>
            {/* <NewPhaseTypeModal
                updatePhaseOptions={updatePhaseOptions}
                project={project}
                isOpen={newPhaseTypeModalIsOpen}
                setIsOpen={setNewPhaseTypeModalIsOpen}
                setParentIsOpen={setIsOpen}
            /> */}
        </>
    )
}
