import React, { useEffect, useState } from 'react'
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../components/bootstrap/Card';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../layout/Page/Page';
import { Dots } from 'loading-animations-react';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import UserService from '../../services/UserService';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import MyTasksWidget from '../projectdashboard/Widgets/MyTasksWidget';
import { ProjectsWidget } from '../projectdashboard/Widgets/ProjectsWidget/ProjectsWidget';

export const MyDashboard = () => {

    const { id, taskId } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading')
    const [user, setUser] = useState(null)

    const refreshData = async () => {
        await UserService.initialize()
        var user = UserService.getMe()
        setUser(user)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, []);

    const getNewMyTask = (newTaskIn) => {
        const me = user

        const uid = uuidv4()
        return {
            ...newTaskIn,
            _id: ("t" + uid),
            g: uuidv4(),
            status: "New",
            comments: [],
            // project: id,
            assignees: [me?.user_id],
            displayAssignees: [{
                ...me,
                value: me.user_id,
                label: <div><img className="avatarImg" src={me.picture} height="30px" width="30px" />{me.name} </div>
            }],
            history: [],
            type: "task"
        }
    }

    return (
        isLoading ?
            <PageWrapper className='justify-content-center text-center' title='Tasks'>
                <div className='justify-content-center text-center' title='Tasks'>
                    <Dots text={loadingMessage}></Dots>
                </div>
            </PageWrapper>
            :
            <PageWrapper>
                <Page>
                    <div className='row h-50'>
                        <div className='col-xl-12 dc-400'>
                            <MyTasksWidget style={{ maxHeight: "350px" }} icon="" title="My Tasks" showComplete={false} filterTasks={(x) => !x?.project} getNewTask={getNewMyTask} />
                        </div>
                        <div className='col-xl-12 dc-400'>
                            <MyTasksWidget style={{ maxHeight: "350px" }} icon="" title={"Tasks Assigned To Me"} showComplete={false} filterTasks={(x) => x?.project} />
                        </div>
                        <div className='col-xxl-12 dc-400'>
                            <ProjectsWidget style={{ maxHeight: "350px" }} icon="" />
                        </div>
                    </div>
                </Page>
            </PageWrapper>
    )
}