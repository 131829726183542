import { Dots } from 'loading-animations-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Button from '../../components/bootstrap/Button';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from '../../components/bootstrap/Dropdown';
import { FileList } from '../../components/filelist/FileList';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import DelphiLogger from '../../services/DelphiLogger';
import FileService from '../../services/FileService';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import { NewProjectModal } from '../projecttasks/modals/NewProjectModal/NewProjectModal';

export const FilePage = () => {

    const { projectId } = useParams();
    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading')
    const [user, setUser] = useState(null)
    const [projectFound, setProjectFound] = useState(true);
    const [curProject, setCurProject] = useState({});
    const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);

    const navigateToProject = (p) => {
        const link = `/files/deal/${p._id}`
        navigate(link);
    }

    const pullProjects = (currentOrg, id) => {
        return ProjectService.getProjects(currentOrg?.id)
            .then((projects) => {
                setProjects(projects)
                let foundProject = projects.find(proj => proj._id == id)

                if (!id) {
                    if (projects.length > 0) {
                        navigateToProject({ _id: projects[0]._id })
                    }
                }
                else if (foundProject == undefined) {
                    DelphiLogger.logMessage(
                        `Current deal not found`,
                        "Current deal not found",
                    )

                    setProjectFound(false)
                }
                else {
                    setProjectFound(true)
                    setCurProject(foundProject)
                }

                return foundProject
            })
    }

    const updateFile = (fileInfo) => {
        try {
            fileInfo.updatedDate = Date.now()
            FileService.putFileInfo([fileInfo])

            const ind = files.findIndex(x => x._id == fileInfo._id)
            const newFiles = [...files]
            newFiles[ind] = fileInfo
            setFiles(newFiles)
        }
        catch (exception) {
            DelphiLogger.logError(
                `File save failed`,
                `File save failed`,
            )
        }
    }

    const refreshData = async () => {
        await UserService.initialize()
        var user = UserService.getMe()
        var org = UserService.getMyCurrentOrg()
        const proj = await (pullProjects(org, projectId))
        const files = await FileService.getFileInfos([proj?._id], true)
        setFiles(files)
        setUser(user)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, [projectId]);

    return (
        isLoading ?
            <PageWrapper className='justify-content-center text-center' title='Tasks'>
                <div className='justify-content-center text-center' title='Tasks'>
                    <Dots text={loadingMessage}></Dots>
                </div>
            </PageWrapper>
            :
            <PageWrapper>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                {
                                    title: 'Deals',
                                    to: '/deals',
                                },
                                {
                                    title: curProject?.title,
                                    to: `/dealdashboard/${curProject?._id}`,
                                },
                                {
                                    title: "Files",
                                    to: `/files/deal/${curProject?._id}`,
                                },
                            ]}
                        />
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <span>
                            Deal:
                        </span>
                        <div>
                            <Dropdown variant="success" id="dropdown-basic">
                                <DropdownToggle>
                                    <Button label={curProject.title} clickable  >{curProject.title}
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    {projects.map((p, ind) => (
                                        <DropdownItem key={`p-${ind}`} onClick={() => { navigateToProject(p) }}>
                                            {p.title}
                                        </DropdownItem>
                                    ))}
                                    <DropdownItem onClick={() => { setIsNewProjectModalOpen(true) }}>
                                        +Add New Deal
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </SubHeaderRight>
                </SubHeader>
                <Page>
                    <FileList curProject={curProject} list={files} updateFile={updateFile} refreshData={refreshData} />
                </Page>
                <NewProjectModal
                    setIsOpen={setIsNewProjectModalOpen}
                    isOpen={isNewProjectModalOpen}
                />
            </PageWrapper>
    )
}