import React, { useState } from 'react'
import DelphiLogger from '../../services/DelphiLogger'

export const AddWorkStreamRow = ({ addNewWorkstream }) => {

    const [newTaskTitle, setNewTaskTitle] = useState("")

    return (
        <div className="">
            <input
                className='addNewTaskBox'
                onChange={e => setNewTaskTitle(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        setNewTaskTitle("")
                        addNewWorkstream({ title: e.target.value })
                    }
                }}
                value={newTaskTitle}
                placeholder="+Add New Workstream"
                type="text"
            />
        </div>
    )
}
