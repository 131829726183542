import { resolveHookState } from 'react-use/lib/misc/hookState';
import DelphiLogger from '../services/DelphiLogger';
import Auth0ClientService from './Auth0ClientService';
import DelphiConfigProvider from './DelphiConfigProvider';
import { v4 as uuidv4 } from 'uuid';

class TaskService {

    static taskSaveQueue = [];
    static workstreamSaveQueue = [];

    static async getTasks(taskIds) {

        var taskIdStr = taskIds.join(',')
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/tasks/${taskIdStr}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static {
        const interval = setInterval(function () {
            // method to be executed;
            TaskService.saveTaskQueue()
            TaskService.saveWorkstreamQueue()
        }, 2000);
    }

    static saveTaskQueue() {

        if (TaskService.taskSaveQueue.length > 0) {

            var saveQ = TaskService.taskSaveQueue.map(({ history, ...keepAttrs }) => keepAttrs)
            TaskService.taskSaveQueue = []
            var l = saveQ.length

            return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
                .then((accessToken) => {

                    const requestOptions = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        },
                        body: JSON.stringify(saveQ)
                    };

                    return fetch(`${DelphiConfigProvider.config.apiEndpoint}/tasks/`, requestOptions)
                })
                .then(() => {
                    // Return the number of tasks saved
                    DelphiLogger.logMessage(
                        'Tasks saved successfully',
                        `+${l}`,
                    )
                })
                .then(() => {
                    TaskService.taskSaveQueue = []
                })
                .catch((error) => {
                    DelphiLogger.logError(
                        `Task save failed`,
                        `Task save failed ${error}`,
                    )
                })
        }
    }

    static saveWorkstreamQueue() {

        if (TaskService.workstreamSaveQueue.length > 0) {
            var l = TaskService.workstreamSaveQueue.length

            return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
                .then((accessToken) => {

                    const requestOptions = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        },
                        body: JSON.stringify(TaskService.workstreamSaveQueue)
                    };

                    return fetch(`${DelphiConfigProvider.config.apiEndpoint}/workstreams/`, requestOptions)
                })
                .then(() => {
                    // Return the number of tasks saved
                    TaskService.workstreamSaveQueue = []
                    DelphiLogger.logMessage(
                        'Workstreams saved successfully',
                        `+${l}`,
                    )
                })
                .catch((error) => {
                    DelphiLogger.logError(
                        `Workstream save failed`,
                        `Workstream save failed ${error}`,
                    )
                })
        }
    }

    static async getMyWorkFlowTasks() {

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/tasks/all/myTasks`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ws = await resp.json()
        const ret = ws.map((w, i) => ({ _id: i, ...w, index: i }))
        return ret
    }

    static async getMyTasks(projectIds = null, workFlowStyle = false) {

        if (workFlowStyle) {
            return (await TaskService.getMyWorkFlowTasks())
        }
        else {
            var user = await Auth0ClientService.getUser()
            var tasks = await (TaskService.getUserTasks(user.sub, projectIds))
            return tasks
        }
    }

    static getUserTasks(userId, projectIds = null) {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                }
                var url = `${DelphiConfigProvider.config.apiEndpoint}/user/${encodeURIComponent(userId)}/tasks/`

                if (projectIds?.length > 0) {
                    var projectIdsStr = projectIds.join(',')
                    url = `${DelphiConfigProvider.config.apiEndpoint}/user/${encodeURIComponent(userId)}/projectTasks/${projectIdsStr}`
                }

                return fetch(url, requestOptions)
            })
            .then((resp) => {
                // Return the number of tasks saved
                return resp.json()
            })
            .then((l) => {
                DelphiLogger.logMessage(
                    'User tasks retrieved successfully',
                    `+${l?.length}`,
                )

                return l
            })
            .catch((error) => {
                DelphiLogger.logError(
                    `User tasks retrieval failed`,
                    `User tasks retrieval failed ${error}`,
                )
            })
    }

    static getProjectWorkflowTasks(projectId, workflowId = null) {

        var workflowIdAppend = ''
        if (workflowId != null) {
            workflowIdAppend = `/${encodeURIComponent(workflowId)}`
        }

        const url = `${DelphiConfigProvider.config.apiEndpoint}/projects/${encodeURIComponent(projectId)}/workflowTasks`

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                }


                return fetch(url, requestOptions)
            })
            .then((resp) => {
                // Return the number of tasks saved
                DelphiLogger.logMessage(
                    'Tasks retrieved successfully',
                )
                return (resp)
            })
            .catch((error) => {
                DelphiLogger.logError(
                    `Task retrieve failed`,
                    `Task retrieve failed ${error}`,
                )
            })
    }

    static putTask(task) {

        const uid = uuidv4()
        if (!task?._id) {
            task._id = uid
        }
        TaskService.taskSaveQueue.push(task);
    }

    static putTasks(tasks) {
        tasks.map(
            t => {
                TaskService.putTask(t)
            }
        )
    }

    static putWorkstream(workstream) {

        TaskService.workstreamSaveQueue.push(workstream);
    }

    static putWorkstreams(workstreams) {

        workstreams.map(x => TaskService.workstreamSaveQueue.push(x))
    }

    static deleteTasks(tasks) {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'Delete',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(tasks)
                };

                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/tasks/`, requestOptions);
            })
    }
}

export default TaskService;