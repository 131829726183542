import Auth0ClientService from './Auth0ClientService';
import DelphiConfigProvider from './DelphiConfigProvider';
import DelphiLogger from './DelphiLogger';

class PhaseService {

    static phaseListPromise = null;
    static phaseList = [];
    static phaseDict = {};

    static setPhaseProperties(phaseList) {


        // PhaseService.phaseList = phaseList

        // for (var phase of phaseList) {
        //     PhaseService.phaseDict[phase._id] = phase
        // }
    }

    static async getPhaseDict() {

        // var phaseOptionList = await PhaseService.getPossiblePhases()
        // const phaseDict = phaseOptionList.reduce((d, phase) => {
        //     const projectTypes = phase.projectTypes

        //     if (projectTypes) {
        //         for (var projectType of projectTypes) {
        //             d[projectType] = d[projectType] || []
        //             d[projectType].push(phase);
        //         }
        //     }

        //     return d
        // }, {})

        // return phaseDict
    }

    static async getPossiblePhases(projectType = null) {



        // try {
        //     const projectFilter = projectType ? `/${projectType}` : ``
        //     const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        //     const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/phases${projectFilter}`, {
        //         method: 'GET',
        //         headers: {
        //             Authorization: `Bearer ${accessToken}`
        //         }
        //     })

        //     const ret = await resp.json()

        //     DelphiLogger.logMessage(
        //         'Phases retrieved successfully',
        //         `+${ret?.length}`,
        //     )
        //     return ret
        // }
        // catch (e) {
        //     DelphiLogger.logMessage(
        //         'Phase retrieval failed',
        //         e.message,
        //     )
        // }
    }

    static resetPhaseList() {

        // console.log(`Resetting the auth0 client`)
        // PhaseService.phaseListPromise = Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
        //     .then((accessToken) => {
        //         const requestOptions = {
        //             method: 'GET',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 Authorization: `Bearer ${accessToken}`
        //             },
        //         }
        //         var url = `${DelphiConfigProvider.config.apiEndpoint}/phases`
        //         return fetch(url, requestOptions)
        //     })
        //     .then((resp) => {
        //         // Return the number of tasks saved
        //         return resp.json()
        //     })
        //     .then((l) => {

        //         PhaseService.setPhaseProperties(l)

        //         DelphiLogger.logMessage(
        //             'Phases retrieved successfully',
        //             `+${l?.length}`,
        //         )

        //         return l
        //     })
        //     .catch((error) => {
        //         DelphiLogger.logError(
        //             `Phase retrieval failed`,
        //             `Phase retrieval failed`
        //         )
        //     })
    }

    static async init() {
        PhaseService.resetPhaseList()
        await PhaseService.phaseListPromise
    }

    static getPhaseInfo(project) {

        var curPhaseIndex = project?.phases?.indexOf(project?.currentPhase)
        return {
            numPhases: project?.phases?.length ?? 0,
            currentPhase: {
                index: curPhaseIndex,
                title: project?.currentPhase,
            },
            phases: project?.phases?.map(x => ({title: x}))
        }

        var phaseInfo = {}
        var curPhaseId = project?.currentPhase
        var phaseIds = project?.phases

        if (curPhaseId && phaseIds) {
            var numPhases = phaseIds.length
            var curPhaseIndex = phaseIds.indexOf(curPhaseId)
            var currentPhase = project?.currentPhase // { ...PhaseService.getPhase(curPhaseId), index: curPhaseIndex }
            var phases = project.phases //.map((p, i) => ({ ...(PhaseService.getPhase(p)), index: i }))

            phaseInfo = {
                numPhases: numPhases,
                currentPhase: currentPhase,
                phases: phases
            }
        }

        return phaseInfo
    }

    static {
        this.init()
    }

    static getPhase(phaseId) {

        return {
            _id: phaseId,
            title: phaseId,
            description: '',
            projectTypes: ['sellSide']
        }

        var phase = null
        var retries = 3

        if (phaseId) {
            while (phase == null && retries-- > 0) {
                phase = PhaseService.phaseDict[phaseId]
                console.log(`getPhase attempt ${retries}: phase: ${phase}, Prom: ${PhaseService.phaseListPromise}`)
            }

        }

        return phase
    }

    static async addPhases(phases) {
        return
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const requestOptions = {
            method: 'PUT',
            Authorization: `Bearer ${accessToken}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(phases)
        };

        return fetch(`${DelphiConfigProvider.config.apiEndpoint}/phases`, requestOptions);

    }
}

export default PhaseService;