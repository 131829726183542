import React from 'react'
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Chip from '@mui/material/Chip';
import NumericInput from 'material-ui-numeric-input';

const CompletionTimeColumn = ({ task, updateTask, workstreamIndex, taskIndex, dropDownOptions }) => {

    return (
        <div>
            <NumericInput
                value={task.duration}
                precision={0}
                decimalChar='.'
                thousandChar=','
                name='duration'
                onBlur={(event) => updateTask({ ...task, duration: parseInt(event.target.value) }, workstreamIndex, taskIndex)}
            />
        </div>
    )
}

const CompletionTimeHeader = ({ phrase = 'Time Remaining' }) => {
    return (
        <th scope="col">{phrase} (H)</th>
    )
}


export { CompletionTimeColumn, CompletionTimeHeader }