import React from 'react'
import Icon from '../../components/icon/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { faStar, faClock, faPlayCircle, faCheckCircle, faBan, faCircleStop, faInfo, faInfoCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProgressBar from 'react-bootstrap/ProgressBar'

export const ProjectCompletionBanner = ({ projectCompletionStats }) => {
  const navigate = useNavigate();

  var layoutMap = {
    "New": {
      color: 'grey',
      icon: faStar,
    },
    "Queued": {
      color: 'info',
      icon: faClock,
    },
    "Active": {
      color: 'warning',
      icon: faPlayCircle,
    },
    "Complete": {
      color: 'success',
      icon: faCheckCircle,
    },
    "Blocked": {
      color: 'danger',
      icon: faCircleStop,
    },

  }

  const isNullOrWhiteSpace = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  }

  var percentComplete = Math.round(projectCompletionStats.statusBreakDown['Complete'] ? projectCompletionStats.statusBreakDown['Complete'] * 100 / projectCompletionStats.totalTasks : 0)

  return (
    <>
      <Icon icon="Files" />
      <div
        className='stick int cursor-pointer text-decoration-underline'
        onClick={() => navigate(`/dealdashboard/${projectCompletionStats?.project?._id}`)} >
        {projectCompletionStats.ProjectName} | {projectCompletionStats.totalTasks} Tasks</div>

      {
        projectCompletionStats.totalTasks > 0 ?
          <>
            <span className='text-muted'>
              {
                Object.keys(projectCompletionStats.statusBreakDown).map((status, index) => {
                  var layout = layoutMap[status]
                  var count = projectCompletionStats.statusBreakDown[status]

                  return (
                    <>
                      <span style={{ color: layout?.color ?? "grey" }}>
                        <FontAwesomeIcon icon={layout?.icon ?? faQuestionCircle} color={layout?.color ?? "grey"} className={'mx-1 ' + `text-${layout?.color ?? "grey"}`} size='lg' />
                      </span>
                      {count} {isNullOrWhiteSpace(status) ? 'No state' : status}
                    </>
                  )
                })
              }
            </span>
            <span>
              <div >
                <ProgressBar variant='success' now={percentComplete} style={{ width: "300px" }} label={`${percentComplete}% Complete`}>
                  {/* {
                    TODO: Get bar to render correctly
                    Object.keys(projectCompletionStats.statusBreakDown).map((status, index) => {
                      var layout = layoutMap[status]
                      var count = projectCompletionStats.statusBreakDown[status]
                      var percentComplete = Math.round(count * 100 / projectCompletionStats.totalTasks)
                      
                      return (
                        <>
                          <ProgressBar variant={layout.color} now={percentComplete} key={index} label={`${status}`} />
                        </>
                      )
                    })
                  } */}
                </ProgressBar>
              </div>
            </span>
          </>
          : <></>
      }
    </>
  )
}
