import React from 'react'
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import OffCanvas, {
    OffCanvasBody,
    OffCanvasHeader,
    OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Checks from '../../../components/bootstrap/forms/Checks';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import PhaseService from '../../../services/PhaseService';
import Select from '../../../components/bootstrap/forms/Select';


export const NewWorkstreamModal = ({ curProject, setIsOpen, isOpen, addNewWorkstream }) => {

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            phase: curProject?.currentPhase,
            tasks: []
        },
        onSubmit: values => {
            addNewWorkstream(
                values
            )
            setIsOpen(false)
        }
    });


    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>Add Workstream</OffCanvasTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='title' label='Title' isFloating>
                                <Input
                                    placeholder='Title'
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='description' label='Description' isFloating>
                                <Textarea
                                    rows={4}
                                    placeholder='Description'
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='bg-transparent'>
                    <Button
                        type='submit'>
                        Add
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}
