import { useFormik } from 'formik';
import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Wizard, { WizardItem } from '../../../components/Wizard';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../../services/DelphiLogger';
import ProjectService from '../../../services/ProjectService';
import { BuyerTrackingInformation } from './WizardSteps/BuyerTrackingInformation';
import { BuyerTrackingRowSelect } from './WizardSteps/BuyerTrackingRowSelect';
import { TrackerMetadata } from './WizardSteps/TrackerMetadata';
import { TrackerPreview } from './WizardSteps/TrackerPreview';
import TrackerService from '../../../services/TrackerService';
import { v4 as uuidv4 } from 'uuid';
import TemplateService from '../../../services/TemplateService';
import TaskService from '../../../services/TaskService';
import UserService from '../../../services/UserService';
import * as Yup from 'yup';
import { BuyerListImport } from '../../../components/BuyerList/BuyerListImport';
import { WizardGenerator } from '../../../components/WizardGenerator/WizardGenerator';


export const NewTrackerPage = () => {
    const { projectId } = useParams();

    const [user, setUser] = useState(null)
    const [projects, setProjects] = useState([])
    const [wizardConfig, setWizardConfig] = useState({})
    const [trackerTemplateOptions, setTrackerTemplateOptions] = useState([])
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Loading")

    const onSubmit = async (formData, trackerTemplateOptions) => {

        setIsLoading(true)
        var project = projectId ? {_id: projectId} : formData.project
        const buyerslist = formData.buyerslist

        if (!project?._id) {
            setLoadingMessage(`Creating a new project ${formData.project?.label}`)
            project = {
                title: formData.project?.label,
                description: formData.description,
                asset: {},
                phases: ["Planning"],
                currentPhase: "Planning"
            }
            await (ProjectService.saveProject([project]))
        }

        setLoadingMessage("Saving Buyers List")
        const l =
        {
            // TODO: get list type from modal
            type: "buyer",
            title: "Buyers List",
            items: buyerslist
        }
        await ProjectService.saveLists(project, [l])
        const [createdList] = await ProjectService.getLists(project, l?._id)

        setLoadingMessage("Creating tracker")
        const tracker = {
            title: `${formData.project?.label} Tracker`,
            list: l?._id,
            project: project?._id,
            trackerType: formData?.trackerType ?? 'buyer',
        }

        await TrackerService.saveTrackers([tracker])

        setLoadingMessage("Saving new tracker rows from buyers list")
        const trackerRows = createdList?.listItems?.map((item, i) => ({
            trackerIndex: i,
            tracker: tracker._id,
            listItem: item._id,
            title: item["Fund Name"],
            status: "Active",
        })) ?? []

        await TrackerService.saveTrackerRows(tracker._id, trackerRows, true)

        setLoadingMessage("Creating Tasks From Tracker Template")
        if (formData?.template) {
            const template = trackerTemplateOptions?.find(x => x?._id == formData?.template)
            if (template) {
                template?.workStreams?.map(t => {
                    const ws = {}
                    ws.template = ws?._id
                    ws._id = uuidv4()
                    ws.title = t?.title
                    ws.type = t?.type
                    ws.tracker = tracker._id
                    ws.trackerIndex = t.trackerIndex
                    ws.project = project?._id
                    ws.status = "New"
                    ws.phase = t?.phase
                    TaskService.putWorkstream(ws)
                })
            }
        }
        TaskService.saveWorkstreamQueue()
        await new Promise(resolve => setTimeout(resolve, 1000));

        setLoadingMessage("Navigating to tracker")
        navigate(`/trackerBoard/${tracker._id}`)
        setIsLoading(false)
    }

    const refreshData = async () => {
        await UserService.initialize()
        var user = UserService.getMe()
        const curOrg = UserService.getMyCurrentOrg()
        const projects = await ProjectService.getProjects(curOrg?.id)

        const res = await TemplateService.getTrackerTemplates()
        const options = res?.map(o => ({ ...o, value: o?._id, label: `${o?.title} (${o?.workStreams?.length} tasks)` })) ?? []
        setTrackerTemplateOptions(options)

        var startObjs = projectId ? [] : [
            {
                createLabel: "Create Project",
                id: "project",
                title: "Project (Select or Create)",
                type: "create-select",
                validate: Yup.object().required("Project is required. You can choose to create a new one from the dropdown if needed"),
                options: projects?.map(p => ({ ...p, value: p?._id, label: p?.title })),
                init: projects?.length > 0 ? { value: projects[0]?._id, label: projects[0]?.title } : null
            }
        ]

        setWizardConfig({
            onSubmit: async (formData) => await onSubmit(formData, options),
            icon: "Bullseye",
            steps: [
                {
                    cards: [
                        {
                            title: "Add Tracker Info",
                            fields: [
                                ...startObjs,
                                {
                                    id: "template",
                                    title: "Tracker Template",
                                    type: "select",
                                    options: options,
                                },
                                {
                                    validate: Yup.string(),
                                    id: "description",
                                    title: "Description",
                                    type: "textarea",
                                }
                            ]
                        },
                        {
                            title: "Upload Buyers List",
                            fields: [
                                {
                                    id: "buyerslist",
                                    title: "Buyers List",
                                    validate: Yup.array().min(1, "You must have at least one buyer").required("You must have at least one buyer"),
                                    render: (formik) => {
                                        return <BuyerListImport
                                            buyersList={formik.values?.buyerslist}
                                            updateBuyersList={(l) => {
                                                formik.setFieldValue("buyerslist", l)
                                            }}
                                        />
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        })

        setProjects(projects)
        setUser(user)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, []);

    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            :
            <PageWrapper title="New Tracker">
                <WizardGenerator wizardObject={wizardConfig} />
            </PageWrapper>
    )
}
