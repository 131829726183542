import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0, } from "@auth0/auth0-react";
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Auth0ClientService from '../../services/Auth0ClientService';
import { Avatar } from '@mui/material';
import { Dots } from 'loading-animations-react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { useFormik, ErrorMessage } from 'formik';
import Wizard, { WizardItem } from '../../components/Wizard';
import { PersonalInfoCard } from './personalinfo/PersonalInfoCard';
import validate from './personalinfo/PersonalInfoValidate';
import DelphiLogger from '../../services/DelphiLogger';
import Icon from '../../components/icon/Icon';
import Card, {
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardFooterRight,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../components/bootstrap/Card';
import User1Webp from '../../assets/img/wanna/wanna2.webp';
import User1Img from '../../assets/img/wanna/wanna2.png';
import Input from '../../components/bootstrap/forms/Input';
import { AddressCard } from './personalinfo/AddressCard';
import { OrganizationSelect } from './organization/OrganizationSelect';
import UserService from '../../services/UserService';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCircleStop } from '@fortawesome/free-regular-svg-icons';

export const Onboard = () => {

    const { user, logout } = useAuth0();
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const [currentUser, setCurrentUser] = useState(null);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const styledBtn = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };

    const formik = useFormik({
        initialValues: {
            given_name: currentUser?.given_name,
            picture: currentUser?.picture,
            family_name: currentUser?.family_name,
            email: currentUser?.email,
            user_metadata: {
                addressLine: currentUser?.user_metadata?.addressLine,
                addressLine2: currentUser?.user_metadata?.addressLine2,
                city: currentUser?.user_metadata?.city,
                state: currentUser?.user_metadata?.state,
                zip: currentUser?.user_metadata?.zip,
                position: currentUser?.user_metadata?.position,
                phone_number: currentUser?.user_metadata?.phone_number,
            },
            organization: currentOrg,
        },
        validate,
        onSubmit: async (formData) => {
            try {
                setIsSubmitting(true)
                await UserService.submitUserProfile(formData)

                DelphiLogger.logMessage(
                    <span className='d-flex align-items-center'>
                        <Icon icon='Info' size='lg' className='me-1' />
                        <span>Updated Successfully {formData.given_name}</span>
                    </span>,
                    "The user's account details have been successfully updated.",
                );

                UserService.prom = null
                await UserService.initialize()

                const link = `/`
                navigate(link);
                setIsSubmitting(false)

            }
            catch (err) {
                setIsSubmitting(false)
                // Return the number of tasks saved
                DelphiLogger.logError(
                    'Submission failed',
                    'Submission failed'
                )
            }
        },
    });

    useEffect(async () => {
        await UserService.initialize()
        var currentOrg = UserService.getMyCurrentOrg()
        var currentUser = UserService.getMe()
        console.debug(currentUser)
        setCurrentUser(currentUser)
        setCurrentOrg(currentOrg)
        formik.setValues({
            given_name: currentUser?.given_name,
            family_name: currentUser?.family_name,
            email: currentUser?.email,
            user_metadata: {
                addressLine: currentUser?.user_metadata?.addressLine,
                addressLine2: currentUser?.user_metadata?.addressLine2,
                city: currentUser?.user_metadata?.city,
                state: currentUser?.user_metadata?.state,
                zip: currentUser?.user_metadata?.zip,
                position: currentUser?.user_metadata?.position,
                phone_number: currentUser?.user_metadata?.phone_number,
            },
            organization: currentOrg,
        })
        setIsLoading(false)
    }, []);

    return (
        isLoading || isSubmitting ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            :
            <PageWrapper title="Welcome To FinSpace">

                <Wizard
                    isHeader
                    stretch
                    color='info'
                    noValidate
                    onSubmit={formik.handleSubmit}
                    className='shadow-3d-info'>
                    <WizardItem id='step1' title='User Info'>
                        <PersonalInfoCard formik={formik} />
                        {Object.keys(formik.errors)?.length > 0 && <Card className="mt-3 mb-0">
                            <CardBody>
                                {
                                    Object.keys(formik.errors).map((key) => {
                                        return <div className='row'><div className='col'><span className="text-danger"><FontAwesomeIcon icon={faCircleStop}></FontAwesomeIcon>{formik.errors[key]}</span></div> </div>
                                    })
                                }
                            </CardBody>
                        </Card>}
                    </WizardItem>
                </Wizard>
            </PageWrapper>
    )
}
