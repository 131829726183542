import React, { useEffect, useState } from 'react';
import { useAuth0, } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OffCanvas, { OffCanvasBody } from '../../components/bootstrap/OffCanvas';
import Avatar, { AvatarGroup } from '../../components/Avatar';
import DatePicker from "react-datepicker";
import USERS from '../../common/data/userDummyData';
import Select from 'react-select';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import CreatableSelect from 'react-select/creatable';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../components/bootstrap/Card';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave, faClone, faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import './TaskPanel.css'
import { AddComment } from '../../components/icon/material-icons';
import { Dots } from 'loading-animations-react';

import { TitleH1 } from './columns/TitleColumn'
import { StatusColumn } from './columns/StatusColumn'
import { PriorityColumn } from './columns/PriorityColumn'
import { DueDateColumn } from './columns/DueDateColumn'
import { TagColumn } from './columns/TagColumn'
import { AssigneesColumn } from './columns/AssigneesColumn';
import UserService from '../../services/UserService';
import Chat, { ChatAvatar, ChatGroup, EmailChatGroup } from '../../components/Chat';
import InputGroup from '../../components/bootstrap/forms/InputGroup';
import Textarea from '../../components/bootstrap/forms/Textarea';
import { FileInput } from '../../components/bootstrap/forms/FileInput';
import TaskService from '../../services/TaskService';
import TagService from '../../services/TagService';
import DelphiLogger from '../../services/DelphiLogger';
import FileService from '../../services/FileService';
import HistoryService from '../../services/HistoryService';

const SimpleTaskPanel = ({ isSimpleTaskPanelOpen, setIsSimpleTaskPanelOpen, taskId, projectId }) => {

    const [task, setTask] = useState({})
    const [orgUsers, setOrgUsers] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const updateOrgUsers = () => {
        var orgUsers = UserService.getOrgUsers()
        return Promise.all(
            orgUsers.map(async (user) => {
                return {
                    ...user,
                    value: user.user_id,
                    label: <div style={{ color: "black" }}><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
                }
            })).then((orgUserOptions) => {
                setOrgUsers(orgUserOptions)
            })
    }

    const updateTask = (newTask) => {
        TaskService.putTask(newTask)
        setTask(newTask)
    }

    // Add a comment to a task
    const addComment = async (task, comment, filesToUpload) => {

        const commentObj = {
            body: comment,
            author: user.sub,
            type: "comment",
            updatedDate: (new Date()).toISOString(),
            createdDate: (new Date()).toISOString(),
            state: "created"
        }

        var filesCreatedIds = []
        if (filesToUpload.length > 0) {
            const filesCreated = await FileService.uploadFiles(filesToUpload, { _id: task?.project })
            filesCreatedIds = filesCreated.map(f => f._id)
            commentObj.files = filesCreatedIds
            commentObj._displayFiles = filesCreated
        }

        HistoryService.putHistory(task?._id, [commentObj]).catch((error) => {
            DelphiLogger.logMessage(
                'Comment add FAILED',
                `Comment add FAILED ${error}`,
            )
        })

        const history = task.history ?? []
        updateTask({ ...task, history: [...history, commentObj], files: [...(task.files ?? []), ...filesCreatedIds] })
    }

    // When selecting or adding a tag
    const updateTaskTags = (
        newValue,
        actionMeta,
        task
    ) => {

        if (actionMeta.action == 'create-option') {
            const newVal = newValue[newValue.length - 1]

            // Only save a tag if it is not already an option
            if (!(tagOptions.some(x => x.value == newVal))) {
                const newTag = { ...newVal, _id: newVal.value }
                setTagOptions([...tagOptions, newTag])
                TagService.putTag(newTag)
            }
        }

        updateTask({ ...task, tags: newValue })
    };

    const pullTags = () => {
        const prom = TagService.getTags().then(
            async (resp) => {
                const tags = await resp.json()
                setTagOptions(tags)
            }
        ).catch(
            (error) => DelphiLogger.logMessage(
                `Tags retrieval failed ${error}`,
                "Tags retrieval failed",
            ))

        return prom
    }

    var dropDownOptions = {
        stateOptions: [
            "",
            "New",
            "Queued",
            "Active",
            "Complete",
            "Blocked"
        ],
        priorityOptions:
            [
                "",
                "Low",
                "Medium",
                "High"
            ],
        tagOptions: tagOptions,
        orgUserOptions: orgUsers
    }

    const refreshData = async () => {
        await UserService.initialize()
        updateOrgUsers()
        pullTags()
    }

    useEffect(async () => {
        await refreshData()
    }, [])


    const loadTask = async (taskId) => {
        const task = (await TaskService.getTasks([taskId]))?.find(x => x) ?? {}
        setTask(task)
    }

    useEffect(async () => {
        setIsLoading(true)
        await loadTask(taskId)
        setIsLoading(false)
    }, [taskId])


    const columnProps = {
        task: task,
        updateTask: updateTask,
        updateTaskTags: updateTaskTags,
        dropDownOptions: dropDownOptions
    }

    const { user } = useAuth0();

    const creatableStyle = {
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "15px",
                backgroundColor: "darkgrey"
            };
        },
    };

    const avatarImgStyle = {
        borderRadius: "50%",
        borderColor: "white",
        borderWidth: "80px"
    }

    const buttonStyle = {
        marginRight: "3px"
    }

    let priorityClass = ""

    switch (task?.priority) {
        case 'High':  // if (x === 'value1')
            priorityClass = "bg-danger"
            break;
        case 'Medium':  // if (x === 'value2')
            priorityClass = "bg-warning"
            break;
        case 'Low':  // if (x === 'value1')
            priorityClass = "bg-light bg-warning"
            break;
        default:
            priorityClass = ""
            break;
    }

    let statusClass = "task-complete bg-light"

    switch (task?.status) {
        case 'Complete':  // if (x === 'value1')
            statusClass = "bg-success"
            break;
        case 'Active':  // if (x === 'value2')
            statusClass = "bg-warning"
            break;
        case 'Cancelled':  // if (x === 'value1')
        case 'Blocked':  // if (x === 'value2')
            statusClass = "bg-danger"
            break;
        case 'New':  // if (x === 'value2')
        case 'Queued':  // if (x === 'value2')
            statusClass = "white"
            break;
        default:
            statusClass = null
            break;
    }

    const projects = [
        { label: "Operation merger", value: "Operation merger" },
        { label: "Finspace Acquisition", value: "Operation merger" }
    ]

    const workStreams = [
        { label: "Pre-work", value: "Pre-work" },
        { label: "Post-work", value: "Post-work" }
    ]

    return (
        <OffCanvas setOpen={setIsSimpleTaskPanelOpen} isOpen={isSimpleTaskPanelOpen} isRightPanel>
            <OffCanvasBody className='p-4'>

                {
                    isLoading ?
                        <div className="d-flex flex-column h-100">
                            <div className='justify-content-center text-center' title='Tasks'>
                                <Dots text="Loading"></Dots>
                            </div>
                        </div>
                        :

                        <div className="d-flex flex-column h-100">

                            <div className="task-detail-top flex-grow: 0">
                                <div className="container-fluid">
                                    <div className='row mb-5'>
                                        <div className='col'>
                                            <div className='d-flex align-items-center'>
                                                <StatusColumn {...columnProps} />
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            <CardActions>
                                                <Button className='bg-warning' style={buttonStyle}>
                                                    <FontAwesomeIcon icon={faClone} />
                                                </Button>
                                                <Button className='bg-danger' style={buttonStyle} onClick={() => {
                                                    updateTask({ ...task, toDelete: !task?.toDelete })
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </Button>
                                                <Button className='white' style={buttonStyle}
                                                    color='link'
                                                    icon='Close'
                                                    onClick={() => {
                                                        setIsSimpleTaskPanelOpen(false);
                                                    }}>
                                                </Button>
                                            </CardActions>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-left mb-3'>
                                        <TitleH1 {...columnProps} />
                                    </div>
                                    <div className='d-flex flex-column align-items-left mb-5'>
                                        <div>
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" style={{ bottom: 0, verticalAlign: "bottom" }}>Assigned To</th>
                                                        <td>
                                                            <AssigneesColumn {...columnProps} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Priority</th>
                                                        <td>
                                                            <PriorityColumn {...columnProps} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Due Date</th>
                                                        <td>
                                                            <DueDateColumn {...columnProps} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Tags</th>
                                                        <td>
                                                            <TagColumn {...columnProps} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="activitySection flex-grow-1" style={{ overflow: "auto" }}>
                                <div>
                                    <p>
                                        Description
                                    </p>
                                </div>
                                <div>
                                    <textarea rows="5" className="taskDesc" defaultValue={task?.description ?? ""} style={{ width: "90%" }}
                                        onChange={(e) => { task.description = e.target.value }}
                                        onBlur={(event) => {
                                            task.description = null
                                            updateTask({ ...task, description: event.target.value })
                                        }}></textarea>
                                </div>
                                <div style={{ width: "97%" }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="comment-widgets m-b-20">
                                                <Chat>
                                                    {
                                                        task?.history ?
                                                            task?.history.map((event, ind) => {
                                                                // TODO: Need to have other history besides comments

                                                                var author = UserService.getUser(event.author);

                                                                switch (event?.type) {
                                                                    case "comment":
                                                                        return (
                                                                            <ChatGroup
                                                                                key={ind}
                                                                                messages={[event]}
                                                                                user={author}
                                                                                isReply={event.author == user.sub}
                                                                            />
                                                                        )
                                                                        break;
                                                                    case "email":
                                                                        return (
                                                                            <EmailChatGroup
                                                                                key={ind}
                                                                                messages={[event]}
                                                                                user={author}
                                                                                isReply={event.author == user.sub}
                                                                            />
                                                                        )
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }
                                                            })
                                                            : <></>
                                                    }
                                                </Chat>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FileInput {...columnProps} onSubmit={async (textAreaContent, filesToUpload) => {
                                await addComment(task, textAreaContent, filesToUpload)
                            }} />
                        </div>
                }
            </OffCanvasBody>
        </OffCanvas>
    );
};
SimpleTaskPanel.propTypes = {
    setIsSimpleTaskPanelOpen: PropTypes.func.isRequired,
    isSimpleTaskPanelOpen: PropTypes.bool.isRequired,
};

export default SimpleTaskPanel;
