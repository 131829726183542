import React, { useState, useEffect } from 'react'
import { useAuth0, } from "@auth0/auth0-react";
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Auth0ClientService from '../../../services/Auth0ClientService';
import { Dots } from 'loading-animations-react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useFormik } from 'formik';
import Wizard, { WizardItem } from '../../../components/Wizard';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import DelphiLogger from '../../../services/DelphiLogger';
import Icon from '../../../components/icon/Icon';
import Card, {
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardFooterRight,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import User1Webp from '../../../assets/img/wanna/wanna2.webp';
import User1Img from '../../../assets/img/wanna/wanna2.png';
import Input from '../../../components/bootstrap/forms/Input';
import { useDropzone } from 'react-dropzone';
import Avatar from '../../../components/Avatar';
import UserService from '../../../services/UserService';
import Select from '../../../components/bootstrap/forms/Select';
import CreatableSelect from 'react-select/creatable';
import Label from '../../../components/bootstrap/forms/Label';

export const PersonalInfoCard = ({ formik }) => {

    const { user, logout } = useAuth0();
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [orgOptions, setOrgOptions] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: async acceptedFiles => {
            var file = acceptedFiles[0]
            setIsUploadingAvatar(true)
            const avatarUrl = await UserService.uploadAvatar(file)
            formik.setFieldValue('picture', avatarUrl)
            setIsUploadingAvatar(false)
        }
    });

    const styledBtn = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };

    const positions = [
        "Analyst",
        "Senior Analyst",
        "Associate",
        "Senior Associate",
        "Vice President",
        "Senior Vice President",
        "Director",
        "Associate Director",
        "Managing Director",
        "Partner",
        "Principal",
        "Intern"
    ]


    const companyType = [ // Make this creatable select
        "Investment Bank",
        "Private Equity Firm",
        "Lender",
        "Corporation",
        "Venture Capital",
        "Other"
    ]


    useEffect(async () => {

        var currentUser = await Auth0ClientService.getUser()
        var organizations = await UserService.getPossibleOrganizations()
        setOrgOptions(organizations)
        setCurrentUser(currentUser)
        setIsLoading(false)
    }, []);

    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <></>
                </div>
            </PageWrapper>
            :
            <>
                <Card>
                    <CardHeader>
                        <CardLabel icon='PersonLinesFill' iconColor='warning'>
                            <CardTitle>User Information</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody className='pt-0'>
                        <div className='row g-4'>
                            <div className='col-md-6'>
                                <FormGroup
                                    id='given_name'
                                    label='First Name'
                                    isFloating>
                                    <Input
                                        placeholder='First Name'
                                        autoComplete='additional-name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values["given_name"]}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched["given_name"]}
                                        invalidFeedback={
                                            formik.errors["given_name"]
                                        }
                                        validFeedback='Looks good!'
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-6'>
                                <FormGroup
                                    id='family_name'
                                    label='Last Name'
                                    isFloating>
                                    <Input
                                        placeholder='Last Name'
                                        autoComplete='family-name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.family_name}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.family_name}
                                        invalidFeedback={formik.errors.family_name}
                                        validFeedback='Looks good!'
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-lg-12'>
                                <FormGroup
                                    id='user_metadata.position'
                                    label='Position'
                                    isFloating>
                                    <Select
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.user_metadata.position}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched?.user_metadata?.position || formik.touched?.position}
                                        invalidFeedback={formik.errors.position}
                                        validFeedback='Looks good!'
                                    >
                                        {
                                            positions.map((position, i) => (
                                                <option value={position}>{position}</option>
                                            ))
                                        }
                                    </Select>
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <CardLabel icon='Building' iconColor='success'>
                            <CardTitle>Organization Information</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4 align-items-center'>
                            <div className='col-md-7'>
                                <Label htmlFor='CC'>Choose Org (Select Create if Adding a New Org)</Label>
                                <FormGroup
                                    id='organization'
                                    isFloating>
                                    <CreatableSelect
                                        // styles={creatableStyle}
                                        options={orgOptions?.map(org => ({ ...org, value: org?.id, label: org?.display_name }))}
                                        minMenuHeight={10}
                                        onChange={(newValue, actionMetaData) => formik.setFieldValue("organization", newValue)}
                                        form="false"
                                        value={{ ...formik.values.organization, value: formik?.values?.organization?.id ?? formik?.values?.organization?.value, label: formik?.values?.organization?.display_name ?? formik?.values?.organization?.label }}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-7' style={{ "display": formik?.values?.organization?.id ? "none" : "block" }}>
                                <Label htmlFor='CC'>Organization Type</Label>
                                <FormGroup
                                    id='organization.metadata.type'
                                    isFloating>
                                    <CreatableSelect
                                        // styles={creatableStyle}
                                        options={companyType?.map(t => ({ value: t, label: t }))}
                                        minMenuHeight={10}
                                        onChange={(newValue, actionMetaData) => formik.setFieldValue("organization", { ...formik?.values?.organization, metadata: { ...formik?.values?.organization?.metadata, type: newValue?.value } })}
                                        form="false"
                                        value={{ ...formik.values.organization?.metadata?.type, value: formik.values.organization?.metadata?.type, label: formik.values.organization?.metadata?.type }}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className='row g-4 align-items-center'>
                            <div className='col-xl-auto'>
                                {
                                    isUploadingAvatar ?
                                        <></>
                                        :
                                        <Avatar srcSet={formik.values?.picture ?? currentUser?.picture ?? User1Webp}
                                            src={formik.values?.picture ?? currentUser?.picture ?? User1Img} size={100} />
                                }
                            </div>
                            <div className='col-xl'>
                                <div className='row g-4'>
                                    <div {...getRootProps({ className: 'dropzone' })} className='col-auto'>
                                        <input {...getInputProps()} />
                                        <Button
                                            color='dark'
                                            isLight
                                        >
                                            Upload Profile Picture
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <p className='lead text-muted'>
                                            Your profile picture helps your teammates and colleagues get to
                                            know you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card className='mb-0'>
                    <CardHeader>
                        <CardLabel icon='MarkunreadMailbox' iconColor='warning'>
                            <CardTitle>Contact Information</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody className='pt-0'>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup
                                    id='user_metadata.phone_number'
                                    label='Phone Number'
                                    isFloating>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.user_metadata.phone_number}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched?.user_metadata?.phone_number || formik.touched?.phone_number}
                                        invalidFeedback={formik.errors.phone_number}
                                        validFeedback='Looks good!'
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup
                                    id='email'
                                    label='Email address'
                                    isFloating>
                                    <Input
                                        disabled={true}
                                        type='email'
                                        placeholder='Email address'
                                        autoComplete='email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.email}
                                        invalidFeedback={
                                            formik.errors.email
                                        }
                                        validFeedback='Looks good!'
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </>
    )
}
