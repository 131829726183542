import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import DelphiLogger from '../../../../../services/DelphiLogger';
import ProjectService from '../../../../../services/ProjectService';
import Modal, {
    ModalBody, ModalFooter, ModalHeader, ModalTitle
} from '../../../../../components/bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../../../../components/bootstrap/OffCanvas';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../../components/bootstrap/Card';
import UserService from '../../../../../services/UserService';
import { FormGroup } from '@mui/material';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import Button from '../../../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../../../../../components/bootstrap/forms/Input';

export const PhaseEditModal = ({ isPhaseEditModalOpen, setIsPhaseEditModalOpen, phases, phaseIndex, goToPhase, updatePhases, curProject, updateProject }) => {

    const [user, setUser] = useState(null)
    const [title, setTitle] = useState("")
    const [isCurPhase, setIsCurPhase] = useState(false)

    useEffect(() => {
        const title = (!phases || phaseIndex >= phases?.length) ? "" : phases[phaseIndex]
        setTitle(title)
        setIsCurPhase(curProject?.currentPhase == title)
    }, [phases, phaseIndex])

    const _updatePhases = (go = false) => {
        const newPhases = [...phases]
        if (phaseIndex >= phases?.length) {
            newPhases = [...phases, title]
        }
        else {
            newPhases[phaseIndex] = title
        }

        if (isCurPhase || go) {
            updateProject({ ...curProject, currentPhase: title, phases: newPhases })
        }
        else {
            updatePhases(newPhases)
        }
    }


    return (
        <Modal
            setIsOpen={setIsPhaseEditModalOpen}
            isOpen={isPhaseEditModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsPhaseEditModalOpen}>
                <OffCanvasTitle id='upcomingEdit'>Edit Phase</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                {
                    <div className='row g-4'>
                        <div className='col-md-12'>
                            <Input
                                placeholder='Phase Name'
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />
                        </div>
                    </div>
                }
            </ModalBody>
            <ModalFooter className='bg-transparent'>
                <Button
                    color="success"
                    onClick={async () => {
                        _updatePhases(true)
                        setIsPhaseEditModalOpen(false)
                    }}
                >
                    Set As Current Phase
                </Button>
                <Button
                    color="success"
                    onClick={async () => {
                        _updatePhases()
                        setIsPhaseEditModalOpen(false)
                    }}
                >
                    Save Phase
                </Button>
            </ModalFooter>
        </Modal>
    )
}