import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import DelphiLogger from '../../services/DelphiLogger';
import ProjectService from '../../services/ProjectService';
import Button from '../bootstrap/Button';
import Modal, {
    ModalBody, ModalFooter, ModalHeader, ModalTitle
} from '../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../bootstrap/OffCanvas';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../bootstrap/Card';
import UserService from '../../services/UserService';
import { NewEmailOutreach } from './NewEmailOutreach';

export const NewEmailOutreachModal = ({ isNewEmailOutreachModalOpen, setIsNewEmailOutreachModalOpen, curProject, callback }) => {

    const projectId = curProject?._id
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true);

    const refreshData = async () => {
        await UserService.initialize()
        var user = UserService.getMe()
        setUser(user)
    }

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, [curProject]);

    return (
        <Modal
            fullScreen={true}
            setIsOpen={setIsNewEmailOutreachModalOpen}
            isOpen={isNewEmailOutreachModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsNewEmailOutreachModalOpen}>
                <OffCanvasTitle id='upcomingEdit'>New Email Outreach</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                {
                    isLoading ?
                        <></>
                        : <NewEmailOutreach />
                }
            </ModalBody>
        </Modal>
    )
}