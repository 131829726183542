import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';
// import FormGroup from './bootstrap/forms/FormGroup';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserService from '../../services/UserService';
import Avatar from '../Avatar';
import DelphiLogger from '../../services/DelphiLogger';

export const AvatarGroupMemberList = ({ members, updateMembers }) => {

    const isEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const [orgUsers, setOrgUsers] = useState([]);

    const _updateMembers = async (newValue) => {

        if (!newValue.user_id) {
            if (!isEmail(newValue.label)) {
                DelphiLogger.logError(`Invitee must be an email address`)
                return
            }
            else {
                newValue = await UserService.getOrCreateUser(newValue.label)
                DelphiLogger.logError(`Invite sent to ${newValue.email}`)
            }
        }

        const newMembers = [...new Set([...(members ?? []), newValue.user_id])]
        updateMembers(newMembers)
    }

    const updateOrgUsers = async () => {
        var orgUsers = UserService.getOrgUsers()
        await Promise.all(
            orgUsers?.map(async (user) => {
                return {
                    ...user,
                    value: user.user_id,
                    label: <div style={{ color: "black" }}><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
                }
            })).then((orgUserOptions) => {
                setOrgUsers(orgUserOptions)
            })
    }

    useEffect(async () => {
        await updateOrgUsers()

    }, []);

    var filter = {
        // default filter configuration
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.data.name} ${option.data.email}`,
        trim: true,
    }


    return (
        <>
            <table class="table">
                <thead>
                    <tr>
                        <th style={{ width: "10%" }} scope="col"></th>
                        <th style={{ width: "7%" }} scope="col"></th>
                        <th style={{ width: "35%" }} scope="col">Name</th>
                        <th style={{ width: "35%" }} scope="col">Email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (members ?? []).map((member, i) => {
                            var resolvedMember = UserService.getUser(member)
                            return (
                                <tr>
                                    <td >
                                        {
                                            updateMembers && <div>
                                                <FontAwesomeIcon className='deleteIcon' size='' icon={faXmarkCircle} onClick={() => { members.splice(i, 1); updateMembers(members) }} />
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <Avatar
                                            size="30px"
                                            src={resolvedMember?.picture}
                                            srcSet={resolvedMember?.picture}
                                            userName={resolvedMember?.name}
                                        />
                                    </td>
                                    <td>{resolvedMember?.name}</td>
                                    <td>
                                        {resolvedMember?.email}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                    </tr>
                </tbody>
            </table>
            {
                updateMembers && <div style={{ width: "50%", paddingBottom: "70px" }}>
                    <CreatableSelect className="tagSelect"
                        placeholder="+Add Member"
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        options={orgUsers}
                        minMenuHeight={10}
                        onChange={async (newValue, actionMetaData) => await _updateMembers(newValue)}
                        form="false"
                        value={null}
                        filterOption={createFilter(filter)}
                        isClearable={true}
                        formatCreateLabel={inputValue => `Invite '${inputValue}' by email`}
                    />
                </div>
            }
        </>
    )
}
