import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Icon from '../../components/icon/Icon';
import Page from '../../layout/Page/Page';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import USERS from '../../common/data/userDummyData';
import Badge from '../../components/bootstrap/Badge';
import Button from '../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Label from '../../components/bootstrap/forms/Label';
import Input from '../../components/bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import SERVICES from '../../common/data/serviceDummyData';
import { demoPages } from '../../menu';
import useTourStep from '../../hooks/useTourStep';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import { NewProjectModal } from '../projecttasks/modals/NewProjectModal/NewProjectModal';
import { Dots } from 'loading-animations-react';
import { ProjectItem } from './ProjectItem';
import useDarkMode from '../../hooks/useDarkMode';
import AvatarTeam from '../../components/common/AvatarTeam';
import Avatar, { AvatarGroup } from '../../components/Avatar';
import './ProjectsDashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown, faCaretSquareRight } from '@fortawesome/free-regular-svg-icons';
import { NewTrackerModal } from '../../components/NewTrackerModal/NewTrackerModal';
import { ProjectsGrid } from './ProjectsGrid';
import { ProjectsList } from './ProjectsList';
import PhaseService from '../../services/PhaseService';

const ProjectsDashboard = () => {
	const location = useLocation();
	const isListView = location.pathname?.includes("/list");

	useTourStep(18);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const [searchText, setSearchText] = useState("")

	const [currentOrg, setCurrentOrg] = useState(null);
	const [teams, setTeams] = useState([]);
	const [projects, setProjects] = useState([]);
	const [projectStats, setProjectStats] = useState([]);
	const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
	const [isNewTrackerModalOpen, setIsNewTrackerModalOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(true);

	const updateProject = async (newProject) => {
		await ProjectService.saveProject([newProject])

		var newProjects = [...projects]
		const i = projects.findIndex(x => x?._id == newProject?._id)
		newProjects[i] = newProject
		setProjects(newProjects)
	}

	const navigateToProject = (p) => {
		const link = `dealdashboard/${p._id}`
		navigate(link);
	}

	const [filterMenu, setFilterMenu] = useState(false);

	const formik = useFormik({
		initialValues: {
			available: false,
			searchInput: '',
			services: [],
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			setFilterMenu(false);
			// alert(JSON.stringify(values, null, 2));
		},
	});

	useEffect(async () => {
		setIsLoading(true)
		await (UserService.initialize())
		await (PhaseService.init())
		const curOrg = UserService.getMyCurrentOrg()
		const projects = await ProjectService.getProjects(curOrg?.id)

		setCurrentOrg(curOrg)
		setProjects(projects)
		setIsLoading(false)
	}, []);

	const searchProjects = projects?.filter(
		(p) => p.title?.toLowerCase()?.includes(searchText?.toLowerCase())
	) ?? []

	return (

		isLoading ?
			<PageWrapper className="justify-content-center text-center" title="Deal Dashboard">
				<div className=" justify-content-center text-center" title="Deal Dashboard">
					<Dots></Dots>
				</div>
			</PageWrapper> :
			<PageWrapper title={demoPages.projectManagement.subMenu.list.text}>
				<SubHeader>
					<SubHeaderLeft>
						<strong className='fs-5'>{currentOrg?.display_name}</strong>
						<SubheaderSeparator />
						<span>
							There are{' '}
							<Badge color='info' isLight>
								{teams?.length} teams
							</Badge>{' '}
							and{' '}
							<Badge color='success' isLight>
								{projects?.length} active projects
							</Badge>
							.
						</span>
					</SubHeaderLeft>
					<SubHeaderRight>
						<Input
							placeholder="Search"
							style={{ maxWidth: "1400px" }}
							onChange={e => setSearchText(e.target.value)}
						/>
						{
							isListView ?
								<Button
									color="light"
									icon="App"
									onClick={() => navigate('/deals')}
								/> :
								<Button
									color="light"
									icon="List"
									onClick={() => navigate('/deals/list')}
								/>
						}
					</SubHeaderRight>
				</SubHeader>
				<Page>
					{
						isListView ?
							<ProjectsList updateProject={updateProject} projects={searchProjects} setIsNewProjectModalOpen={setIsNewProjectModalOpen} /> :
							<ProjectsGrid projects={searchProjects} setIsNewProjectModalOpen={setIsNewProjectModalOpen} />
					}
				</Page>

				<NewProjectModal
					setIsOpen={setIsNewProjectModalOpen}
					isOpen={isNewProjectModalOpen}
				/>

				<NewTrackerModal
					isOpen={isNewTrackerModalOpen}
					setIsOpen={setIsNewTrackerModalOpen}
				/>
			</PageWrapper>
	);
};

export default ProjectsDashboard;
