import DelphiConfigProvider from './DelphiConfigProvider';
import showNotification from '../components/extras/showNotification'

class DelphiLogger {

    static logError(message, submessage = '', shouldShowNotification = true) {
        if (DelphiConfigProvider.showErrorNotifications && shouldShowNotification) {
            showNotification(
                message,
                submessage,
            )
        }
    }

    static logMessage(message, submessage = '', shouldShowNotification = false) {
        if (DelphiConfigProvider.showLogNotifications && shouldShowNotification) {
            showNotification(
                message,
                submessage,
            )
        }
    }

    static debug(obj, message = "DebugMessage") {

        console.log(message);
        console.log(obj);
    }

    static console(message) {
        console.log(message);
    }

}


export default DelphiLogger; 