import { CardActions } from '@material-ui/core';
import WebViewer from '@pdftron/webviewer';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../../../components/bootstrap/Button';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle
} from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Icon from '../../../../components/icon/Icon';
import DelphiLogger from '../../../../services/DelphiLogger';
import UserService from '../../../../services/UserService';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const defaultRender = (props) => (
    <>
        {props.canvasLayer.children}
        {props.annotationLayer.children}
        {props.textLayer.children}
    </>
);

const isNullOrWhiteSpace = (str) => {
    return (!str || str.length === 0 || /^\s*$/.test(str))
}

export const EmailAttachment = ({ attachment, setAttachment, deleteAttachment, row, enabled = true, customText = null }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const [documentViewer, setDocumentViewer] = useState(null);
    const viewer = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(false)
    const [tronInstance, setTronInstance] = useState(null)
    const [pdfInstance, setPdfInstance] = useState(false);
    const [org, setOrg] = useState({})
    const [attachemntExpanded, setAttachemntExpanded] = useState(true)

    const getViewer = (attachment) => {

        const markText = !isNullOrWhiteSpace(customText) ? customText :
            ((attachment.watermarkSrc == 'myOrg') ? org?.name :
                (row?._displaylistItem ? row?._displaylistItem["Fund Name"] :
                    row && row["user_metadata"] && row["user_metadata"]["company_name"] ? row["user_metadata"]["company_name"] : "{{Their Org}}"))

        return <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
            <div style={{ height: '750px' }}>
                <Viewer
                    renderPage={(props) => (
                        <>
                            {props.canvasLayer.children}
                            {
                                attachment.watermarkSrc && attachment?.watermarloc && attachment?.watermarkSrc != 'none' &&
                                <div
                                    style={{
                                        alignItems: attachment?.watermarloc,
                                        display: 'flex',
                                        height: '100%',
                                        justifyContent: 'center',
                                        left: 0,
                                        position: 'absolute',
                                        top: 0,
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            color: 'rgba(0, 0, 0, 0.2)',
                                            fontSize: `${4 * props.scale}rem`,
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                            transform: attachment?.watermarloc == 'center' ? 'rotate(-45deg)' : null,
                                            userSelect: 'none',
                                        }}
                                    >
                                        {markText}
                                    </div>
                                </div>
                            }
                            {props.annotationLayer.children}
                            {props.textLayer.children}
                        </>
                    )}
                    fileUrl={attachment?.url}
                    plugins={[
                        defaultLayoutPluginInstance
                        // Register plugins
                    ]}
                />
            </div>
        </Worker>
    }

    const resetViewer = async () => {
        const instance = await (WebViewer({ path: '/webapp/lib', isReadOnly: true }, viewer.current))
        setTronInstance(instance)
    }

    useEffect(async () => {
        setIsLoading(true)
        await UserService.initialize()
        setOrg(UserService.getMyCurrentOrg())
        if (!tronInstance) {
            await resetViewer()
        }
        setPdfLoaded(true)
        setIsLoading(false)

    }, []);

    const tryLoadDoc = () => {
        if (attachment && tronInstance) {
            const file = attachment?.file
            const { annotationManager, documentViewer } = tronInstance.Core;
            setPdfInstance(tronInstance)
            setDocumentViewer(documentViewer)
            annotationManager.setPermissionCheckCallback((author, annotation) => {
                return false;
            });
            tronInstance.UI.loadDocument(file);
            // you can now call WebViewer APIs here...

            if (attachment?.watermarkSrc && attachment?.watermarloc) {
                updateWaterMark(attachment?.watermarloc, attachment?.watermarkSrc, documentViewer)
            }
            return true
        }

        return false
    }

    const retryLoad = () => {
        var retries = 3;
        var success = false;

        while (retries-- > 0 && !(success = tryLoadDoc())) {
            setTimeout(() => { console.log("Load failed"); }, 100);
        }
    }


    useEffect(() => {
        setIsLoading(true)
        retryLoad()
        setIsLoading(false)
    }, [attachment, pdfLoaded, row]);

    const updateWaterMark = (loc, src, documentViewer) => {
        const markText = (src == 'myOrg') ? org?.name : (row?._displaylistItem ? row?._displaylistItem["Fund Name"] : "{{Their Org}}")
        DelphiLogger.debug(attachment)
        var mark = {}

        if (loc == "top") {

            mark = {
                header: {
                    fontSize: 25,
                    fontFamily: 'sans-serif',
                    color: 'red',
                    opacity: 70,
                    center: markText
                }
            }
        }
        else if (loc == "center") {

            mark = {
                // Draw diagonal watermark in center of the document
                diagonal: {
                    fontSize: 25, // or even smaller size
                    fontFamily: 'sans-serif',
                    color: 'red',
                    opacity: 50,
                    text: markText
                },
            }
        }
        else if (loc == "bottom") {

            mark = {
                // Draw diagonal watermark in center of the document
                footer: {
                    fontSize: 25,
                    fontFamily: 'sans-serif',
                    color: 'red',
                    opacity: 70,
                    center: markText
                }
            }
        }

        DelphiLogger.debug(mark, "Mark")
        documentViewer.setWatermark(mark)
    }

    const waterMarkLocations = [
        {
            title: "Top",
            value: "start"
        },
        {
            title: "Center",
            value: "center"
        },
        {
            title: "Bottom",
            value: "end"
        }
    ]

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': []
        },
        onDrop: async acceptedFiles => {
            var file = acceptedFiles[0]
            pdfInstance.UI.loadDocument(file, { filename: file?.name ?? "basic.pdf" })
        }
    });

    return (
        <Card>
            <CardHeader>
                <CardLabel>
                    <CardTitle>
                        <span>
                            <Icon onClick={() => setAttachemntExpanded(!attachemntExpanded)} icon={attachemntExpanded ? 'ChevronDown' : "ChevronRight"} />
                            {attachment?.title}
                        </span>
                    </CardTitle>
                    <div {...getRootProps({ className: 'dropzone' })} className='col-auto'>
                        <input {...getInputProps()} />
                    </div>
                </CardLabel>
                {enabled &&
                    <CardActions>
                        <Button icon="XLg"
                            onClick={() => deleteAttachment()}
                        />
                    </CardActions>
                }
            </CardHeader>
            <CardBody style={{ display: attachemntExpanded ? "block" : "none" }} >
                <div >
                    {
                        attachment &&
                        <div className='row g-4' >
                            <div className={enabled ? 'col-md-9' : 'col-md-12'}>
                                <div className=" justify-content-center text-center">
                                    <div className="MyComponent">
                                        {/* <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div> */
                                            getViewer(attachment)
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                enabled &&
                                <div className='col-md-3'>
                                    <div className='row g-4' >
                                        <div className='col-md-12'>
                                            <FormGroup
                                                id='watermarksrc'
                                                label='Watermark'
                                                isFloating>
                                                <Select
                                                    onChange={e => setAttachment({ ...attachment, watermarkSrc: e.target.value })}
                                                    value={attachment?.watermarkSrc}
                                                >
                                                    <option value="none">None</option>
                                                    <option value="myOrg">My Org WaterMark</option>
                                                    <option value="theirOrg">Reciepient Org Watermark</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-12'>
                                            <FormGroup
                                                id='watermarkloc'
                                                label='Watermark Location'
                                                isFloating>
                                                <Select
                                                    onChange={e => setAttachment({ ...attachment, watermarloc: e.target.value })}
                                                    value={attachment?.watermarloc}
                                                // invalidFeedback={formik?.errors.firstName}
                                                >
                                                    {
                                                        waterMarkLocations.map((type, i) => (
                                                            <option value={type.value}>{type.title}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </CardBody>
        </Card>
    )
}
