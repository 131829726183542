import React, { useEffect, useState } from 'react';
import { useAuth0, } from "@auth0/auth0-react";
// import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
// import Chat, { ChatGroup, ChatHeader } from '../../../components/Chat';
// import InputGroup from '../../../components/bootstrap/forms/InputGroup';
// import Textarea from '../../../components/bootstrap/forms/Textarea';
// import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';
// import CHATS from '../../../common/data/chatDummyData';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import env from "react-dotenv";
import DelphiConfigProvider from '../../../services/DelphiConfigProvider';
import UserService from '../../../services/UserService';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import { useTour } from '@reactour/tour';
import Icon from '../../../components/icon/Icon';

const CommonHeaderChat = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [state, setState] = useState(false);
	const [msgCount, setMsgCount] = useState(0);
	const [meDetailed, setMeDetailed] = useState(null)
	const { user, logout } = useAuth0();
	const navigate = useNavigate();
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	// TODO Good example of header notification
	// useEffect(() => {
	// 	const timeout = setTimeout(() => {
	// 		setMsgCount(1);
	// 		DelphiLogger.logMessage(
	// 			<span className='d-flex align-items-center'>
	// 				<Avatar
	// 					srcSet={USERS.CHLOE.srcSet}
	// 					src={USERS.CHLOE.src}
	// 					size={36}
	// 					color={USERS.CHLOE.color}
	// 					className='me-3'
	// 				/>
	// 				<span>{USERS.CHLOE.name} sent a message.</span>
	// 			</span>,
	// 			<div onClick={() => setState(!state)} role='presentation'>
	// 				<p>I think it's really starting to shine.</p>
	// 			</div>,
	// 		);
	// 	}, 30000);
	// 	return () => {
	// 		clearTimeout(timeout);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(async () => {
		await UserService.initialize()
		const user = UserService.getMe()
		setMeDetailed(user)
		setMsgCount(0);
	}, [state]);
	const { setIsOpen } = useTour();

	return (
		<>

			<div className='row g-3'>
				<div className='col-auto position-relative'>
					<Popovers trigger='hover' desc='Start the "Finspace" tour'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon='Tour'
							onClick={() => setIsOpen(true)}
							aria-label='Start the "Facit" tour'
						/>
					</Popovers>
					{/* <Icon
						icon='Circle'
						className={classNames(
							'position-absolute start-65',
							'text-danger',
							'animate__animated animate__heartBeat animate__infinite animate__slower',
						)}
					/> */}
				</div>
				<div
					className='col d-flex align-items-center cursor-pointer'
					onClick={() => setState(!state)}
					role='presentation'>
					<div className='me-3'>
						<div className='text-end'>
							<div className='fw-bold fs-6 mb-0'>
								{user.name}
							</div>
							<div className='text-muted'>
								<small>{meDetailed?.user_metadata?.position}</small>
							</div>
						</div>
					</div>
					{
						!DelphiConfigProvider.isProd &&
						<div>
							Env: {window.location.host}
						</div>
					}
					<div className='position-relative'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Avatar
									clickable
									srcSet={user.picture}
									src={user.picture}
									size={48}
									color={USERS.CHLOE.color}
								/>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								<DropdownItem onClick={() => logout({ returnTo: window.location.origin })}>
									Logout
								</DropdownItem>
								<DropdownItem key="btn-logout">
									<Button
										onClick={() => navigate(`/onboard`)}>
										Update Profile
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
						{!!msgCount && (
							<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
								{msgCount} <span className='visually-hidden'>unread messages</span>
							</span>
						)}
						<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
							<span className='visually-hidden'>Online user</span>
						</span>
					</div>
				</div>
				{/* 
				// Good example chat open

				<OffCanvas
				id='chat'
				isOpen={state}
				setOpen={setState}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setState} className='fs-5'>
					<ChatHeader to={USERS.CHLOE.name} />
				</OffCanvasHeader>
				<OffCanvasBody>
					<Chat>
						{CHATS.CHLOE_VS_JOHN.map((msg) => (
							<ChatGroup
								key={msg.id}
								messages={msg.messages}
								user={msg.user}
								isReply={msg.isReply}
							/>
						))}
					</Chat>
				</OffCanvasBody>
				<div className='chat-send-message p-3'>
					<InputGroup>
						<Textarea />
						<Button color='info' icon='Send'>
							SEND
						</Button>
					</InputGroup>
				</div>
			</OffCanvas> */}
			</div>
		</>
	);
};

export default CommonHeaderChat;
