import React from 'react'
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Chip from '@mui/material/Chip';

const PriorityColumn = ({ task, updateTask, workstreamIndex, taskIndex, dropDownOptions }) => {

    let dropDownStyle = {
        minWidth: "40px",
        minHeight: "40px",
        height: "100%",
        width: "100%",
    }

    let divStyle = {
        width: "70px"
    }

    let priorityClass = ""

    switch (task.priority) {
        case 'High':  // if (x === 'value1')
            priorityClass = "bg-danger"
            break;
        case 'Medium':  // if (x === 'value2')
            priorityClass = "bg-warning"
            break;
        case 'Low':  // if (x === 'value1')
            priorityClass = "bg-light bg-warning"
            break;
        default:
            priorityClass = ""
            break;
    }

    return (
        <div className='dropdown-cell'>
            <Dropdown>
                <DropdownToggle hasIcon={false}>
                    <div style={dropDownStyle} clickable className='full-cell-div'>
                        {priorityClass ? <Chip label={task.priority} clickable className={priorityClass} /> : ""}
                    </div>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
                    {dropDownOptions.priorityOptions.map((s, ind) => (
                        <DropdownItem key={`${workstreamIndex}-${taskIndex}-${ind}`} onClick={() => updateTask({ ...task, priority: s }, workstreamIndex, taskIndex)}>
                            {s}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

const PriorityHeader = () => {
    return (
        <th scope="col" style={{ width: "5%" }}>Priority</th>
    )
}


export { PriorityColumn, PriorityHeader }