import React, { useState } from 'react'

export const SubTaskInput = ({ updateSubTask, workstreamIndex, taskIndex }) => {

    const [newTaskTitle, setNewTaskTitle] = useState("")

    return (
        <div>
            <input
                style={{ width: "100%" }}
                className='addNewTaskBox'
                onChange={e => setNewTaskTitle(e.target.value)}
                placeholder='+Add Sub Task'
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        setNewTaskTitle("")
                        updateSubTask({ title: e.target.value }, workstreamIndex, taskIndex)
                    }
                }}
                value={newTaskTitle}
                type="text"
            />
        </div>
    )
}
