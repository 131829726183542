import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { BuyerNDASend } from '../../../pages/tracker/NewTracker/WizardSteps/BuyerNDASend';
import { BuyerTrackingRowSelect } from '../../../pages/tracker/NewTracker/WizardSteps/BuyerTrackingRowSelect';
import { EmailPreview } from '../../../pages/tracker/NewTracker/WizardSteps/EmailPreview';
import DelphiLogger from '../../../services/DelphiLogger';
import EmailService from '../../../services/EmailService';
import FileService from '../../../services/FileService';
import { Dots } from 'loading-animations-react';
import UserService from '../../../services/UserService';
import Wizard, { WizardItem } from '../../Wizard';
import { useAuth0, } from "@auth0/auth0-react";
import { v4 as uuidv4 } from 'uuid';
import HistoryService from '../../../services/HistoryService';

export const EmailBlast = ({ curProject, setIsEmailBlastModalOpen, focusWorkStream, updateWorkstream,
    trackerRows, actionItems, getOrDefaultActionItem, updateAI, refreshData }) => {

    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState(new Set())
    const [subject, setSubject] = useState("")
    const [cc, setCc] = useState("")
    const [fromEmail, setFromEmail] = useState("noreply")
    const [body, setBody] = useState("")
    const [org, setOrg] = useState(null)
    const [sending, setSending] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [me, setMe] = useState(null)
    const { user, logout } = useAuth0();


    const options = trackerRows?.map(x => x?._displaylistItem)
    const selectedRows = trackerRows?.filter(x => selectedItems.has(x?._displaylistItem?._id))

    const updateSelectedItems = (ns) => {
        setSelectedItems(new Set(ns?.map(x => x?._id)))
    }

    const props = {
        selectedRows: selectedRows,
        updateSelectedItems: updateSelectedItems,
        subject: subject,
        setSubject: setSubject,
        fromEmail: fromEmail,
        setFromEmail: setFromEmail,
        cc: cc,
        setCc: setCc,
        body: body,
        setBody: setBody,
        trackerRows: trackerRows,
        options: options,
        attachments: attachments,
        setAttachments: setAttachments,
        actionItems: actionItems,
        me: me,
    }

    const submit = async () => {
        try {
            setSending(true)

            const uploadedFiles = await FileService.uploadFiles(attachments?.map(f => f?.file), curProject)
            const wsId = focusWorkStream?._id

            const newWs = { ...focusWorkStream }
            newWs.emails = []

            for (var row of selectedRows) {
                const item = row?._displaylistItem
                const convertedEmail = EmailService.fillTemplate({ body: body, subject: subject }, item, user, org)
                const r_id = row?._id
                const aiId = `${r_id}${wsId}`

                const emailAttachments = attachments?.map((x, i) => {

                    return {
                        watermark: {
                            src: x?.watermarkSrc,
                            text: (x?.watermarkSrc == 'myOrg') ? org?.name : row?._displaylistItem["Fund Name"],
                            loc: x?.watermarloc
                        },
                        file: uploadedFiles[i]._id,
                        filename: x?.file?.name,
                        type: x?.file?.type,
                        disposition: "attachment"
                    }
                })

                const emailAddress = item["Contact Email"]
                const name = item["Contact Name"]

                if (emailAddress) {

                    const email = {
                        _id: uuidv4(),
                        actionItem: aiId,
                        subject: convertedEmail?.subject,
                        body: convertedEmail?.body,
                        status: 'New',
                        fromName: me?.name,
                        fromEmail: fromEmail + "@finspace.app",
                        replyToName: me?.name,
                        replyToEmail: me?.email,
                        cc: cc,
                        toEmail: emailAddress,
                        toName: name,
                        attachments: emailAttachments
                    }
                    await EmailService.saveEmails([email])

                    const ai = getOrDefaultActionItem(row, focusWorkStream)
                    if (ai) {
                        const historyItem = {
                            body: convertedEmail?.body,
                            author: user.sub,
                            type: "email",
                            emails: [email?._id],
                            files: uploadedFiles?.map(f => f?._id),
                            updatedDate: (new Date()).toISOString(),
                            createdDate: (new Date()).toISOString(),
                            state: "created"
                        }

                        newWs.emails.push(email?._id)
                        await HistoryService.putHistory(ai?._id, [historyItem])
                        updateAI({ ...ai, status: 'Complete', history: [...(ai?.history ?? []), historyItem] })
                    }
                }
            }

            newWs.emailsSent = true
            newWs.emailSendDate = Date.now()
            newWs.emailsSender = user.sub
            updateWorkstream(newWs)

            if (refreshData) {
                await refreshData()
            }

            setSending(false)
            setIsEmailBlastModalOpen(false)
        } catch (error) {
            setIsEmailBlastModalOpen(false)
            throw error
        }
    }

    useEffect(async () => {
        await (UserService.initialize())
        const me = (UserService.getMe())
        DelphiLogger.debug(me, "Me")
        setMe(me)
        setOrg(UserService.getMyCurrentOrg())
    }, []);

    return (
        <PageWrapper title="Send Email Blast" >
            {
                sending ?
                    <PageWrapper className="justify-content-center text-center" title="Tasks">
                        <div className=" justify-content-center text-center" title="Tasks">
                            <Dots text="Sending..."></Dots>
                        </div>
                    </PageWrapper>
                    :
                    <Wizard
                        isHeader
                        stretch
                        color='info'
                        noValidate
                        onSubmit={submit}
                        className='shadow-3d-info'>
                        <WizardItem id='step1' title='Select Recipients'>
                            <BuyerTrackingRowSelect {...props} />
                        </WizardItem>
                        <WizardItem id='step2' title='Add Email Content'>
                            <BuyerNDASend {...props}
                            />
                        </WizardItem>
                        <WizardItem id='step3' title='Preview Email'>
                            <EmailPreview {...props} />
                        </WizardItem>
                    </Wizard>
            }
        </PageWrapper>
    )
}
