import React, { useState, useEffect } from 'react'
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import OffCanvas, {
    OffCanvasBody,
    OffCanvasHeader,
    OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Checks from '../../../components/bootstrap/forms/Checks';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
// import { Checkbox, FormGroup, Select, InputLabel } from '@mui/material';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Chip from '@mui/material/Chip';
import { PriorityColumn } from '../columns/PriorityColumn';
import { CompletionTimeColumn, CompletionTimeHeader } from '../columns/CompletionTimeColumn';
import { Waves } from 'loading-animations-react';
import Wizard, { WizardItem } from '../../../components/Wizard';
import './NewWorkstreamFromTemplateModal.css'
import PhaseService from '../../../services/PhaseService';
import Select from '../../../components/bootstrap/forms/Select';

export const NewWorkstreamFromTemplateModal = ({ curProject, setIsOpen, isOpen,
    workStreamOptions, addNewWorkstream }) => {

    const [newWorkStream, setNewWorkStream] = useState({ title: '', description: '', tasks: []});

    const [selectedWorkstreams, setSelectedWorkstreams] = useState([]);
    const [taskOptions, setTaskOptions] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);

    const handleMultiWSSelection = (event) => {
        let newTaskOptions = []
        let newSelectedWorkstreams = []
        let newSelectedTasks = []

        const { options } = event.target;

        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {

                newSelectedWorkstreams.push(options[i].value)

                const tasksToAdd = workStreamOptions[options[i].value].tasks
                if (tasksToAdd) {
                    for (let i = 0, l = tasksToAdd.length; i < l; i += 1) {
                        const t = { ...tasksToAdd[i] }
                        newTaskOptions.push(t)
                        newSelectedTasks.push(t)
                    }
                }
            }
        }

        setTaskOptions(newTaskOptions);
        setSelectedTasks(newSelectedTasks);
        setSelectedWorkstreams(newSelectedWorkstreams)
    }

    const handleMultiTaskSelection = (event) => {
        let newSelectedTasks = []

        const { options } = event.target;

        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                newSelectedTasks.push(taskOptions[i])
            }
        }

        setSelectedTasks(newSelectedTasks);
    };

    useEffect(() => {
        setNewWorkStream({ ...newWorkStream, phase: curProject?.currentPhase })
    }, [curProject])


    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>Add Workstream From Template</OffCanvasTitle>
            </ModalHeader>
            <div>
                <ModalBody >

                    <Wizard
                        isHeader
                        stretch
                        color='info'
                        noValidate
                        onSubmit={() => {
                            addNewWorkstream({ ...newWorkStream, tasks: [...selectedTasks] });
                            setIsOpen(false)
                        }}
                        className='shadow-3d-info'>

                        <WizardItem id='step1' title='Add New Workstream Info'>
                            <Card isCompact className='mb-0'>
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle>New Workstream Info</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12'>
                                            <FormGroup id='title' label='Title' isFloating>
                                                <Input
                                                    placeholder='Title'
                                                    onChange={(event) => setNewWorkStream({ ...newWorkStream, title: event.target.value })}
                                                    value={newWorkStream.title}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='description' label='Description' isFloating>
                                                <Textarea
                                                    style={{ height: "150px" }}
                                                    rows={4}
                                                    placeholder='Description'
                                                    onChange={(event) => setNewWorkStream({ ...newWorkStream, description: event.target.value })}
                                                    value={newWorkStream.description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </WizardItem>
                        <WizardItem id='step2' title='Select Template Workstreams To Use'>
                            <Card isCompact className='mb-0'>
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle>Template Workstreams</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <FormGroup >
                                            <Select
                                                multiple
                                                native
                                                value={selectedWorkstreams}
                                                // @ts-ignore Typings are not considering `native`
                                                onChange={(e) => handleMultiWSSelection(e)}
                                                label="Native"
                                                inputProps={{
                                                    id: 'select-multiple-native',
                                                }}
                                            >
                                                {workStreamOptions.filter(ws => ws?.tasks?.length > 0).map((ws, ind) => (
                                                    <option key={ind} value={ind}>
                                                        {ws.title} ({ws?.tasks?.length ?? 0} tasks)
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </CardBody>
                            </Card>
                        </WizardItem>
                        <WizardItem id='step3' title='Confirm Template Tasks To Add'>
                            <Card isCompact className='mb-0'>
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle>Template Tasks</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <FormGroup >
                                            <Select
                                                multiple
                                                native
                                                value={selectedTasks.map((x) => x._id)}
                                                // @ts-ignore Typings are not considering `native`
                                                onChange={(e) => handleMultiTaskSelection(e)}
                                                label="Native"
                                                inputProps={{
                                                    id: 'select-multiple-native',
                                                }}
                                            >
                                                {taskOptions.map((t, ind) => (
                                                    <option key={ind} value={t._id}>
                                                        {t.title}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </CardBody>
                            </Card>
                        </WizardItem>
                    </Wizard>
                </ModalBody>
            </div>
        </Modal >
    )
}
