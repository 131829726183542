const validate = (values) => {
	const errors = {};
	if (!values.given_name) {
		errors.given_name = 'First name is required';
	} else if (values.given_name.length < 3) {
		errors.given_name = 'First name must be 3 characters or more';
	} else if (values.given_name.length > 20) {
		errors.given_name = 'First name must be 20 characters or less';
	}

	if (!values.user_metadata.position) {
		errors.position = 'Required';
	}	

	if (!values.family_name) {
		errors.family_name = 'Last name is required';
	} else if (values.family_name.length < 3) {
		errors.family_name = 'Last name must be 3 characters or more';
	} else if (values.family_name.length > 20) {
		errors.family_name = 'Last name must be 20 characters or less';
	}

	return errors;
};

export default validate;
