import React from 'react'
import CreatableSelect from 'react-select/creatable';

const TagColumn = ({ task, updateTask, updateTaskTags, workstreamIndex, taskIndex, dropDownOptions }) => {

    const creatableStyle = {

        multiValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "15px",
                backgroundColor: "darkgrey"
            };
        },
    };

    return (
        <div>
            <CreatableSelect style={{ color: "black" }} className="tagSelect"
                styles={creatableStyle}
                options={dropDownOptions.tagOptions}
                isMulti
                minMenuHeight={10}
                onChange={(newValue, actionMetaData) => updateTaskTags(newValue, actionMetaData, task, workstreamIndex, taskIndex)}
                form="false"
                value={task.tags}
            />
        </div>
    )
}

const TagHeader = () => {
    return (
        <th scope="col">Tags</th>
    )
}


export { TagColumn, TagHeader }