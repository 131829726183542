import classNames from 'classnames';
import { Dots } from 'loading-animations-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../components/bootstrap/Badge';
import Button from '../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../components/bootstrap/Card';
import Progress from '../../components/bootstrap/Progress';
import AvatarTeam from '../../components/common/AvatarTeam';
import Icon from '../../components/icon/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import PhaseService from '../../services/PhaseService';
import ProjectService from '../../services/ProjectService';
import { useNavigate } from 'react-router-dom';

export const ProjectItem = ({ project, updateProject, isMinimized, i, cols = 4, ...props }) => {

    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false)
    const navigateToProject = () => {
        if (!isDeleted) {
            navigate(`/dealdashboard/${project?._id}`);
        }
    }
    const { darkModeStatus } = useDarkMode();
    const [isLoading, setIsLoading] = useState(true);
    const [projectStats, setprojectStats] = useState({})

    var phaseInfo = PhaseService.getPhaseInfo(project)
    var phaseBanner = phaseInfo?.numPhases > 0 ?
        `${phaseInfo?.currentPhase?.index + 1}/${phaseInfo?.numPhases}: ${phaseInfo?.currentPhase?.title}` :
        "No phases yet"

    useEffect(async () => {
        setIsLoading(true)
        const projectStats = await ProjectService.getProjectStats([project._id])
        setprojectStats(projectStats)
        setIsLoading(false)
    }, []);

    const deleteProject = () => {

        const isConfirmed = window.confirm("Are you sure want to delete");

        if (isConfirmed) {
            updateProject(i, { ...project, toDelete: true })
        }

    }

    const percent = projectStats?.totalTasks > 0 ? Math.floor(100 * ((projectStats?.projectStatus?.Complete ?? 0) / projectStats?.totalTasks)) : 0

    const numRecentActivities = Math.floor(Math.random() * 50)
    const taskOwnerIds = projectStats?.projectUserCompletion?.filter(x => x._id).map((x, i) => x._id) ?? [];

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={`col-md-${cols}`} {...props}>
            <Card stretch>
                {
                    isLoading ? <></> :
                        <>
                            <CardHeader
                                className={classNames({
                                    "cursor-pointer": true,
                                })}
                                style={isMinimized ? { borderRadius: "2rem 2rem 2rem 2rem" } : {}}
                                onClick={
                                    () => { navigateToProject() }
                                }
                            >
                                <CardLabel icon='Ballot'>
                                    <Link to={`/dealdashboard/${project._id}`}>
                                        <CardTitle>{project.title}</CardTitle>
                                    </Link>
                                    <CardSubTitle>Phase: <b>{phaseBanner}</b></CardSubTitle>
                                </CardLabel>
                                <CardActions>
                                    {
                                        project?.dueDate && (
                                            <small className='border border-success border-2 text-success fw-bold px-2 py-1 rounded-1'>

                                                <div className='todo-subtitle text-muted small'>
                                                    Due: {moment(project?.dueDate).fromNow()}
                                                </div>
                                            </small>
                                        )

                                    }
                                    {
                                        updateProject &&
                                        <Button
                                            color='danger'
                                            icon='XLg'
                                            tag='a'
                                            onClick={(e) => {
                                                setIsDeleted(true)
                                                e.preventDefault();
                                                deleteProject()
                                            }}>
                                        </Button>
                                    }
                                </CardActions>
                            </CardHeader>
                            {
                                !isMinimized &&
                                <CardBody className="card-body align-items-center justify-content-center">
                                    <div className='row g-2 mb-3'>
                                        <div className='col-auto'>
                                            {
                                                projectStats?.fileCount.length > 0 &&
                                                <Link to={`/files/deal/${project._id}`}>
                                                    <Badge color={darkModeStatus ? 'light' : 'dark'} isLight>
                                                        <Icon icon='AttachFile' /> {projectStats?.fileCount[0].count}
                                                    </Badge>
                                                </Link>
                                            }
                                        </div>
                                        <div className='col-auto'>
                                            <Link to={`/dealtasks/${project._id}`}>
                                                <Badge color={darkModeStatus ? 'light' : 'dark'} isLight>
                                                    <Icon icon='TaskAlt' /> {projectStats?.totalTasks}
                                                </Badge>
                                            </Link>
                                        </div>
                                    </div>
                                    {
                                        !isMinimized &&
                                        <>
                                            <div className='row'>
                                                <div className='d-flex col-md-12 justify-content-end'>
                                                    <AvatarTeam members={project?.collaborators}>
                                                    </AvatarTeam>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    {percent}% of tasks completed
                                                    <Progress isAutoColor value={projectStats?.projectStatus?.Complete ?? 0} max={projectStats?.totalTasks} height={10} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </CardBody>
                            }
                        </>
                }
            </Card>
        </div>
    )
}