import moment from 'moment';
import USERS from './userDummyData';
import EVENT_STATUS from './enumEventStatus';
import SERVICES from './serviceDummyData';

const data = [
	{
		id: 1,
		fund: {
			"Company": "Twitterbridge",
			"fundSize": 607,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.APPROVED,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(1, 'days').format('DD'),
		time: 1030,
		customer: { name: 'Alison Berry', email: 'alisonberry@site.com' },
		assigned: USERS.GRACE,
		service: SERVICES.ICE_SKATING,
		duration: '45min',
		payment: 15,
		stats: [
			"Complete", "Complete", "Queued", "Complete"
		]
	},
	{
		id: 2,
		fund: {
			"Company": "Pixoboo",
			"fundSize": 791,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.APPROVED,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(1, 'days').format('DD'),
		time: 1200,
		customer: { name: 'Diane Bower', email: 'dianebower@site.com' },
		assigned: USERS.JANE,
		service: SERVICES.YOGA,
		duration: '45min',
		payment: 40,
		stats: [
			"Complete", "Active", "Complete", "Complete"
		]
	},
	{
		id: 3,
		fund: {
			"Company": "Livetube",
			"fundSize": 226,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.CANCELED,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(1, 'days').format('DD'),
		time: 1230,
		customer: { name: 'Claire Campbell', email: 'clairecampbell@site.com' },
		assigned: USERS.JOHN,
		service: SERVICES.TENNIS,
		duration: '45min',
		payment: 40,
		stats: [
			"Complete", "Complete", "Complete", "Complete"
		]
	},
	{
		id: 4,
		fund: {
			"Company": "Skimia",
			"fundSize": 269,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.REJECTED,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(1, 'days').format('DD'),
		time: 1500,
		customer: { name: 'Sue Quinn', email: 'suequinn@site.com' },
		assigned: USERS.RYAN,
		service: SERVICES.HANDBALL,
		duration: '45min',
		payment: 120,
		stats: [
			"Complete", "Complete", "Blocked"
		]
	},
	{
		id: 5,
		fund: {
			"Company": "Oodoo",
			"fundSize": 265,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.APPROVED,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(2, 'days').format('DD'),
		time: '0930',
		customer: { name: 'Gabrielle Powell', email: 'gabriellepowell@site.com' },
		assigned: USERS.ELLA,
		service: SERVICES.SNOWBOARDING,
		duration: '1h',
		payment: 45,
		stats: [
			"Complete", "Complete"
		]
	},
	{
		id: 6,
		fund: {
			"Company": "Edgeify",
			"fundSize": 401,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.REJECTED,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(3, 'days').format('DD'),
		time: 1530,
		customer: { name: 'Emily Taylor', email: 'emilytaylor@site.com' },
		assigned: USERS.CHLOE,
		service: SERVICES.HANDBALL,
		duration: '45min',
		payment: null,
		stats: [
			"Complete", "Active"
		]
	},
	{
		id: 7,
		fund: {
			"Company": "Kare",
			"fundSize": 327,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.PENDING,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(3, 'days').format('DD'),
		time: 1130,
		customer: { name: 'Carolyn Morgan', email: 'carolynmorgan@site.com' },
		assigned: USERS.JANE,
		service: SERVICES.CRICKET,
		duration: '30min',
		payment: null,
		stats: [
			"Active"
		]
	},
	{
		id: 8,
		fund: {
			"Company": "Eabox",
			"fundSize": 788,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.PENDING,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(4, 'days').format('DD'),
		time: 1300,
		customer: { name: 'Penelope North', email: 'penelopenorth@site.com' },
		assigned: USERS.RYAN,
		service: SERVICES.HIKING,
		duration: '1h',
		payment: null,
		exited: true,
		stats: [
			"Complete", "Active"
		]
	},
	{
		id: 9,
		fund: {
			"Company": "Realbuzz",
			"fundSize": 393,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.PENDING,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(4, 'days').format('DD'),
		time: 1530,
		customer: { name: 'Alexander Kelly', email: 'alexanderkelly@site.com' },
		assigned: USERS.ELLA,
		service: SERVICES.YOGA,
		duration: '45min',
		payment: null,
		exited: true,
		stats: [
			"Complete", "Complete"
		]
	},
	{
		id: 10,
		fund: {
			"Company": "Trunyx",
			"fundSize": 792,
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png"
		},
		status: EVENT_STATUS.PENDING,
		date:
			moment().format('YYYY') + moment().format('MM') + moment().add(4, 'days').format('DD'),
		time: 1600,
		customer: { name: 'Cameron Hodges', email: 'cameronhodges@site.com' },
		assigned: USERS.SAM,
		service: SERVICES.KITE_SURFING,
		duration: '30min',
		payment: null,
		exited: true,
		stats: [
			"Complete", "Complete"
		]
	},
];

export default data;
