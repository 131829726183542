import React, { Suspense, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import { Dots } from 'loading-animations-react';
import ReactGA from 'react-ga';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import ENV from '../ENV';
import UserService from '../services/UserService';
import reactPlugin, { appInsights } from '../services/AppInsights';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

ReactGA.initialize('G-22SKYSENL5');

const App = () => {
	getOS();
	const navigate = useNavigate();


	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(async () => {


		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (ENV.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	const { isLoading, error } = useAuth0();

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isLoading) {
		return <Dots></Dots>;
	}

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path, 'onboard'];

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<Suspense fallback={
				<Dots></Dots>
			}>
				<ThemeProvider theme={theme}>
					<ToastProvider components={{ ToastContainer, Toast }}>
						<TourProvider
							steps={steps}
							styles={styles}
							showNavigation={false}
							showBadge={false}>
							<div
								ref={ref}
								className='app'
								style={{
									backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
									zIndex: fullScreenStatus && 1,
									overflow: fullScreenStatus && 'scroll',
								}}>
								<Routes>
									{withOutAsidePages.map((path) => (
										<Route key={path} path={path} />
									))}
									<Route path='*' element={<Aside />} />
								</Routes>
								<Wrapper />
							</div>
							<Portal id='portal-notification'>
								<ReactNotifications />
							</Portal>
						</TourProvider>
					</ToastProvider>
				</ThemeProvider>

			</Suspense>
		</AppInsightsContext.Provider >
	);
};

export default withAuthenticationRequired(App);
