import React, { useState, useEffect, useRef } from 'react'
import { useAuth0, } from "@auth0/auth0-react";
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import Auth0ClientService from '../../../../services/Auth0ClientService';
import { Dots } from 'loading-animations-react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle,
} from '../../../../components/bootstrap/Card';
import UserService from '../../../../services/UserService';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import Avatar from '../../../../components/Avatar';
import { Chip } from '@material-ui/core';
import MaterialTable from 'material-table';
import { CardActions } from '@mui/material';
import { BuyerListModal } from '../../../../components/BuyerList/BuyerListModal';

export const BuyerTrackingRowSelect = ({ project, refreshData, options, updateSelectedItems, enableBuyerList = false }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isBuyersListModalOpen, setIsBuyersListModalOpen] = useState(false)
    const tableRef = useRef();

    const trackerTypeOptions = [
        {
            value: "buyer",
            title: "Buyer"
        }
    ]

    const templateOptions = [
        {
            value: "basic",
            title: "Basic Buyer Tracking"
        }
    ]

    const styleOptions = {
        search: true,
        exportButton: true,
        defaultExpanded: true,
        selection: true
    }

    const cols = [
        {
            title: "Fund Name",
            field: "Fund Name",
        },
        {
            title: "Contact Name",
            field: "Contact Name",
        },
        {
            title: "Contact Email",
            field: "Contact Email",
        },
        {
            title: "Fund Size",
            field: "Fund Size",
        },
        {
            title: "Enabled",
            field: "Enabled",
        },
        {
            title: "Enabled",
            field: "Enabled",
        },
        {
            title: "Type",
            field: "Type",
        },
        {
            title: "Tier",
            field: "Tier",
        }
    ]

    // useEffect(async () => {
    //     tableRef?.onAllSelected(true)
    // }, [options]);

    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            :
            <>
                <Card >
                    <CardHeader>
                        <CardLabel>
                            <CardTitle>Select Buyers</CardTitle>
                        </CardLabel>
                        <CardActions>
                            {enableBuyerList && <Button
                                icon="Pencil"
                                color="primary"
                                onClick={() => setIsBuyersListModalOpen(true)}
                            >
                                Edit Buyer List
                            </Button>}
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            <div className='col-md-12'>
                                <MaterialTable
                                    tableRef={tableRef}
                                    className="TaskTable "
                                    data={options}
                                    columns={cols}
                                    options={styleOptions}
                                    onSelectionChange={(rows) => {
                                        updateSelectedItems(rows)
                                    }}
                                />
                            </div>
                        </div>
                    </CardBody >
                </Card>
                <BuyerListModal
                    project={project}
                    isBuyersListModalOpen={isBuyersListModalOpen}
                    setIsBuyersListModalOpen={setIsBuyersListModalOpen}
                    refreshData={refreshData} />
            </>
    )
}
