import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Button from '../../components/bootstrap/Button';
import { BuyerListImport } from '../../components/BuyerList/BuyerListImport'
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import ProjectService from '../../services/ProjectService'
// import { BuyerListImport } from '../projecttasks/modals/NewProjectModal/WizardSteps/BuyerListImport'


export const BuyerListPage = ({ }) => {

    const { projectId } = useParams();
    const [list, setList] = useState({ items: [] })

    const pullAll = async () => {

        var lists = await ProjectService.getLists({ _id: projectId })

        for (var l of lists) {
            // TODO support more than 1 list
            if (l.type == 'buyer') {
                setList(l)
                return
            }
        }
        setList({
            // TODO: get list type from modal
            type: "buyer",
            title: "Buyers List",
            items: []
        })
    }

    const updateList = async (newItems) => {
        const newList = { ...list }
        newList.items = newItems
        await ProjectService.saveLists({ _id: projectId }, [newList], true)
        setList(newList)
    }

    useEffect(async () => {
        await pullAll()
    }, []);

    return (
        <PageWrapper title="Buyers List">
            <SubHeader>
                <SubHeaderLeft>
                    <Breadcrumb
                        list={[
                            {
                                title: 'Deals',
                                to: '/deals',
                            },
                            {
                                title: "Current Deal",
                                to: `/dealdashboard/${projectId}`,
                            },
                            {
                                title: "Buyers List",
                                to: `/trackerBoard`,
                            },
                        ]}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                <BuyerListImport buyersList={list?.items ?? []} updateBuyersList={updateList} />
            </Page>
        </PageWrapper>
    )
}
