import {
    faCaretSquareDown,
    faCaretSquareRight
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Button from '../../components/bootstrap/Button';
import Card, {
    CardBody
} from '../../components/bootstrap/Card';
import { ProjectItem } from './ProjectItem';

export const ProjectsGrid = ({
    projects,
    setIsNewProjectModalOpen,
    updateProject,
}) => {

    const [projectsExpanded, setProjectsExpanded] = useState(true)
    const [trackersExpanded, setTrackersExpanded] = useState(false)
    const [taskBoardsExpanded, setTaskBoardsExpanded] = useState(false)

    return (
        <div class="container testimonial-group">
            <div className='row mt-3'>
                <div className='col-12'>
                    <div className='display-4 fw-bold py-3'><span><FontAwesomeIcon
                        title={projectsExpanded ? 'Collapse' : 'Expand'}
                        icon={projectsExpanded ? faCaretSquareDown : faCaretSquareRight}
                        onClick={() => setProjectsExpanded(!projectsExpanded)}
                    />Deals</span></div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-3'>
                    <Card stretch>
                        <CardBody className='d-flex align-items-center justify-content-center'>
                            <Button
                                color='info'
                                size='lg'
                                isLight
                                className='w-100 h-100'
                                icon='AddCircle'
                                onClick={() => setIsNewProjectModalOpen(true)}>
                                Add New Deal
                            </Button>
                        </CardBody>
                    </Card>
                </div>
                {
                    projects.filter((p, i) => !p?.toDelete).map((project, i) => {
                        return <ProjectItem isMinimized={true} cols={3} style={{ display: ((projectsExpanded || i < 2) ? "inline-block" : "none") }} updateProject={updateProject} i={i} project={project} />
                    })
                }
            </div>
        </div>
    )
}
