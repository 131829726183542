import React from 'react'
import Select, { createFilter } from 'react-select';
import DelphiLogger from '../../../services/DelphiLogger';


const ProjectColumn = ({ task, updateTask, dropDownOptions, workstreamIndex, taskIndex }) => {

    // var filter = {
    //     // default filter configuration
    //     ignoreCase: true,
    //     ignoreAccents: true,
    //     matchFrom: 'any',
    //     stringify: option => `${option.data.name} ${option.data.email}`,
    //     trim: true,
    // }

    const getProject = (projectId) => {
        const ret = dropDownOptions?.projectOptions.find(x => x?._id == projectId)
        return { label: ret?.title, value: ret?._id }
    }

    return (
        <div>

            <Select className="tagSelect"
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={[
                    { label: '', value: null },
                    ...(dropDownOptions?.projectOptions?.map(o => ({ label: o?.title, value: o?._id })) ?? [])
                ]}
                minMenuHeight={10}
                onChange={(newValue, actionMetaData) => {

                    const p = dropDownOptions?.projectOptions?.find(p => p?._id == newValue?.value)
                    const ws = p?._displayWorkStreams?.length > 0 ? p?._displayWorkStreams[0] : null
                    updateTask({ ...task, project: p?._id, workstream: ws?._id }, workstreamIndex, taskIndex)
                }}
                form="false"
                value={getProject(task?.project)}
                // filterOption={createFilter(filter)}
                isClearable={true}
            />
        </div>
    )
}

const ProjectHeader = () => {
    return (
        <th scope="col" style={{ minWidth: "175px" }}>Deal</th>
    )
}


export { ProjectColumn, ProjectHeader }