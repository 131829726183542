import DelphiLogger from '../services/DelphiLogger';
import Auth0ClientService from './Auth0ClientService';
import DelphiConfigProvider from './DelphiConfigProvider';
class TagService {

    static tagSaveQueue = [];

    static {
        const interval = setInterval(function () {
            // method to be executed;
            TagService.saveTagQueue()
        }, 2000);
    }

    static saveTagQueue() {

        if (TagService.tagSaveQueue.length > 0) {

            var l = TagService.tagSaveQueue.length

            return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
                .then((accessToken) => {
                    const requestOptions = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        },
                        body: JSON.stringify(TagService.tagSaveQueue)
                    };

                    return fetch(`${DelphiConfigProvider.config.apiEndpoint}/tags/`, requestOptions)
                })
                .then(() => {
                    // Return the number of tasks saved
                    DelphiLogger.logError(
                        'Tasks saved successfully',
                        `+${l}`,
                    )
                })
                .then(() => {
                    TagService.tagSaveQueue = []
                })
                .catch((error) => {
                    DelphiLogger.logError(
                        `Task save failed`,
                        `Task retrieval failed ${error}`,
                    )
                })
        }
    }

    static getTags() {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                }
                const url = `${DelphiConfigProvider.config.apiEndpoint}/tags/`

                return fetch(url, requestOptions)
            });
    }

    static putTags(tags) {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(tags)
                };
                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/tags/`, requestOptions);
            });
    }

    static putTag(tag) {
        TagService.tagSaveQueue.push(tag)
    }

    static deleteTags(tags) {
        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {
                const requestOptions = {
                    method: 'Delete',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(tags)
                };
                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/tags/`, requestOptions);
            });
    }
}

export default TagService;