import React, { useEffect, useState } from 'react'
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../components/bootstrap/Card';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../layout/Page/Page';
import { Dots } from 'loading-animations-react';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import UserService from '../../services/UserService';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import * as Yup from 'yup';
import { WorkingGroupListImport } from '../WorkingGroupMemberModal/WorkingGroupListImport';
import { WizardGenerator } from '../WizardGenerator/WizardGenerator';
import { BuyerListImport } from '../BuyerList/BuyerListImport';
import { RecipientImport } from './RecipientImport';
import { NewBuyerCompose, NewEmailCompose } from './NewEmailCompose';
import ProjectService from '../../services/ProjectService';
import DelphiLogger from '../../services/DelphiLogger';
import TrackerService from '../../services/TrackerService';
import { BuyerTrackingRowSelect } from '../../pages/tracker/NewTracker/WizardSteps/BuyerTrackingRowSelect';
import { BuyerNDASend } from '../../pages/tracker/NewTracker/WizardSteps/BuyerNDASend';
import { EmailPreview } from '../../pages/tracker/NewTracker/WizardSteps/EmailPreview';
import { NewEmailPreview } from './NewEmailPreview';
import FileService from '../../services/FileService';
import TaskService from '../../services/TaskService';
import EmailService from '../../services/EmailService';
import HistoryService from '../../services/HistoryService';
import { set } from 'date-fns';

export const NewEmailOutreach = ({ callback }) => {

    const { id, taskId } = useParams();
    const navigate = useNavigate();
    useMinimizeAside();

    const [useTrackerFlow, setUseTrackerFlow] = useState(false)

    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading')
    const [user, setUser] = useState(null)
    const [currentProject, setCurrentProject] = useState(undefined)
    const [currentTracker, setCurrentTracker] = useState(undefined)
    const [wizardConfig, setWizardConfig] = useState({})
    const [projectOptions, setProjectOptions] = useState([])

    const [selectedItems, setSelectedItems] = useState(new Set())
    const [subject, setSubject] = useState("")
    const [cc, setCc] = useState("")
    const [fromEmail, setFromEmail] = useState("noreply")
    const [body, setBody] = useState("")
    const [org, setOrg] = useState(null)
    const [sending, setSending] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [me, setMe] = useState(null)
    const [currentWS, setCurrentWS] = useState({})
    const [trackerAIs, setTrackerAIs] = useState({})

    const [newOutReachTitle, setNewOutReachTitle] = useState("")

    const trackerFlowTrackerRows = currentTracker?._displayRows ?? []
    const trackerRowDisplayItems = trackerFlowTrackerRows?.map(x => x?._displaylistItem) ?? []
    const selectedRows = trackerFlowTrackerRows?.filter(x => selectedItems.has(x?._displaylistItem?._id))

    const updateSelectedItems = (ns) => {
        setSelectedItems(new Set(ns?.map(x => x?._id)))
    }

    const updateCurrentTracker = async (inputT) => {
        var aiMap = {}
        var tracker = inputT
        setUseTrackerFlow(false)
        if (inputT?._id) {
            const trackers = await (TrackerService.getTrackers(inputT?._id))
            tracker = trackers?.find(x => x)
            tracker = { ...tracker, label: tracker?.title, value: tracker?._id }

            aiMap = tracker?._displayActionItems?.reduce(function (map, ai) {
                map[ai?._id] = ai;
                return map;
            }, {}) ?? {};
            setCurrentTracker(tracker)
            setUseTrackerFlow(true)
            DelphiLogger.debug(aiMap)
        }
        setTrackerAIs(aiMap)
        setCurrentTracker(tracker)
        setCurrentWS(undefined)
    }

    // const updateWizardConfig = () => {

    //     const trackerFlowProps = {
    //         selectedRows: selectedRows,
    //         updateSelectedItems: updateSelectedItems,
    //         subject: subject,
    //         setSubject: setSubject,
    //         fromEmail: fromEmail,
    //         setFromEmail: setFromEmail,
    //         cc: cc,
    //         setCc: setCc,
    //         body: body,
    //         setBody: setBody,
    //         trackerRows: trackerFlowTrackerRows,
    //         options: trackerRowDisplayItems,
    //         attachments: attachments,
    //         setAttachments: setAttachments,
    //         me: user,
    //     }

    //     let futureSteps = []

    //     if (useTrackerFlow) {
    //         futureSteps = [
    //             {
    //                 cards: [
    //                     {
    //                         fields: [
    //                             {
    //                                 id: "browselect",
    //                                 title: "Select Recipients",
    //                                 init: [],
    //                                 render: () => <BuyerTrackingRowSelect {...trackerFlowProps} />
    //                             }
    //                         ]
    //                     },
    //                 ]
    //             },
    //             {
    //                 cards: [
    //                     {
    //                         fields: [
    //                             {
    //                                 id: "trowselect",
    //                                 title: "Add Email Content",
    //                                 init: [],
    //                                 render: () => <BuyerNDASend {...trackerFlowProps} />
    //                             }
    //                         ]
    //                     },
    //                 ]
    //             },
    //             {
    //                 cards: [
    //                     {
    //                         fields: [
    //                             {
    //                                 id: "preview",
    //                                 title: "Preview Email",
    //                                 init: [],
    //                                 render: () => <EmailPreview {...trackerFlowProps} />
    //                             }
    //                         ]
    //                     },
    //                 ]
    //             }
    //         ]
    //     }
    //     else {
    //         futureSteps = [
    //             {
    //                 cards: [
    //                     {
    //                         fields: [
    //                             {
    //                                 id: "users",
    //                                 title: "Outreach Contacts",
    //                                 validate: Yup.array().min(1, "You must have at least one recipient").required("Email Recipients are required"),
    //                                 init: [],
    //                                 render: (formik) => {
    //                                     return <RecipientImport
    //                                         workingGroupList={formik.values?.users}
    //                                         updateWorkingGroupList={(l) => {
    //                                             formik.setFieldValue("users", l)
    //                                         }}
    //                                     />
    //                                 }
    //                             }
    //                         ]
    //                     },
    //                 ]
    //             },
    //             {
    //                 cards: [
    //                     {
    //                         fields: [
    //                             {
    //                                 id: "email",
    //                                 title: "Outreach Contacts",
    //                                 init: { groupBy: "user_metadata.company_name" },
    //                                 render: (formik) => {
    //                                     return <NewEmailCompose
    //                                         email={formik.values?.email}
    //                                         me={user}
    //                                         setEmail={(email) => {
    //                                             formik.setFieldValue("email", email)
    //                                         }}
    //                                     />
    //                                 }
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             }
    //         ]
    //     }

    //     setWizardConfig({
    //         onSubmit: formData => { },
    //         icon: "BriefCase",
    //         steps: [
    //             {
    //                 cards: [
    //                     {
    //                         title: "New Outreach Information",
    //                         fields: [
    //                             {
    //                                 id: "name",
    //                                 title: "Email Outreach Name",
    //                                 type: "input",
    //                                 validate: Yup.string().required("Outreach name is required")
    //                             },
    //                         ],
    //                     },
    //                     {
    //                         title: "Select Tracker (Optional)",
    //                         info: "You can choose to track this email blast in an existing or new tracker",
    //                         fields: [
    //                             {
    //                                 createLabel: "Select or Create Project",
    //                                 id: "project",
    //                                 title: "Deal (Select or Create)",
    //                                 type: "create-select",
    //                                 options: projectOptions?.map(p => ({ ...p, value: p?._id, label: p?.title })),
    //                                 updateValue: p => {
    //                                     setCurrentProject(p)
    //                                     setCurrentTracker(null)
    //                                 }
    //                             },
    //                             {
    //                                 visible: formik => currentProject,
    //                                 createLabel: "Select or Create Tracker",
    //                                 id: "tracker",
    //                                 title: "Tracker (Select or Create)",
    //                                 type: "create-select",
    //                                 options: (currentProject?._displayTrackers?.map(p => ({ ...p, value: p?._id, label: p?.title })) || []),
    //                                 updateValue: async t => {
    //                                     updateCurrentTracker(t)
    //                                     setUseTrackerFlow(true)
    //                                 }
    //                             },
    //                             {
    //                                 visible: formik => currentTracker,
    //                                 createLabel: "Select or Create Task",
    //                                 id: "workstream",
    //                                 title: "Task (Select or Create)",
    //                                 type: "create-select",
    //                                 options: (currentTracker?._displayWorkStreams?.map(p => ({ ...p, value: p?._id, label: p?.title })) || []),
    //                                 updateValue: ws => {
    //                                     setCurrentWS(ws)
    //                                 }
    //                             },
    //                         ]
    //                     },
    //                 ],
    //             },
    //             ...futureSteps
    //         ]
    //     },
    //     )
    // }

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const refreshData = async () => {
        const projectOptions = await TrackerService?.getTrackerBreakdown()
        setProjectOptions(projectOptions)

        await UserService.initialize()

        setOrg(UserService.getMyCurrentOrg())
        const user = UserService.getMe()
        setUser(user)
        setMe(user)
    }

    const trackerFlowProps = {
        selectedRows: selectedRows,
        updateSelectedItems: updateSelectedItems,
        subject: subject,
        setSubject: setSubject,
        fromEmail: fromEmail,
        setFromEmail: setFromEmail,
        cc: cc,
        setCc: setCc,
        body: body,
        setBody: setBody,
        trackerRows: trackerFlowTrackerRows,
        options: trackerRowDisplayItems,
        attachments: attachments,
        setAttachments: setAttachments,
        me: user,
    }

    let futureSteps = []

    if (!currentProject) {
        futureSteps = []
    }
    else if (useTrackerFlow) {
        futureSteps = [
            {
                cards: [
                    {
                        fields: [
                            {
                                id: "browselect",
                                title: "Select Recipients",
                                init: [],
                                render: () => <BuyerTrackingRowSelect {...trackerFlowProps} />
                            }
                        ]
                    },
                ]
            },
            {
                cards: [
                    {
                        fields: [
                            {
                                id: "rowselect",
                                title: "Add Email Content",
                                init: [],
                                render: () => <BuyerNDASend {...trackerFlowProps} />
                            }
                        ]
                    },
                ]
            },
            {
                cards: [
                    {
                        fields: [
                            {
                                id: "preview",
                                title: "Preview Email",
                                init: [],
                                render: () => <EmailPreview {...trackerFlowProps} />
                            }
                        ]
                    },
                ]
            }
        ]
    }
    else {
        futureSteps = [
            {
                cards: [
                    {
                        fields: [
                            {
                                id: "users",
                                title: "Outreach Contacts",
                                validate: Yup.array().min(1, "You must have at least one recipient").required("Email Recipients are required"),
                                init: [],
                                render: (formik) => {
                                    return <RecipientImport
                                        workingGroupList={formik.values?.users}
                                        updateWorkingGroupList={(l) => {
                                            formik.setFieldValue("users", l)
                                        }}
                                    />
                                }
                            }
                        ]
                    },
                ]
            },
            {
                cards: [
                    {
                        fields: [
                            {
                                id: "email",
                                title: "Outreach Contacts",
                                render: (formik) => {
                                    return <NewEmailCompose
                                        email={formik.values?.email}
                                        me={user}
                                        setEmail={(email) => {
                                            formik.setFieldValue("email", email)
                                        }}
                                    />
                                }
                            }
                        ]
                    }
                ]
            },
            {
                cards: [
                    {
                        fields: [
                            {
                                id: "prev",
                                title: "Preview Emails",
                                render: (formik) => <NewEmailPreview
                                    selectedRows={formik.values?.users}
                                    email={formik.values?.email}
                                    me={user}
                                />
                            }
                        ]
                    }
                ]
            }
        ]
    }

    const updateWorkstream = (ws) => {

        if (!ws._id) {
            ws._id = uuidv4()
            ws.tracker = currentTracker?._id
            ws.trackerIndex = currentTracker?._displayWorkStreams.length
            ws.project = currentProject?._id
            ws.status = "New"
            ws.phase = currentProject.currentPhase ?? currentProject.phases > 0 ? currentProject.phases[0] : null
        }

        TaskService.putWorkstream(ws)
    }

    const getOrDefaultActionItem = (currentProject, currentTracker, trackerRow, workstream, ais) => {

        ais = ais ? ais : trackerAIs

        const aiId = `${trackerRow._id}${workstream._id}`
        var ai = ais[aiId]

        if (!ai) {

            const itemName = trackerRow?._displaylistItems?.length > 0 ?
                trackerRow?._displaylistItems[0]["Fund Name"] : ""

            ai = {
                _id: aiId,
                tracker: currentTracker?._id,
                trackerRow: trackerRow._id,
                workstream: workstream._id,
                title: `${workstream?.title} (${itemName})`,
                type: workstream.type,
                owners: [],
                project: currentProject?._id,
                status: "New"
            }
        }

        return ai
    }

    const updateAI = (ai) => {
        const newDict = { ...trackerAIs }
        newDict[`${ai?._id}`] = ai
        setTrackerAIs(newDict)
        TaskService.putTask(ai)
    }

    const wiz = {
        onSubmit: async formData => {
            try {
                setIsLoading(true)
                var outReachName = formData?.name
                var project = formData?.project
                var tracker = formData?.tracker
                var workstream = formData?.workstream

                var users = formData?.users
                var email = formData?.email

                var selectedRows = trackerFlowProps?.selectedRows ?? selectedRows ?? []

                setLoadingMessage("Saving new project")
                if (!project?._id) {
                    project = { _id: uuidv4(), title: project?.label }
                    ProjectService.saveProject([project])
                }

                // Set up the tracker
                if (!useTrackerFlow) {

                    setLoadingMessage("Saving new contacts")
                    var buyerslist = users?.map(u => {
                        var umd = u["user_metadata"]
                        return {
                            "Fund Name": umd ? umd["company_name"] : "",
                            "Contact Name": u?.name,
                            "Contact Email": u?.email,
                            "Enabled": true,
                        }
                    })

                    const l =
                    {
                        type: "buyer",
                        title: "Buyers List",
                        items: buyerslist
                    }
                    await ProjectService.saveLists(project, [l])
                    const [createdList] = await ProjectService.getLists(project, l?._id)

                    setLoadingMessage("Creating tracker")
                    tracker = {
                        title: `${outReachName} Tracker`,
                        list: l?._id,
                        project: project?._id,
                        trackerType: formData?.trackerType ?? 'buyer',
                    }

                    await TrackerService.saveTrackers([tracker])

                    setLoadingMessage("Saving new tracker rows from contact list")
                    const trackerRows = createdList?.listItems?.map((item, i) => ({
                        _id: uuidv4(),
                        trackerIndex: i,
                        tracker: tracker._id,
                        listItem: item._id,
                        title: item["Fund Name"],
                        status: "Active",
                    })) ?? []

                    await TrackerService.saveTrackerRows(tracker._id, trackerRows, true)
                    const trackers = await (TrackerService.getTrackers(tracker._id))
                    tracker = trackers[0]

                    selectedRows = tracker?._displayRows
                }

                if (!workstream?._id) {
                    setLoadingMessage("Creating new workstream")

                    workstream = {}
                    workstream._id = uuidv4()
                    workstream.title = workstream?.label ?? outReachName
                    workstream.type = "EmailBlast"
                    workstream.tracker = tracker?._id
                    workstream.trackerIndex = tracker?._displayWorkStreams?.length ?? 0
                    workstream.project = project?._id
                    workstream.status = "New"

                    TaskService.putWorkstream(workstream)
                }


                var body = email?.body ?? trackerFlowProps?.body
                var subject = email?.subject ?? trackerFlowProps?.subject
                var attachments = email?.attachments ?? trackerFlowProps?.attachments ?? []
                var fromEmail = email?.fromEmail ?? trackerFlowProps?.fromEmail
                var cc = email?.cc ?? trackerFlowProps?.cc

                setLoadingMessage("Uploading template file")
                const uploadedFiles = await FileService.uploadFiles(attachments?.map(f => f?.file), project)

                const wsId = workstream?._id

                const newWs = { ...workstream }
                newWs.emails = []

                setLoadingMessage("Generating emails")
                for (var row of selectedRows) {
                    const item = row?._displaylistItem
                    const convertedEmail = EmailService.fillTemplate({ body: body, subject: subject }, item, user, org)
                    const r_id = row?._id
                    const aiId = `${r_id}${wsId}`

                    const emailAttachments = attachments?.map((x, i) => {

                        return {
                            watermark: {
                                src: x?.watermarkSrc,
                                text: (x?.watermarkSrc == 'myOrg') ? org?.name : (row?._displaylistItem["Fund Name"]),
                                loc: x?.watermarloc
                            },
                            file: uploadedFiles[i]._id,
                            filename: x?.file?.name,
                            type: x?.file?.type,
                            disposition: "attachment"
                        }
                    })

                    const emailAddress = item["Contact Email"]
                    const name = item["Contact Name"]

                    if (emailAddress) {

                        var sentEmail = {
                            _id: uuidv4(),
                            actionItem: aiId,
                            subject: convertedEmail?.subject,
                            body: convertedEmail?.body,
                            status: 'New',
                            fromName: me?.name,
                            fromEmail: fromEmail ? (fromEmail + "@finspace.app") : "noreply@finspace.app",
                            replyToName: me?.name,
                            replyToEmail: me?.email,
                            cc: cc,
                            toEmail: emailAddress,
                            toName: name,
                            attachments: emailAttachments
                        }
                        await EmailService.saveEmails([sentEmail])

                        const ai = getOrDefaultActionItem(project, tracker, row, workstream)
                        if (ai) {
                            const historyItem = {
                                body: convertedEmail?.body,
                                author: user.sub,
                                type: "email",
                                emails: [sentEmail?._id],
                                files: uploadedFiles?.map(f => f?._id),
                                updatedDate: (new Date()).toISOString(),
                                createdDate: (new Date()).toISOString(),
                                state: "created"
                            }

                            newWs.emails.push(sentEmail?._id)
                            await HistoryService.putHistory(ai?._id, [historyItem])
                            updateAI({ ...ai, status: 'Complete', history: [...(ai?.history ?? []), historyItem] })
                        }
                    }
                }

                newWs.emailsSent = true
                newWs.emailSendDate = Date.now()
                newWs.emailsSender = user.sub
                TaskService.putWorkstream(newWs)

                setSending(false)
                if (callback) {
                    callback()
                }
                setIsLoading(false)
                navigate(`/trackerBoard/${tracker._id}`);

            } catch (error) {
                DelphiLogger.logError("Failed to send emails", error?.message)
                console.log(error)
                setIsLoading(false)
            }
        },
        icon: "BriefCase",
        steps: [
            {
                cards: [
                    {
                        title: "New Outreach Information",
                        fields: [
                            {
                                id: "name",
                                title: "Email Outreach Name",
                                type: "input",
                                init: newOutReachTitle,
                                updateValue: setNewOutReachTitle,
                                validate: Yup.string().required("Outreach name is required")
                            },
                        ],
                    },
                    {
                        title: "Select or Create Tracker",
                        info: "You can choose to track this email blast in an existing or new tracker",
                        fields: [
                            {
                                visible: formik => newOutReachTitle,
                                createLabel: "Create Deal",
                                id: "project",
                                title: "Deal (Select or Create)",
                                validate: Yup.object().required("Project is required"),
                                type: "create-select",
                                init: currentProject,
                                options: projectOptions?.map(p => ({ ...p, value: p?._id, label: p?.title })),
                                updateValue: p => {
                                    setCurrentProject(p)
                                    updateCurrentTracker(undefined)
                                }
                            },
                            {
                                visible: formik => currentProject && newOutReachTitle && currentProject?._displayTrackers?.length > 0,
                                createLabel: "Create Tracker",
                                id: "tracker",
                                title: "Tracker (Select or Create)",
                                type: "create-select",
                                init: currentTracker,
                                options: (currentProject?._displayTrackers?.map(p => ({ ...p, value: p?._id, label: p?.title })) || []),
                                updateValue: async t => {
                                    updateCurrentTracker(t)
                                    setCurrentWS(undefined)
                                }
                            },
                            {
                                visible: formik => currentTracker?._displayWorkStreams?.length > 0,
                                createLabel: "Create Task",
                                id: "workstream",
                                init: currentWS,
                                title: "Task (Optional)",
                                type: "select",
                                options: (currentTracker?._displayWorkStreams?.map(p => ({ ...p, value: p?._id, label: p?.title })) || []),
                                updateValue: ws => {
                                    setCurrentWS(ws)
                                }
                            },
                        ]
                    },
                ],
            },
            ...futureSteps
        ]
    }

    useEffect(async () => {
        // updateWizardConfig()
        DelphiLogger.debug(currentProject)
    }, [projectOptions, currentProject, currentTracker,]);

    useEffect(async () => {
        setIsLoading(true)
        await refreshData()
        setIsLoading(false)
    }, []);

    return (
        isLoading ?
            <div className='justify-content-center text-center' title='Tasks'>
                <Dots text={loadingMessage}></Dots>
            </div>
            :
            <WizardGenerator wizardObject={wiz} />
    )
}
