import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { faGear } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PhaseService from '../../../services/PhaseService'
import ProjectService from '../../../services/ProjectService'
import Button from '../../bootstrap/Button'
import { PhaseArrow } from './PhaseArrow'
import './PhaseArrowProgressBar.css'
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../bootstrap/Dropdown';
import { PhaseSelect } from '../../../pages/projecttasks/modals/NewProjectModal/WizardSteps/PhaseSelect';
import Modal, {
    ModalBody, ModalHeader
} from '../../bootstrap/Modal'
import DelphiLogger from '../../../services/DelphiLogger';
import { PhaseEditModal } from '../../../pages/projecttasks/modals/NewProjectModal/WizardSteps/PhaseEditModal';

export const PhaseArrowProgressBar = ({ project, updateProject, phaseFilter, setPhaseFilter, phaseToggleEnabled = true }) => {

    const [phaseSelectModalIsOpen, setPhaseSelectModalIsOpen] = useState(false);
    const [isPhaseEditModalOpen, setIsPhaseEditModalOpen] = useState(false)
    const [phaseEditModalIndex, setPhaseEditModalIndex] = useState(0)

    var numPhases = project?.phases?.length ?? 1
    const navigate = useNavigate();
    var phaseWidth = Math.floor(85 / numPhases)
    var curPhaseIndex = (project?.phases?.indexOf(project?.currentPhase)) ?? -1
    var lastPhaseIndex = project?.phases?.length - 1
    var nextPhaseId = curPhaseIndex > -1 && curPhaseIndex < numPhases - 1 ? project?.phases[curPhaseIndex + 1] : null
    var nextPhase = PhaseService.getPhase(nextPhaseId)
    var firstPhase = project?.phases?.length > 0 ? PhaseService.getPhase(project?.phases[0]) : null

    const commonStyle = {
        width: `${phaseWidth}%`
    }

    const removePhaseFromFilter = (phaseFilter, id) => {
        var index = phaseFilter.indexOf(id);
        if (index !== -1) {
            phaseFilter.splice(index, 1);
        }
    }

    const togglePhase = (phase) => {
        if (phaseToggleEnabled) {
            var newPhaseFilter = [...phaseFilter]

            if (newPhaseFilter.includes(phase?._id)) {
                removePhaseFromFilter(newPhaseFilter, phase?._id)
            }
            else {
                newPhaseFilter.push(phase?._id)
            }

            if (newPhaseFilter.length == 0) {
                newPhaseFilter = [...project.phases]
            }

            setPhaseFilter(newPhaseFilter)
        }
    }

    const goToPhase = async (phase) => {
        const isConfirmed = window.confirm("Are you sure you want to advance the deal to the next phase?");
        if (isConfirmed) {
            await updateProject({ ...project, currentPhase: phase?._id })
        }

        return isConfirmed
    }

    const addNewPhaseType = (phaseType) => {

    }

    const startPhaseEdit = (index) => {
        setPhaseEditModalIndex(index)
        setIsPhaseEditModalOpen(true)
    }

    const updatePhases = async (newPhases) => {
        const currentPhase = newPhases.includes(project.currentPhase) ? project.currentPhase : null
        await updateProject({ ...project, currentPhase: currentPhase, phases: newPhases })
    }

    useEffect(async () => {
        await PhaseService.init()
    }, []);

    return (
        <>
            <div class="arrowcontainer">
                <div class="wrapper">
                    <div class="arrow-steps clearfix">
                        {
                            project?.phases?.map((phaseId, i) => {
                                var phase = PhaseService.getPhase(phaseId)
                                return <PhaseArrow
                                    index={i}
                                    project={project}
                                    style={commonStyle}
                                    phase={phase}
                                    phaseFilter={phaseFilter}
                                    togglePhase={togglePhase}
                                    startPhaseEdit={startPhaseEdit} />
                            })
                        }
                        <div style={{ display: (updateProject ? "inherit" : "none") }} class="phase-modal-button-div" >
                            <Dropdown variant="success" id="dropdown-basic">
                                <DropdownToggle>
                                    <Button icon='Gear' />
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem onClick={() => { setPhaseSelectModalIsOpen(true) }} >
                                        {"Edit deal phases"}
                                    </DropdownItem>
                                    <DropdownItem style={{ display: (nextPhaseId ? "inherit" : "none") }} onClick={async () => { await goToPhase(nextPhase) }} >
                                        {`Go to next phase (${nextPhase?.title ?? ""})`}
                                    </DropdownItem>
                                    <DropdownItem style={{ display: (firstPhase && !project?.currentPhase ? "inherit" : "none") }} onClick={async () => { await goToPhase(firstPhase) }} >
                                        {`Go to first phase (${firstPhase?.title})`}
                                    </DropdownItem>
                                    <DropdownItem style={{ display: (lastPhaseIndex == curPhaseIndex ? "inherit" : "none") }} onClick={
                                        async () => {
                                            const isConfirmed = window.confirm("Are you sure you want to complete the deal");
                                            if (isConfirmed) {
                                                await updateProject({ ...project, complete: true })
                                            }
                                            const link = `/deals`
                                            navigate(link);
                                        }}
                                    >
                                        {`Complete Deal`}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <PhaseSelect
                updateProject={updateProject}
                setIsOpen={setPhaseSelectModalIsOpen}
                isOpen={phaseSelectModalIsOpen}
                project={project}
                updatePhases={updatePhases}
                addNewPhaseType={addNewPhaseType} />
            <PhaseEditModal
                updateProject={updateProject}
                curProject={project}
                phaseIndex={phaseEditModalIndex}
                phases={project?.phases}
                isPhaseEditModalOpen={isPhaseEditModalOpen}
                setIsPhaseEditModalOpen={setIsPhaseEditModalOpen}
                goToPhase={goToPhase}
                updatePhases={updatePhases}
            />
        </>
    )
}
