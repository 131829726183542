import { duration } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DelphiLogger from '../services/DelphiLogger';
import DelphiConfigProvider from './DelphiConfigProvider';
import Auth0ClientService from './Auth0ClientService'

class TemplateService {

    static saveWorkStreamTemplate(ws) {

        const uid = uuidv4()
        const tasks = ws?.tasks ?? []

        const newWS = {
            title: ws.title,
            description: ws.description,
            phase: ws?.phase,
            type: ws?.type,
            trackerIndex: ws?.trackerIndex,
            _id: uid
        }

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(newWS)
                };

                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/workstream`, requestOptions)

            })
            .then(() => {
                return TemplateService.saveTaskTemplates(tasks, uid)
            })
            .then(() => {

                // Return the number of tasks saved
                DelphiLogger.logMessage(
                    'Workstream template saved successfully',
                    `'${newWS.title}' template + ${tasks.length} tasks saved`,
                )

                return (newWS)
            })
            .catch((error) => {
                DelphiLogger.logError(
                    `Workstream save failed`,
                    `Workstream save failed ${error}`,
                )
            })
    }

    static async getProjectTemplates() {
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/projects`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async saveProjectTemplate(template) {
        const templates = [template]
        const uid = uuidv4()
        template._id = uid

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/projects`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(templates)
        })

        const ret = await resp.json()
        return ret
    }

    static async saveTrackerTemplate(template) {
        const uid = uuidv4()
        template._id = uid
        const templates = [template]

        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/trackers`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(templates)
        })

        const ret = await resp.json()
        return ret
    }

    static async getTrackerTemplates() {
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/trackers`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async saveTaskTemplates(tasks, wsId = null) {

        tasks = tasks.filter(t =>
            t.title?.length > 0 &&
            t.isSelected
        )

        tasks.map((t, ind) => {
            tasks[ind] = {
                title: t.title,
                estimatedDuration: t.duration,
                priority: t.priority,
                type: t.type
            }

            if (wsId) {
                tasks[ind].workFlowTemplate = wsId
            }
        })

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(tasks)
                };

                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/tasks`, requestOptions)

            })
    }

    static getWorkStreamTemplates() {

        return Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)
            .then((accessToken) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                };

                return fetch(`${DelphiConfigProvider.config.apiEndpoint}/templates/workstream`, requestOptions)

            })
    }
}

export default TemplateService;