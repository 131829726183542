import React from 'react'
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faComment, faCheckCircle, faTrashCan, faHandRock } from '@fortawesome/free-regular-svg-icons'
import { Checkbox } from '@mui/material';

const ActionColumn = ({ provided, task, updateTask, workstreamIndex, taskIndex, dropDownOptions, selectMode }) => {

    let checkStyle = {
        color: task.status == 'Complete' ? "green " : "inherit",
    }

    const creatableStyle = {
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "15px",
                backgroundColor: "darkgrey"
            };
        },
    };

    return (
        <div style={{ maxWidth: "80px" }}>
            <span className="task-grip" {...provided.dragHandleProps}>
                <FontAwesomeIcon
                    icon={faHandRock}
                    style={{ float: "left" }}
                />
            </span>
            <span hidden={!selectMode} className="cursor-pointer action-circle large complete-btn" title="Select many">
                <Checkbox checked={task.isSelected} onClick={() => { updateTask({ ...task, isSelected: !task.isSelected }, workstreamIndex, taskIndex) }} />
            </span>
            <span className="cursor-pointer action-circle large complete-btn" title="Mark Complete">
                <FontAwesomeIcon icon={faCheckCircle} style={checkStyle} onClick={() => { updateTask({ ...task, status: 'Complete' }, workstreamIndex, taskIndex) }} />
            </span>
            <span className="cursor-pointer action-circle large complete-btn" title="Delete">
                <FontAwesomeIcon icon={faTrashCan} onClick={() => { updateTask({ ...task, toDelete: true }, workstreamIndex, taskIndex) }} />
            </span>
        </div>
    )
}

const ActionHeader = () => {
    return (
        <th style={{ maxWidth: "80px" }} scope="col"></th>
    )
}


export { ActionColumn, ActionHeader }