import { useAuth0 } from "@auth0/auth0-react";
import { faListCheck, faPlus, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/bootstrap/Button';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from '../../components/bootstrap/Dropdown';
import { PhaseArrowProgressBar } from '../../components/Phase/PhaseArrow/PhaseArrowProgressBar';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import TaskPanel from './TaskPanel';
import TaskTable from './TaskTable';

import HistoryService from '../../services/HistoryService';
import TagService from '../../services/TagService';
import TaskService from '../../services/TaskService';
import UserService from '../../services/UserService';

import { NewWorkstreamModal } from './modals/NewWorkstreamModal';


import DelphiLogger from '../../services/DelphiLogger';
import FileService from '../../services/FileService';
import ProjectService from '../../services/ProjectService';
import { NewProjectModal } from './modals/NewProjectModal/NewProjectModal';
import { ProjectCompletionBanner } from './ProjectCompletionBanner';
import PhaseService from "../../services/PhaseService";
import { Input } from "@mui/material";
import Breadcrumb from "../../components/bootstrap/Breadcrumb";

const MyTasksPage = () => {
    const navigate = useNavigate();

    const [toggleRightPanel, setToggleRightPanel] = useState(false);

    const newTags = new Set()
    const { user } = useAuth0();

    const [isLoading, setisLoading] = useState(true);
    const [projectFound, setProjectFound] = useState(true);
    const [selectMode, setSelectMode] = useState(false);
    const [projects, setProjects] = useState([]);
    const [phaseFilter, setPhaseFilter] = useState([]);
    const [workStreams, setWorkStreams] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [orgUsers, setOrgUsers] = useState([]);
    const [isTaskPanelOpen, setIsTaskPanelOpen] = useState(false);
    const [focusItems, setFocusItems] = useState({ task: {}, workstreamIndex: -1, taskIndex: -1 });
    const [filterSort, setFilterSort] = useState({})
    const [projectOptions, setProjectOptions] = useState([])
    const [me, setMe] = useState({})

    const updateSubTask = (subTask, workstreamIndex, taskIndex, subTaskIndex) => {

        const workStreamsTarget = [...workStreams]
        var task = workStreamsTarget[workstreamIndex].tasks[taskIndex]
        task = { ...task }
        var subTasks = workStreamsTarget[workstreamIndex].tasks[taskIndex]?._displaySubTasks ?? []
        subTasks = [...subTasks]

        if (!(subTaskIndex >= 0)) {

            subTask = {
                ...subTask,
                parent: task?._id,
                status: "New",
                comments: [],
                history: [],
                workstream: workStreamsTarget[workstreamIndex]._id,
                index: subTasks.length,
                type: "task"
            }

            subTaskIndex = subTasks.length
            subTasks.push(subTask)
        }
        else {
            subTasks[subTaskIndex] = subTask
        }

        task._displaySubTasks = subTasks
        workStreamsTarget[workstreamIndex].tasks[taskIndex] = task
        TaskService.putTask(subTask)
        setWorkStreams(workStreamsTarget)

        setFocusItems(
            {
                type: 'task',
                task: task,
                workstreamIndex: workstreamIndex,
                taskIndex: taskIndex
            }
        )

        DelphiLogger.debug({
            subTask: subTask,
            workstreamIndex: workstreamIndex,
            taskIndex: taskIndex,
            subTaskIndex: subTaskIndex,
            task: task
        },
            "Full Update")
    }

    const updateAllWSTasks = (workstreamIndex, newTasks, shouldConfirm = false) => {

        const isConfirmed = shouldConfirm ? window.confirm("Are you sure? Old task state is not preserved") : true;

        if (isConfirmed) {
            const workStreamsTarget = [...workStreams]
            workStreamsTarget[workstreamIndex] = { ...workStreamsTarget[workstreamIndex], status: 'Complete' }

            workStreamsTarget[workstreamIndex].tasks = newTasks
            setWorkStreams(workStreamsTarget)
            TaskService.putTasks(newTasks)
        }

        return isConfirmed
    }

    // When selecting or adding a tag
    const updateTaskTags = (
        newValue,
        actionMeta,
        task,
        workstreamIndex,
        taskIndex
    ) => {

        if (actionMeta.action == 'create-option') {
            const newVal = newValue[newValue.length - 1]

            // Only save a tag if it is not already an option
            if (!(tagOptions.some(x => x.value == newVal))) {
                const newTag = { ...newVal, _id: newVal.value }
                setTagOptions([...tagOptions, newTag])
                TagService.putTag(newTag)
            }
        }

        updateTask({ ...task, tags: newValue }, workstreamIndex, taskIndex)
    };

    const addNewTask = (workstreamIndex, newTaskIn = {}) => {

        const me = UserService.getMe()

        const uid = uuidv4()
        const workStreamsTarget = [...workStreams]
        const newTask = {
            ...newTaskIn,
            _id: ("t" + uid),
            g: uuidv4(),
            status: "New",
            comments: [],
            // project: id,
            assignees: [me?.user_id],
            displayAssignees: [{
                ...me,
                value: me.user_id,
                label: <div><img className="avatarImg" src={me.picture} height="30px" width="30px" />{me.name} </div>
            }],
            history: [],
            workstream: workStreamsTarget[workstreamIndex]._id,
            myIndex: workStreamsTarget[workstreamIndex]?.tasks?.length,
            type: "task"
        }
        TaskService.putTask(newTask)
        workStreamsTarget[workstreamIndex].tasks.push(newTask)
        setWorkStreams(workStreamsTarget)
    }

    const updateTask = (newTask, workstreamIndex, taskIndex) => {
        TaskService.putTask(newTask)
        setFocusItems(
            {
                type: 'task',
                task: newTask,
                workstreamIndex: workstreamIndex,
                taskIndex: taskIndex
            }
        )
        const workStreamsTarget = [...workStreams]
        workStreamsTarget[workstreamIndex].tasks[taskIndex] = newTask

        setWorkStreams(workStreamsTarget)
    }

    const setFocusTask = (task, workstreamIndex, taskIndex) => {

        if (task._id != focusItems?.task?._id) {
            setFocusItems(
                {
                    task: task,
                    workstreamIndex: workstreamIndex,
                    taskIndex: taskIndex
                }
            )
        }

        const link = `/mytasks/${task._id}`
        navigate(link);
    }

    // Add a comment to a task
    const addComment = async (task, comment, filesToUpload, workstreamIndex, taskIndex) => {

        const commentObj = {
            body: comment,
            author: user.sub,
            type: "comment",
            updatedDate: (new Date()).toISOString(),
            createdDate: (new Date()).toISOString(),
            state: "created"
        }

        var filesCreatedIds = []
        if (filesToUpload.length > 0) {
            const filesCreated = await FileService.uploadFiles(filesToUpload, { _id: task?.project })
            filesCreatedIds = filesCreated.map(f => f._id)
            commentObj.files = filesCreatedIds
            commentObj._displayFiles = filesCreated
        }

        HistoryService.putHistory(task?._id, [commentObj]).catch((error) => {
            DelphiLogger.logMessage(
                'Comment add FAILED',
                `Comment add FAILED ${error}`,
            )
        })

        const history = task.history ?? []
        updateTask({ ...task, history: [...history, commentObj], files: [...(task.files ?? []), ...filesCreatedIds] }, workstreamIndex, taskIndex)
    }

    var dropDownOptions = {
        stateOptions: [
            "",
            "New",
            "Queued",
            "Active",
            "Complete",
            "Blocked"
        ],
        priorityOptions:
            [
                "",
                "Low",
                "Medium",
                "High"
            ],
        tagOptions: tagOptions,
        orgUserOptions: orgUsers,
        projectOptions: projectOptions
    }

    const pullWorkStreams = async () => {
        const pws = await TaskService.getMyWorkFlowTasks()
        setWorkStreams(pws)
        return (pws)
    }

    const pullProjectOptions = async () => {
        const ws = await ProjectService.getProjectWorkstreams()
        setProjectOptions(ws)
        return (ws)
    }

    const pullTags = (id) => {
        const prom = TagService.getTags().then(
            async (resp) => {
                const tags = await resp.json()
                setTagOptions(tags)
            }
        ).catch(
            (error) => DelphiLogger.logMessage(
                `Tags retrieval failed ${error}`,
                "Tags retrieval failed",
            ))

        return prom
    }

    const updateSearch = (text) => {
        setFilterSort({ ...filterSort, search: text })
    }


    const loadUsers = async (workStreams) => {
        const workStreamsTarget = [...workStreams]

        await Promise.all(
            workStreamsTarget.map(async (ws) => {
                ws.tasks = [...ws.tasks]

                await Promise.all(
                    ws.tasks.map(async (task, i) => {
                        var newTask = { ...task, loaded: true, }
                        var assignees = task.assignees ?? []
                        newTask.displayAssignees = assignees.map((as) => {
                            var user = UserService.getUser(as)
                            return {
                                ...user,
                                value: user.user_id,
                                label: <div><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
                            }
                        })?.filter(x =>
                            !x.isFalseUser
                        )
                        ws.tasks[i] = newTask
                    })
                )
            })
        )

        setWorkStreams(workStreamsTarget)
    }

    const pullAll = async () => {
        await UserService.initialize()
        var currentOrg = UserService.getMyCurrentOrg()
        Promise.all([
            pullTags(),
            pullWorkStreams(),
            pullProjectOptions()
        ])
            .then((data) => {
                // TODO: Pull workstreams from global scope instead of promise
                return Promise.all(
                    [
                        loadUsers(data[1])
                    ])
            })
    }

    useEffect(async () => {
        setisLoading(true)
        await pullAll()
        setisLoading(false)
    }, []);

    return (
        <>
            {
                isLoading ?
                    <PageWrapper className="justify-content-center text-center" title="Tasks">
                        <div className=" justify-content-center text-center" title="Tasks">
                            <Dots></Dots>
                        </div>
                    </PageWrapper>
                    :
                    <PageWrapper title="Tasks">
                        <SubHeader>
                            <SubHeaderLeft>
                                <Breadcrumb
                                    list={[
                                        {
                                            title: 'Deals',
                                            to: '/deals',
                                        },
                                        {
                                            title: "My Tasks",
                                            to: `/mytasks`,
                                        },
                                    ]}
                                />
                            </SubHeaderLeft>
                            <SubHeaderRight>
                                <div>
                                    <Input
                                        placeholder="Search Tasks"
                                        style={{ maxWidth: "2000px", minWidth: "300px" }}
                                        value={filterSort?.search}
                                        onChange={e => updateSearch(e.target.value)}

                                    />
                                    <FontAwesomeIcon icon={faXmarkCircle} onClick={() => updateSearch("")} />
                                </div>
                            </SubHeaderRight>
                        </SubHeader>


                        {
                            /* <SubHeader zIndex="880">
                            <SubHeaderLeft>
                                Phases
                                <PhaseArrowProgressBar project={curProject} phaseFilter={phaseFilter} updateProject={updateProject} />
                            </SubHeaderLeft>
                            </SubHeader> */
                        }

                        <Page container='fluid'>

                            <div className='row'>
                                <div className='col-12 table-c'>
                                    {
                                        !projectFound ?
                                            projects?.length > 0 ?
                                                <>Project not found</> :
                                                <>No projects yet. Create a new project in the dropdown above</> :
                                            <TaskTable
                                                setFilterSort={setFilterSort}
                                                filterSort={filterSort}
                                                updateAllWSTasks={updateAllWSTasks}
                                                selectMode={selectMode}
                                                workstreams={workStreams}
                                                dropDownOptions={dropDownOptions}
                                                updateTaskTags={updateTaskTags}
                                                updateTask={updateTask}
                                                addNewTask={addNewTask}
                                                setIsTaskPanelOpen={setIsTaskPanelOpen}
                                                setFocusTask={setFocusTask}
                                                phaseFilter={phaseFilter}
                                            />
                                    }
                                </div>
                            </div>
                        </Page>

                        <TaskPanel
                            updateSubTask={updateSubTask}
                            setIsTaskPanelOpen={setIsTaskPanelOpen}
                            isTaskPanelOpen={isTaskPanelOpen}
                            focusItems={focusItems}
                            updateTask={updateTask}
                            addComment={addComment}
                            updateTaskTags={updateTaskTags}
                            dropDownOptions={dropDownOptions} />
                    </PageWrapper>
            }
        </>
    )
}

export default MyTasksPage;
