import React from 'react';
import PropTypes from 'prop-types';
import logolong from '../assets/logos/logolong.png';

const Logo = ({ width, height }) => {
	return (
		<div>
			<img style={{height: "30px"}} src={logolong}></img>
		</div>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
