import DelphiLogger from '../services/DelphiLogger';
import Auth0ClientService from "./Auth0ClientService";
import DelphiConfigProvider from './DelphiConfigProvider';

class FileService {

    static toString(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    static async uploadFiles(filesToUpload, project) {

        var projectId = project._id
        var filesCreated = []
        var filesFailed = 0

        var proms = filesToUpload.map(async (file, ind) => {
            DelphiLogger.logMessage(
                'Uploading file',
                `Uploading file ttt: ${file.name}`,
            )

            const formData = new FormData();
            formData.append("file", file);
            formData.append("filename", file.name);

            const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

            const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/files/project/${projectId}`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            var respObj = await resp.json()
            filesCreated.push(respObj)
        })

        await Promise.all(proms)

        DelphiLogger.logMessage(
            'Files uploaded successfully',
            `Successful:${filesCreated.length}\n Failed:${filesFailed}`,
        )

        return filesCreated
    };

    static async putFileInfo(fileInfos) {
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(fileInfos)
        }

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/files/fileInfo`, requestOptions)

        const ret = await resp.json()
        return ret
    }

    static async getTempFile(fileId) {
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const infoProm = fetch(`${DelphiConfigProvider.config.apiEndpoint}/files/${fileId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const contentProm = fetch(`${DelphiConfigProvider.config.apiEndpoint}/files/fileContent/${fileId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const results = await Promise.all([infoProm, contentProm])
        const fileInfo = await results[0].json()
        const b = await results[1].blob()
        fileInfo.blob = b

        return fileInfo
    }

    static async getFileInfos(projectIds, detailed = false) {
        const projectId = projectIds.join(",")
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const detailParam = detailed ? "?detailed=true" : ""

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/files/project/${projectId}${detailParam}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const ret = await resp.json()
        return ret
    }

    static async navigateToDownload(file, link) {
        if (link.current.href) { return }
        const accessToken = await Auth0ClientService.getAuthToken(DelphiConfigProvider.config.auth0apiAudience)

        const resp = await fetch(`${DelphiConfigProvider.config.apiEndpoint}/files/fileContent/${file._id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const blob = await resp.blob()
        const href = window.URL.createObjectURL(blob)

        link.current.download = file.filename
        link.current.href = href

        link.current.click()
    }

}

export default FileService;